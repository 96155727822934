import React from 'react';
import { Card, CardContent, Typography, Box, IconButton, Tooltip, useTheme, CircularProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip } from 'recharts';

const ResponseTimeAnalysisChart = ({ responseTimeData = [], isLoading }) => {
  const theme = useTheme();

  const CustomBarShape = (props) => {
    const { x, y, width, height } = props;
    return <rect x={x} y={y} width={width} height={height} fill={theme.palette.primary.main} rx={5} ry={5} />;
  };

  if (isLoading) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardContent sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  if (!responseTimeData || responseTimeData.length === 0) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardContent sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography>No response time data available</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box mb={2}>
          <Typography variant="h6">Response Time Distribution</Typography>
          <Tooltip title="Distribution of response times across different time ranges">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box flexGrow={1} minHeight={300}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={responseTimeData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="range" stroke={theme.palette.text.primary} />
              <YAxis stroke={theme.palette.text.primary} />
              <RechartsTooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 10,
                  color: theme.palette.text.primary,
                }}
              />
              <Bar dataKey="count" fill={theme.palette.primary.main} shape={<CustomBarShape />} />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ResponseTimeAnalysisChart;
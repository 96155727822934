import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import BugReportIcon from '@mui/icons-material/BugReport';

const ErrorContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '200px',
  textAlign: 'center',
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });

    // Log the error to an error reporting service
    this.logError(error, errorInfo);
  }

  logError = (error, errorInfo) => {
    // TODO: Implement your error logging service here
    console.error("Uncaught error:", error, errorInfo);

    // Example: If you're using a service like Sentry
    // Sentry.captureException(error);
  }

  handleReload = () => {
    window.location.reload();
  }

  handleReportError = () => {
    // TODO: Implement error reporting functionality
    // This could open a modal with a form to submit error details,
    // or send an automatic report to your error tracking system
    console.log("Reporting error:", this.state.error);

    // Example: If you're using a service like Sentry
    // Sentry.showReportDialog({ eventId: capturedEventId });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer elevation={3}>
          <BugReportIcon color="error" style={{ fontSize: 60, marginBottom: 16 }} />
          <ErrorMessage variant="h5" color="error">
            Oops! Something went wrong.
          </ErrorMessage>
          <ErrorMessage variant="body1">
            We're sorry for the inconvenience. Please try refreshing the page or report this error.
          </ErrorMessage>
          {process.env.NODE_ENV === 'development' && this.state.error && (
            <Box mt={2} textAlign="left" width="100%">
              <Typography variant="subtitle2" color="textSecondary">
                Error details (visible in development mode only):
              </Typography>
              <pre style={{ 
                overflowX: 'auto', 
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                background: '#f5f5f5',
                padding: '10px',
                borderRadius: '4px'
              }}>
                {this.state.error.toString()}
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </pre>
            </Box>
          )}
          <ButtonContainer>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={this.handleReload}
            >
              Reload Page
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<BugReportIcon />}
              onClick={this.handleReportError}
            >
              Report Error
            </Button>
          </ButtonContainer>
        </ErrorContainer>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;

import { createTheme } from '@mui/material/styles';
import chatConfig from './chatConfig';

const chatTheme = (mode, settings = {}) => {
  const defaultColors = {
    primary: {
      light: '#1976d2',
      dark: '#90caf9',
    },
    secondary: {
      light: '#dc004e',
      dark: '#f48fb1',
    },
    askButton: {
      light: '#0976d2',
      dark: '#90caf9',
    },
  };

  return createTheme({
    palette: {
      mode,
      primary: {
        main: settings?.primaryColor || 
              chatConfig?.colors?.primary?.[mode] || 
              defaultColors.primary[mode] || 
              '#1976d2',
      },
      secondary: {
        main: settings?.secondaryColor || 
              chatConfig?.colors?.secondary?.[mode] || 
              defaultColors.secondary[mode] || 
              '#dc004e',
      },
      background: {
        default: mode === 'dark' ? '#121212' : '#f5f5f5',
        paper: mode === 'dark' ? '#1e1e1e' : '#ffffff',
      },
      text: {
        primary: mode === 'dark' ? '#ffffff' : '#000000',
        secondary: mode === 'dark' ? '#b0b0b0' : '#545454',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background: mode === 'dark'
              ? chatConfig?.backgroundGradient?.dark || 'linear-gradient(to bottom right, #1a1a1a, #2c2c2c)'
              : chatConfig?.backgroundGradient?.light || 'linear-gradient(to bottom right, #f5f5f5, #e0e0e0)',
            transition: 'background 0.3s ease',
            scrollbarColor: mode === "dark" ? "#6b6b6b #2b2b2b" : "#959595 #f1f1f1",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: mode === "dark" ? "#2b2b2b" : "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: mode === "dark" ? "#6b6b6b" : "#959595",
              minHeight: 24,
              border: mode === "dark" ? "3px solid #2b2b2b" : "3px solid #f1f1f1",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: chatConfig.askButton.borderRadius,
            fontSize: chatConfig.askButton.fontSize,
            fontWeight: chatConfig.askButton.fontWeight,
            padding: chatConfig.askButton.padding,
            minWidth: chatConfig.askButton.minWidth,
            transition: chatConfig.askButton.transition,
            boxShadow: chatConfig.askButton.boxShadow,
            '&:hover': {
              transition: chatConfig.askButton.transition,
            },
          },
          containedPrimary: {
            backgroundColor: mode === 'dark' 
              ? chatConfig.askButton.backgroundColor.dark
              : chatConfig.askButton.backgroundColor.light,
            color: chatConfig.askButton.textColor[mode],
            '&:hover': {
              backgroundColor: mode === 'dark'
                ? chatConfig.askButton.hoverBackgroundColor.dark
                : chatConfig.askButton.hoverBackgroundColor.light,
              filter: 'brightness(90%)',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              borderRadius: chatConfig.askButton.borderRadius,
            },
          },
        },
      },
    },
  });
};

export default chatTheme;

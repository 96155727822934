import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, Button, Grid, Card, CardContent, CardActions,
  IconButton, Chip, Snackbar, Alert, Divider, List, ListItem, ListItemIcon, ListItemText,
  Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  useTheme, useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Add, Edit, Delete, Link, Phone, Email, Message, ContentCopy } from '@mui/icons-material';
import { deleteCTAButton, getCTAButtons } from '../services/api';
import ButtonPreview from './ButtonPreview';
import CTAButtonDialog from './CTAButtonDialog';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1),
  textTransform: 'none',
  fontWeight: 400,
  fontSize: '0.875rem',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[6],
  },
}));

const CTAButtonManager = () => {
  const [ctaButtons, setCTAButtons] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentButton, setCurrentButton] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, buttonId: null });
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchCTAButtons = useCallback(async () => {
    try {
      const buttons = await getCTAButtons();
      setCTAButtons(buttons);
    } catch (error) {
      setError('Failed to fetch CTA buttons: ' + (error.message || 'Unknown error'));
    }
  }, []);

  useEffect(() => {
    fetchCTAButtons();
  }, [fetchCTAButtons]);

  const handleEdit = (button) => {
    setCurrentButton({...button, id: button._id || button.id});
    setOpenDialog(true);
  };

  const handleDeleteConfirmation = (buttonId) => {
    setDeleteConfirmation({ open: true, buttonId });
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmation({ open: false, buttonId: null });
  };

  const handleDeleteConfirm = async () => {
    const buttonId = deleteConfirmation.buttonId;
    if (!buttonId) {
      setError('Cannot delete button: Invalid ID');
      return;
    }
    try {
      await deleteCTAButton(buttonId);
      setCTAButtons(prevButtons => prevButtons.filter(button => (button.id || button._id) !== buttonId));
      setSuccess('CTA Button deleted successfully');
    } catch (error) {
      setError('Failed to delete CTA Button: ' + (error.response?.data?.detail || error.message || 'Unknown error'));
    } finally {
      setDeleteConfirmation({ open: false, buttonId: null });
    }
  };

  const handleSave = async (updatedButton) => {
    try {
      setCTAButtons(prevButtons => {
        const buttonId = updatedButton.id || updatedButton._id;
        if (buttonId) {
          return prevButtons.map(b => (b.id === buttonId || b._id === buttonId) ? {...updatedButton, id: buttonId} : b);
        } else {
          return [...prevButtons, {...updatedButton, id: updatedButton._id}];
        }
      });

      await fetchCTAButtons();

      setOpenDialog(false);
      setCurrentButton(null);
      setSuccess('CTA Button saved successfully');
    } catch (error) {
      setError('Failed to save CTA Button: ' + (error.response?.data?.detail || error.message || 'Unknown error'));
    }
  };

  const getActionIcon = (actionType) => {
    switch (actionType) {
      case 'link': return <Link />;
      case 'phone': return <Phone />;
      case 'email': return <Email />;
      case 'message': return <Message />;
      case 'copy': return <ContentCopy />;
      default: return <Link />;
    }
  };

  const getActionText = (action) => {
    switch (action.type) {
      case 'link': return `Link to: ${action.url}`;
      case 'phone': return `Call: ${action.number}`;
      case 'email': return `Email: ${action.address}`;
      case 'message': return `Message: ${action.content?.substring(0, 20)}...`;
      case 'copy': return `Copy: ${action.text?.substring(0, 20)}...`;
      default: return 'Unknown action';
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems={isMobile ? 'flex-start' : 'center'} mb={4}>
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom 
          sx={{ 
            fontWeight: 'normal', 
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            fontSize: '2rem'  // Increased font size
          }}
        >
          CTA Button Manager
        </Typography>
        <StyledButton
          variant="contained"
          startIcon={<Add />}
          onClick={() => {
            setCurrentButton(null);
            setOpenDialog(true);
          }}
          sx={{ mt: isMobile ? 2 : 0 }}
        >
          Add CTA Button
        </StyledButton>
      </Box>

      <Grid container spacing={3}>
        {ctaButtons.map((button) => (
          <Grid item xs={12} sm={6} md={4} key={button.id || button._id}>
            <StyledCard>
              <CardContent sx={{ flexGrow: 1 }}>
                <Box mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ButtonPreview buttonData={button} />
                </Box>
                <StyledTypography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>{button.text}</StyledTypography>
                <Box mb={1}>
                  {Array.isArray(button.keywords) && button.keywords.map((keyword, index) => (
                    <Chip 
                      key={index} 
                      label={keyword} 
                      size="small" 
                      sx={{ 
                        mr: 0.5, 
                        mb: 0.5,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.contrastText
                      }} 
                    />
                  ))}
                </Box>
                <Divider sx={{ my: 1 }} />
                <StyledTypography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>Actions:</StyledTypography>
                <List dense>
                  {button.actions && button.actions.map((action, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        {getActionIcon(action.type)}
                      </ListItemIcon>
                      <ListItemText 
                        primary={<StyledTypography sx={{ fontWeight: 'medium' }}>{action.type.charAt(0).toUpperCase() + action.type.slice(1)}</StyledTypography>}
                        secondary={<StyledTypography>{getActionText(action)}</StyledTypography>}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                <Tooltip title="Edit">
                  <IconButton 
                    onClick={() => handleEdit(button)} 
                    size="small"
                    sx={{ 
                      color: theme.palette.primary.main,
                      '&:hover': { backgroundColor: theme.palette.primary.light }
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton 
                    onClick={() => handleDeleteConfirmation(button.id || button._id)} 
                    size="small"
                    sx={{ 
                      color: theme.palette.error.main,
                      '&:hover': { backgroundColor: theme.palette.error.light }
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <CTAButtonDialog 
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setCurrentButton(null);
        }}
        currentButton={currentButton}
        onSave={handleSave}
      />

      <Dialog
        open={deleteConfirmation.open}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this CTA button? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleDeleteCancel} color="primary">
            Cancel
          </StyledButton>
          <StyledButton onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CTAButtonManager;
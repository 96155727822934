import React, { useCallback } from 'react';
import { Card, CardContent, Typography, Box, useTheme } from '@mui/material';
import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip } from 'recharts';
import InfoButton from '../InfoButton';

const QuestionComplexityResponseTimeChart = ({ processedComplexityData, maxFrequency, isLoading }) => {
  const theme = useTheme();
  
  

  const colorScale = useCallback((value) => {
    const hue = ((1 - value) * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
  }, []);

  const CustomizedShape = (props) => {
    const { cx, cy, payload } = props;
    const frequency = payload.frequency;
    const size = 50 + (frequency / maxFrequency) * 350;
    const color = colorScale(payload.responseTime / 10); // Adjust the divisor to change color distribution

    return (
      <circle
        cx={cx}
        cy={cy}
        r={Math.sqrt(size / Math.PI)}
        fill={color}
        fillOpacity={0.6}
        stroke={theme.palette.text.primary}
        strokeWidth={0}
      />
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '10px',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
          }}
        >
          <Typography variant="body2" color="text.primary" fontWeight="bold" gutterBottom>
            Question: {data.question}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Complexity: {data.complexity} words
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Response Time: {data.responseTime.toFixed(2)} s
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Frequency: {data.frequency}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="text.primary">
          Question Complexity vs Response Time
        </Typography>
        <InfoButton title="Scatter plot showing the relationship between question complexity (word count) and response time" />
        <ResponsiveContainer width="100%" height={500}>
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              type="number" 
              dataKey="complexity" 
              name="Question Complexity" 
              unit=" words"
              label={{ value: 'Question Complexity (words)', position: 'bottom', fill: theme.palette.text.primary }}
              tick={{ fill: theme.palette.text.primary }}
            />
            <YAxis 
              type="number" 
              dataKey="responseTime" 
              name="Response Time" 
              unit=" s"
              label={{ value: 'Response Time (s)', angle: -90, position: 'left', fill: theme.palette.text.primary }}
              tick={{ fill: theme.palette.text.primary }}
            />
            <ZAxis type="number" dataKey="frequency" range={[50, 400]} name="Frequency" />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
            <Scatter 
              name="Questions" 
              data={processedComplexityData} 
              shape={<CustomizedShape />}
            />
          </ScatterChart>
        </ResponsiveContainer>
        <Box mt={2}>
          <Typography variant="body2" color="text.secondary">
            Bubble size represents question frequency. Color indicates response time (green: fast, red: slow).
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default QuestionComplexityResponseTimeChart;
import React, { useReducer, useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
 
  IconButton, 
  Snackbar, 
  Avatar, 
  useTheme,
  CircularProgress,
  FormControlLabel,
  Switch,
  ThemeProvider,
  CssBaseline
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';

import { v4 as uuidv4 } from 'uuid';

// Make sure these component imports are correct
import Message from './Message';
import LoadingIndicator from './LoadingIndicator';
import ThinkingAnimation from './ThinkingAnimation';
import AnimatedWelcomeText from './AnimatedWelcomeText';

import packageInfo from '../../package.json';


import { getSettings, askQuestion, submitFeedback } from '../services/api';


import chatTheme from './chatTheme';
import { chatConfig } from './chatConfig';
import {
  BackgroundGradient,
  Header,
  ChatContainer,
  StyledTextField,
  InputArea,
  Footer
} from './ChatComponents';

// Action types for useReducer
const ADD_MESSAGE = 'ADD_MESSAGE';
const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';
const SET_LOADING = 'SET_LOADING';
const SET_THINKING = 'SET_THINKING';
const SET_ERROR = 'SET_ERROR';

<style jsx global>{`
  body {
    overflow: hidden;
  }
`}</style>



// Reducer function
const chatReducer = (state, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return { ...state, messages: [...state.messages, action.payload] };
    case UPDATE_LAST_MESSAGE:
      return { 
        ...state, 
        messages: state.messages.map((msg, index) => 
          index === state.messages.length - 1 ? { ...msg, ...action.payload } : msg
        )
      };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_THINKING:
      return { ...state, isThinking: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

const ChatInterface = ({ 
  initialMessages,
  setGlobalMessages,
  settings,
  setSettings,
  darkMode, 
  setDarkMode,
  initialIsLoading,
  setGlobalIsLoading
}) => {
  const navigate = useNavigate();
  const theme = useMemo(() => chatTheme(darkMode ? 'dark' : 'light'), [darkMode]);

  const [state, dispatch] = useReducer(chatReducer, {
    messages: initialMessages || [],
    isLoading: initialIsLoading || false,
    isThinking: false,
    error: null
  });
  const [input, setInput] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarConfig, setSnackbarConfig] = React.useState({});
  const [sessionId, setSessionId] = React.useState('');
  const chatContainerRef = useRef(null);
  
  const [welcomeMessage, setWelcomeMessage] = useState('Welcome to the Chat Interface!');

  const [selectedModel, setSelectedModel] = React.useState('gpt-4o');
  const [aiTemp, setAiTemp] = React.useState(0.7);
  const [kValue, setKValue] = React.useState(3);

  

  useEffect(() => {
    const fetchSettingsData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found, skipping settings fetch');
        return;
      }

      try {
        const data = await getSettings();
        if (data) {
          setSettings(data);
          setWelcomeMessage(data.welcomeMessage || 'Welcome to the Chat Interface!');
          setSelectedModel(data.model || 'gpt-4o');
          setAiTemp(data.temperature || 0.7);
          setKValue(data.kValue || 7);
        } else {
          console.error('Failed to fetch settings');
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    fetchSettingsData();
  }, [setSettings, setWelcomeMessage, setSelectedModel, setAiTemp, setKValue]);

  useEffect(() => {
    setSessionId(uuidv4());
    if (settings) {
      setWelcomeMessage(settings.welcomeMessage || 'Welcome to the Chat Interface!');
      setSelectedModel(settings.model || 'gpt-4o');
      setAiTemp(settings.temperature || 0.7);
      setKValue(settings.kValue || 7);
    }
  }, [settings, setWelcomeMessage, setSelectedModel, setAiTemp, setKValue]);

  useEffect(() => {
    if (setGlobalMessages) {
      setGlobalMessages(state.messages);
    }
  }, [state.messages, setGlobalMessages]);

  useEffect(() => {
    if (setGlobalIsLoading) {
      setGlobalIsLoading(state.isLoading);
    }
  }, [state.isLoading, setGlobalIsLoading]);

  const scrollToLatestBotMessage = useCallback(() => {
  if (chatContainerRef.current) {
    const messages = chatContainerRef.current.querySelectorAll('[data-message-type="bot"]');
    if (messages.length > 0) {
      const latestBotMessage = messages[messages.length - 1];
      latestBotMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}, []);



  

  const handleInputChange = (e) => {
  setInput(e.target.value);
};
  
  
  

  const handleSubmit = async (e) => {
  e.preventDefault();
  if (input.trim() && !state.isLoading) {
    dispatch({ type: SET_LOADING, payload: true });
    dispatch({ type: SET_THINKING, payload: true });

    const userMessage = { sender: 'user', text: input };
    dispatch({ type: ADD_MESSAGE, payload: userMessage });
    setInput('');

    try {
      const response = await askQuestion({
        question: input,
        model: selectedModel,
        temperature: aiTemp,
        k_value: kValue,
        session_id: sessionId,
        source: 'chat-interface',
        language: 'en',
        is_follow_up: state.messages.length > 0
      });

      
      

      dispatch({ type: ADD_MESSAGE, payload: { 
        sender: 'bot', 
        text: response.answer, 
        id: response.interaction_id,
        metadata: {
          ...response.metadata,
          media: response.media
        }
      }});

      // Scroll to the latest bot message after adding it
      setTimeout(() => scrollToLatestBotMessage(), 100);

    } catch (error) {
      console.error('Error:', error);
      dispatch({ type: ADD_MESSAGE, payload: { 
        sender: 'bot', 
        text: `I'm sorry, but I encountered an error while processing your request. Please try again later or contact support if the problem persists.`,
        error: true
      }});

      // Scroll to the error message
      setTimeout(() => scrollToLatestBotMessage(), 100);

    } finally {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: SET_THINKING, payload: false });
    }
  }
};
  
  
  

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const copyMessageWithTimestamp = (message, timestamp) => {
    const textToCopy = `${message.sender}: ${message.text}\nTimestamp: ${timestamp}`;
    navigator.clipboard.writeText(textToCopy);
  };

  const copyFullChat = () => {
    const fullChatText = state.messages
      .map(msg => `${msg.sender === 'user' ? 'You' : 'Bot'}: ${msg.text}`)
      .join('\n\n');
    navigator.clipboard.writeText(fullChatText);
    const copyConfig = chatConfig.snackbars.copy;
    setSnackbarConfig({
      icon: copyConfig.icon,
      backgroundColor: copyConfig.backgroundColor,
      color: copyConfig.color,
    });
    setSnackbarMessage(copyConfig.message || "Full chat copied to clipboard");
    setSnackbarOpen(true);
  };

  const handleFeedback = async (messageId, feedbackType) => {
    try {
      await submitFeedback({
        interaction_id: messageId,
        feedback_type: feedbackType,
      });

      dispatch({ 
        type: UPDATE_LAST_MESSAGE, 
        payload: { id: messageId, feedback: feedbackType } 
      });

      
    } catch (error) {
      console.error('Error submitting feedback:', error);
      if (error.message === 'Your session has expired. Please log in again.') {
        navigate('/login');
      }
    }
  };
  
  
  
  






  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BackgroundGradient>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            height: '100vh',
            position: 'relative',
          }}
          role="main"
          aria-label="Chat interface"
        >
          <Header>
            <img 
              src={darkMode ? chatConfig.logoDark : chatConfig.logoLight} 
              alt={`${packageInfo.name} logo`}
              style={{ height: chatConfig.logoHeight }} 
            />
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={darkMode}
                    onChange={() => setDarkMode(!darkMode)}
                    color="primary"
                    inputProps={{ 'aria-label': 'dark mode toggle' }}
                  />
                }
                label={
                  <>
                    {!darkMode ? <LightModeRoundedIcon sx={{ marginRight: 1 }} /> : null}
                    {darkMode ? <DarkModeRoundedIcon sx={{ marginLeft: 1 }} /> : null}
                  </>
                }
                sx={{
                  color: 'text.primary',
                  '& .MuiFormControlLabel-label': {
                    color: 'text.primary',
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              />
            </Box>
          </Header>
          
          {state.isLoading && (
            <Box
            sx={{
              position: 'absolute',
              top: '0%', // Position it at the bottom of the header
              left: 0,
              right: 0,
              zIndex: chatConfig.loadingIndicator.zIndex,
            }}
          >
            <LoadingIndicator />
          </Box>
            
          )}
           <ChatContainer 
            ref={chatContainerRef}
            elevation={3} 
            aria-label="Chat messages"
            role="log"
            sx={{ 
              flexGrow: 1, 
              overflowY: 'auto', 
              padding: 2,
              height: 'calc(100vh - 200px)',
              maxHeight: 'calc(100vh - 200px)'
            }} 
          >
            <AnimatedWelcomeText />
            {state.messages.map((message, index) => (
              <Message
                key={`${message.id || index}-${message.sender}`}
                message={message}
                darkMode={darkMode}
                onCopy={(timestamp) => copyMessageWithTimestamp(message, timestamp)}
                onFeedback={(feedbackType) => handleFeedback(message.id, feedbackType)}
                metadata={message.metadata}
                config={chatConfig}
                sessionId={sessionId}
                userIcon={darkMode ? chatConfig.icons.user.dark : chatConfig.icons.user.light}
                botIcon={darkMode ? chatConfig.icons.bot.dark : chatConfig.icons.bot.light}

                isLatestBotMessage={
                  message.sender === 'bot' && 
                  index === state.messages.findLastIndex(m => m.sender === 'bot')
                }
                isLatestUserMessage={
                  message.sender === 'user' && 
                  index === state.messages.findLastIndex(m => m.sender === 'user')
                }
              />
            ))}
            {state.isThinking && (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar 
                  sx={{ width: 40, height: 40, mr: 2 }}
                  src={darkMode ? chatConfig.icons.bot.dark : chatConfig.icons.bot.light}
                  alt=""
                  aria-hidden="true"
                />
                <ThinkingAnimation />
              </Box>
            )}
          </ChatContainer>
          <Footer>
            <InputArea component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
  <StyledTextField
    fullWidth
    value={input}
    onChange={handleInputChange}
    onKeyDown={handleKeyDown}
    placeholder={chatConfig.inputField.placeholder}
    variant="outlined"
    disabled={state.isLoading}
    id="chat-input"
    name="chat-input"
    sx={{ 
      flexGrow: 1,
      mr: 2,
      mb: { xs: 1, md: 0 },
    }}
    InputProps={{
      style: {
        color: theme.palette.mode === 'dark' 
          ? chatConfig.inputField.textColor.dark 
          : chatConfig.inputField.textColor.light,
        fontSize: chatConfig.inputField.fontSize,
        fontFamily: chatConfig.inputField.fontFamily,
      }
    }}
    aria-label="Message input"
    multiline
    maxRows={chatConfig.inputField.maxRows}
    minRows={chatConfig.inputField.minRows}
  />
  <Box sx={{ display: 'flex', width: { xs: '100%', md: 'auto' } }}>
    <Button
      type="submit"
      variant="contained"
      fullWidth
      sx={{
        backgroundColor: darkMode 
          ? chatConfig.askButton.backgroundColor.dark 
          : chatConfig.askButton.backgroundColor.light,
        color: darkMode 
          ? chatConfig.askButton.textColor.dark 
          : chatConfig.askButton.textColor.light,
        fontSize: chatConfig.askButton.fontSize,
        fontWeight: chatConfig.askButton.fontWeight,
        borderRadius: chatConfig.askButton.borderRadius,
        padding: chatConfig.askButton.padding,
        width: { xs: '100%', md: 'auto' },
        mr: 2,
        '&:hover': {
          backgroundColor: darkMode 
            ? chatConfig.askButton.hoverBackgroundColor.dark 
            : chatConfig.askButton.hoverBackgroundColor.light,
        }
      }}
      endIcon={
        state.isLoading ? 
        <CircularProgress size={chatConfig.askButton.icon.size} color="inherit" /> : 
        <SendIcon sx={{ fontSize: chatConfig.askButton.icon.size }} />
      }
      disabled={state.isLoading}
      aria-label={state.isLoading ? "Sending message" : "Send message"}
    >
      {state.isLoading ? 'Thinking...' : 'Ask'}
    </Button>
    <IconButton 
      onClick={copyFullChat} 
      sx={{ 
        color: 'primary.main', 
        width: { xs: '0%', md: 'auto' }
      }}
      aria-label="Copy full chat"
    >
      <ContentCopyIcon />
    </IconButton>
  </Box>
</InputArea>


          </Footer>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={chatConfig.snackbars.duration || 3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          ContentProps={{
            sx: {
              backgroundColor: snackbarConfig.backgroundColor,
              color: snackbarConfig.color,
            },
          }}
        />
      </BackgroundGradient>
    </ThemeProvider>
  );
};

export default React.memo(ChatInterface);
import React, { useCallback } from 'react';
import { 
  Box, Typography, List, ListItem, ListItemIcon, 
  ListItemText, ListItemSecondaryAction, IconButton,
  Card, CardContent, Divider, useTheme
} from '@mui/material';
import { 
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  InsertDriveFile as InsertDriveFileIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Description as DescriptionIcon
} from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';

const DocumentUpload = ({ files, onFileUpload, onFileRemove }) => {
  const theme = useTheme();

  const onDrop = useCallback((acceptedFiles) => {
    onFileUpload({ target: { files: acceptedFiles } });
  }, [onFileUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return <PictureAsPdfIcon />;
      case 'doc':
      case 'docx':
        return <DescriptionIcon />;
      default:
        return <InsertDriveFileIcon />;
    }
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>Upload Documents</Typography>
        <Box
          {...getRootProps()}
          sx={{
            border: `2px dashed ${theme.palette.primary.main}`,
            borderRadius: 2,
            p: 3,
            textAlign: 'center',
            cursor: 'pointer',
            mb: 2,
            bgcolor: isDragActive ? theme.palette.action.hover : 'transparent',
            transition: 'background-color 0.3s ease',
            '&:hover': {
              bgcolor: theme.palette.action.hover,
            },
          }}
        >
          <input {...getInputProps()} accept=".pdf,.docx,.txt,.xml,.md" />
          <CloudUploadIcon sx={{ fontSize: 48, color: theme.palette.primary.main, mb: 1 }} />
          <Typography variant="body1">
            {isDragActive
              ? 'Drop the files here...'
              : 'Drag and drop files here, or click to select files'}
          </Typography>
          <Typography variant="caption" display="block" sx={{ mt: 1 }}>
            Supported file types: PDF, DOCX, TXT, XML, MD
          </Typography>
        </Box>
        {files.length > 0 && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" gutterBottom>Uploaded Files</Typography>
            <List>
              {files.map((file, index) => (
                <ListItem key={index} sx={{ 
                  bgcolor: theme.palette.background.paper,
                  borderRadius: 1,
                  mb: 1,
                  '&:hover': {
                    bgcolor: theme.palette.action.hover,
                  },
                }}>
                  <ListItemIcon>
                    {getFileIcon(file.name)}
                  </ListItemIcon>
                  <ListItemText 
                    primary={file.name} 
                    secondary={`${(file.size / 1024).toFixed(2)} KB`} 
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => onFileRemove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default DocumentUpload;
import { removeStopwords } from 'stopword';

export const generateWordCloudData = (questions) => {
  const allText = questions.join(' ').toLowerCase();
  const words = removeStopwords(allText.split(/\s+/));
  const wordFreq = {};
  words.forEach(word => {
    if (word.length > 2) {
      wordFreq[word] = (wordFreq[word] || 0) + 1;
    }
  });
  return Object.entries(wordFreq).map(([value, count]) => ({ value, count }));
};

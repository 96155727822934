// Color Palette Definitions
const colorPalettes = {
  forest: {
    name: "Forest",
    description: "Earthy tones inspired by lush forests",
    primary: "#2C5F2D",
    secondary: "#97BC62",
    accent: "#FED766",
    background: {
      light: "#E9F5DB",
      dark: "#1E2D2F"
    },
    text: {
      light: "#333333",
      dark: "#F0F7F4"
    },
    gradient: {
      start: "#97BC62",
      middle: "#2C5F2D",
      end: "#1E2D2F"
    },
    lightGradient: {
      start: "#E9F5DB",
      end: "#C7E5A5"
    }
  },
  ocean: {
    name: "Ocean",
    description: "Cool blues reminiscent of the deep sea",
    primary: "#003B46",
    secondary: "#07575B",
    accent: "#66A5AD",
    background: {
      light: "#C4DFE6",
      dark: "#002535"
    },
    text: {
      light: "#003B46",
      dark: "#C4DFE6"
    },
    gradient: {
      start: "#66A5AD",
      middle: "#07575B",
      end: "#003B46"
    },
    lightGradient: {
      start: "#E0F2F7",
      end: "#A8D8E2"
    }
  },
  sunset: {
    name: "Sunset",
    description: "Warm hues inspired by a tropical sunset",
    primary: "#DB504A",
    secondary: "#FF6F59",
    accent: "#FFD275",
    background: {
      light: "#FFF8E8",
      dark: "#4A2C40"
    },
    text: {
      light: "#4A2C40",
      dark: "#FFF8E8"
    },
    gradient: {
      start: "#FFD275",
      middle: "#FF6F59",
      end: "#DB504A"
    },
    lightGradient: {
      start: "#FFF8E8",
      end: "#FFE4B5"
    }
  },
  lavender: {
    name: "Lavender",
    description: "Soft purple tones for a calm and soothing atmosphere",
    primary: "#7E5A9B",
    secondary: "#B392AC",
    accent: "#F1E4F3",
    background: {
      light: "#F9F4F5",
      dark: "#453750"
    },
    text: {
      light: "#453750",
      dark: "#F9F4F5"
    },
    gradient: {
      start: "#F1E4F3",
      middle: "#B392AC",
      end: "#7E5A9B"
    },
    lightGradient: {
      start: "#F9F4F5",
      end: "#E6D7E8"
    }
  },
  citrus: {
    name: "Citrus",
    description: "Bright and energetic colors inspired by citrus fruits",
    primary: "#FF6B35",
    secondary: "#F7C59F",
    accent: "#EFEFD0",
    background: {
      light: "#FFF8E7",
      dark: "#004E89"
    },
    text: {
      light: "#004E89",
      dark: "#FFF8E7"
    },
    gradient: {
      start: "#EFEFD0",
      middle: "#F7C59F",
      end: "#FF6B35"
    },
    lightGradient: {
      start: "#FFF8E7",
      end: "#FBE7CF"
    }
  },
  mint: {
    name: "Mint",
    description: "Fresh and clean palette with mint green accents",
    primary: "#3AAFA9",
    secondary: "#2B7A78",
    accent: "#DEF2F1",
    background: {
      light: "#FEFFFF",
      dark: "#17252A"
    },
    text: {
      light: "#17252A",
      dark: "#FEFFFF"
    },
    gradient: {
      start: "#DEF2F1",
      middle: "#3AAFA9",
      end: "#2B7A78"
    },
    lightGradient: {
      start: "#FEFFFF",
      end: "#E8F8F7"
    }
  },
  berry: {
    name: "Berry",
    description: "Rich berry tones for a bold and playful look",
    primary: "#8E3B46",
    secondary: "#E84A5F",
    accent: "#FF847C",
    background: {
      light: "#FECEA8",
      dark: "#2A363B"
    },
    text: {
      light: "#2A363B",
      dark: "#FECEA8"
    },
    gradient: {
      start: "#FF847C",
      middle: "#E84A5F",
      end: "#8E3B46"
    },
    lightGradient: {
      start: "#FECEA8",
      end: "#FFD7C3"
    }
  },
  monochrome: {
    name: "Monochrome",
    description: "Classic black and white with shades of gray",
    primary: "#333333",
    secondary: "#666666",
    accent: "#CCCCCC",
    background: {
      light: "#F5F5F5",
      dark: "#1A1A1A"
    },
    text: {
      light: "#333333",
      dark: "#F5F5F5"
    },
    gradient: {
      start: "#CCCCCC",
      middle: "#666666",
      end: "#333333"
    },
    lightGradient: {
      start: "#F5F5F5",
      end: "#E0E0E0"
    }
  },
  neon: {
    name: "Neon",
    description: "Vibrant neon colors for a high-energy interface",
    primary: "#FF00FF",
    secondary: "#00FFFF",
    accent: "#FFFF00",
    background: {
      light: "#FFFFFF",
      dark: "#000000"
    },
    text: {
      light: "#000000",
      dark: "#FFFFFF"
    },
    gradient: {
      start: "#FFFF00",
      middle: "#00FFFF",
      end: "#FF00FF"
    },
    lightGradient: {
      start: "#FFFFFF",
      end: "#F0F0FF"
    }
  },
  pastel: {
    name: "Pastel",
    description: "Soft, muted colors for a gentle and approachable feel",
    primary: "#A0D2DB",
    secondary: "#D4A5A5",
    accent: "#F9E2AE",
    background: {
      light: "#FFF7F8",
      dark: "#445552"
    },
    text: {
      light: "#445552",
      dark: "#FFF7F8"
    },
    gradient: {
      start: "#F9E2AE",
      middle: "#D4A5A5",
      end: "#A0D2DB"
    },
    lightGradient: {
      start: "#FFF7F8",
      end: "#FDE8EA"
    }
  },
  earth: {
    name: "Earth",
    description: "Natural, earthy tones inspired by soil and clay",
    primary: "#5E3023",
    secondary: "#895737",
    accent: "#CCC591",
    background: {
      light: "#E4D5B7",
      dark: "#2D1E2F"
    },
    text: {
      light: "#2D1E2F",
      dark: "#E4D5B7"
    },
    gradient: {
      start: "#CCC591",
      middle: "#895737",
      end: "#5E3023"
    },
    lightGradient: {
      start: "#E4D5B7",
      end: "#D6C4A1"
    }
  },
  jewel: {
    name: "Jewel",
    description: "Rich, deep colors inspired by precious gemstones",
    primary: "#3C1874",
    secondary: "#7B1E7A",
    accent: "#B33771",
    background: {
      light: "#F8C3CD",
      dark: "#1C0C5B"
    },
    text: {
      light: "#1C0C5B",
      dark: "#F8C3CD"
    },
    gradient: {
      start: "#B33771",
      middle: "#7B1E7A",
      end: "#3C1874"
    },
    lightGradient: {
      start: "#F8D7E3",
      end: "#E6B8D9"
    }
  },
  arctic: {
    name: "Arctic",
    description: "Cool, icy tones reminiscent of polar landscapes",
    primary: "#2E3740",
    secondary: "#3F5765",
    accent: "#BCD2E8",
    background: {
      light: "#F0F5F9",
      dark: "#1E2730"
    },
    text: {
      light: "#2E3740",
      dark: "#F0F5F9"
    },
    gradient: {
      start: "#BCD2E8",
      middle: "#3F5765",
      end: "#2E3740"
    },
    lightGradient: {
      start: "#F0F5F9",
      end: "#D9E6F2"
    }
  },
  tropical: {
    name: "Tropical",
    description: "Vibrant colors inspired by exotic flowers and fruits",
    primary: "#FC440F",
    secondary: "#1A8FE3",
    accent: "#F9E900",
    background: {
      light: "#F1FAFB",
      dark: "#071013"
    },
    text: {
      light: "#071013",
      dark: "#F1FAFB"
    },
    gradient: {
      start: "#F9E900",
      middle: "#1A8FE3",
      end: "#FC440F"
    },
    lightGradient: {
      start: "#F1FAFB",
      end: "#E0F5F8"
    }
  },
  retro: {
    name: "Retro",
    description: "Nostalgic colors reminiscent of vintage design",
    primary: "#E8A87C",
    secondary: "#C38D9E",
    accent: "#41B3A3",
    background: {
      light: "#F9F1F0",
      dark: "#2B303A"
    },
    text: {
      light: "#2B303A",
      dark: "#F9F1F0"
    },
    gradient: {
      start: "#41B3A3",
      middle: "#C38D9E",
      end: "#E8A87C"
    },
    lightGradient: {
      start: "#F9F1F0",
      end: "#F3E0DE"
    }
  },
  vintage: {
  name: "Vintage",
  description: "Muted and nostalgic tones inspired by classic design.",
  primary: "#B6A39E",
  secondary: "#726960",
  accent: "#D3CBB8",
  background: {
    light: "#EFE8E1",
    dark: "#40362F"
  },
  text: {
    light: "#40362F",
    dark: "#EFE8E1"
  },
  gradient: {
    start: "#D3CBB8",
    middle: "#726960",
    end: "#40362F"
  },
  lightGradient: {
    start: "#EFE8E1",
    end: "#D1C6B5"
  }
},
coral: {
  name: "Coral",
  description: "Bright and fresh coral-inspired tones.",
  primary: "#FF6F61",
  secondary: "#F3D1D1",
  accent: "#F9A8A8",
  background: {
    light: "#FFF1F0",
    dark: "#5B3A3A"
  },
  text: {
    light: "#5B3A3A",
    dark: "#FFF1F0"
  },
  gradient: {
    start: "#F9A8A8",
    middle: "#FF6F61",
    end: "#5B3A3A"
  },
  lightGradient: {
    start: "#FFF1F0",
    end: "#FCD7D7"
  }
},
metallic: {
  name: "Metallic",
  description: "Sleek and modern metallic tones.",
  primary: "#A8A9AD",
  secondary: "#C0C0C0",
  accent: "#DAE1E7",
  background: {
    light: "#F5F7FA",
    dark: "#44474A"
  },
  text: {
    light: "#44474A",
    dark: "#F5F7FA"
  },
  gradient: {
    start: "#DAE1E7",
    middle: "#C0C0C0",
    end: "#44474A"
  },
  lightGradient: {
    start: "#F5F7FA",
    end: "#EBEDF0"
  }
},
roseGold: {
  name: "Rose Gold",
  description: "Luxurious pinkish-gold tones.",
  primary: "#B76E79",
  secondary: "#FFD1DC",
  accent: "#E5B7B7",
  background: {
    light: "#FFF3F4",
    dark: "#4A2C31"
  },
  text: {
    light: "#4A2C31",
    dark: "#FFF3F4"
  },
  gradient: {
    start: "#E5B7B7",
    middle: "#FFD1DC",
    end: "#B76E79"
  },
  lightGradient: {
    start: "#FFF3F4",
    end: "#FFE6E9"
  }
},
desert: {
  name: "Desert",
  description: "Warm, earthy tones inspired by desert landscapes.",
  primary: "#C19A6B",
  secondary: "#9B7653",
  accent: "#F2E1D3",
  background: {
    light: "#FAF1E6",
    dark: "#5A4631"
  },
  text: {
    light: "#5A4631",
    dark: "#FAF1E6"
  },
  gradient: {
    start: "#F2E1D3",
    middle: "#9B7653",
    end: "#C19A6B"
  },
  lightGradient: {
    start: "#FAF1E6",
    end: "#EFE5D8"
  }
},
cyberpunk: {
  name: "Cyberpunk",
  description: "Futuristic, neon-inspired palette with dark contrasts.",
  primary: "#FF0090",
  secondary: "#00FFFF",
  accent: "#FFFC00",
  background: {
    light: "#1A1A1D",
    dark: "#0D0D0D"
  },
  text: {
    light: "#00FFFF",
    dark: "#FF0090"
  },
  gradient: {
    start: "#FF0090",
    middle: "#00FFFF",
    end: "#FFFC00"
  },
  lightGradient: {
    start: "#1A1A1D",
    end: "#383838"
  }
},
galaxy: {
  name: "Galaxy",
  description: "Deep blues and purples inspired by outer space.",
  primary: "#1E3D59",
  secondary: "#3F51B5",
  accent: "#9C27B0",
  background: {
    light: "#F3E5F5",
    dark: "#1A237E"
  },
  text: {
    light: "#1A237E",
    dark: "#F3E5F5"
  },
  gradient: {
    start: "#3F51B5",
    middle: "#9C27B0",
    end: "#1E3D59"
  },
  lightGradient: {
    start: "#F3E5F5",
    end: "#E1BEE7"
  }
},
autumn: {
  name: "Autumn",
  description: "Warm reds and browns inspired by fall foliage.",
  primary: "#8B4513",
  secondary: "#D2691E",
  accent: "#FFA07A",
  background: {
    light: "#FFF8DC",
    dark: "#5E2B29"
  },
  text: {
    light: "#5E2B29",
    dark: "#FFF8DC"
  },
  gradient: {
    start: "#FFA07A",
    middle: "#D2691E",
    end: "#8B4513"
  },
  lightGradient: {
    start: "#FFF8DC",
    end: "#FCE7D1"
  }
},
solarized: {
  name: "Solarized",
  description: "Balanced light and dark tones inspired by the Solarized color scheme.",
  primary: "#268BD2",
  secondary: "#2AA198",
  accent: "#B58900",
  background: {
    light: "#FDF6E3",
    dark: "#002B36"
  },
  text: {
    light: "#073642",
    dark: "#FDF6E3"
  },
  gradient: {
    start: "#268BD2",
    middle: "#2AA198",
    end: "#B58900"
  },
  lightGradient: {
    start: "#FDF6E3",
    end: "#EEE8D5"
  }
},
midnight: {
  name: "Midnight",
  description: "Dark, moody palette inspired by the night sky.",
  primary: "#191970",
  secondary: "#483D8B",
  accent: "#6A5ACD",
  background: {
    light: "#F8F8FF",
    dark: "#000033"
  },
  text: {
    light: "#000033",
    dark: "#F8F8FF"
  },
  gradient: {
    start: "#6A5ACD",
    middle: "#483D8B",
    end: "#191970"
  },
  lightGradient: {
    start: "#F8F8FF",
    end: "#E0E0FF"
  }
},
sakura: {
  name: "Sakura",
  description: "Delicate pinks and whites inspired by cherry blossoms.",
  primary: "#FADADD",
  secondary: "#FFB7C5",
  accent: "#FF69B4",
  background: {
    light: "#FFF0F5",
    dark: "#48233C"
  },
  text: {
    light: "#48233C",
    dark: "#FFF0F5"
  },
  gradient: {
    start: "#FFB7C5",
    middle: "#FF69B4",
    end: "#FADADD"
  },
  lightGradient: {
    start: "#FFF0F5",
    end: "#FADADD"
  }
},
zen: {
  name: "Zen",
  description: "Peaceful, neutral tones inspired by a Zen garden.",
  primary: "#8D99AE",
  secondary: "#EDF2F4",
  accent: "#2B2D42",
  background: {
    light: "#FFFFFF",
    dark: "#2B2D42"
  },
  text: {
    light: "#2B2D42",
    dark: "#FFFFFF"
  },
  gradient: {
    start: "#EDF2F4",
    middle: "#8D99AE",
    end: "#2B2D42"
  },
  lightGradient: {
    start: "#FFFFFF",
    end: "#ECEFF4"
  }
},
sunrise: {
  name: "Sunrise",
  description: "Soft pastels inspired by the colors of dawn.",
  primary: "#FFB74D",
  secondary: "#FFCC80",
  accent: "#FFE0B2",
  background: {
    light: "#FFF3E0",
    dark: "#795548"
  },
  text: {
    light: "#795548",
    dark: "#FFF3E0"
  },
  gradient: {
    start: "#FFB74D",
    middle: "#FFCC80",
    end: "#FFE0B2"
  },
  lightGradient: {
    start: "#FFF3E0",
    end: "#FFEBB7"
  }
},
indigoNight: {
  name: "Indigo Night",
  description: "Dark blues and purples inspired by a starlit night.",
  primary: "#3D5A80",
  secondary: "#98C1D9",
  accent: "#EE6C4D",
  background: {
    light: "#E0FBFC",
    dark: "#293241"
  },
  text: {
    light: "#293241",
    dark: "#E0FBFC"
  },
  gradient: {
    start: "#3D5A80",
    middle: "#98C1D9",
    end: "#EE6C4D"
  },
  lightGradient: {
    start: "#E0FBFC",
    end: "#A8DADC"
  }
},
flamingo: {
  name: "Flamingo",
  description: "Bright, playful colors inspired by flamingos.",
  primary: "#FF6F61",
  secondary: "#FFC0CB",
  accent: "#FFD700",
  background: {
    light: "#FFF8F0",
    dark: "#FFA07A"
  },
  text: {
    light: "#FF6F61",
    dark: "#FFF8F0"
  },
  gradient: {
    start: "#FFC0CB",
    middle: "#FF6F61",
    end: "#FFD700"
  },
  lightGradient: {
    start: "#FFF8F0",
    end: "#FFF0E1"
  }
},
espresso: {
  name: "Espresso",
  description: "Rich, deep browns and creams inspired by coffee.",
  primary: "#4E342E",
  secondary: "#D7CCC8",
  accent: "#8D6E63",
  background: {
    light: "#EFEBE9",
    dark: "#3E2723"
  },
  text: {
    light: "#3E2723",
    dark: "#EFEBE9"
  },
  gradient: {
    start: "#D7CCC8",
    middle: "#8D6E63",
    end: "#4E342E"
  },
  lightGradient: {
    start: "#EFEBE9",
    end: "#D7CCC8"
  }
},
rainbow: {
  name: "Rainbow",
  description: "Vibrant, multicolored tones representing a rainbow.",
  primary: "#FF0000",
  secondary: "#FF7F00",
  accent: "#FFFF00",
  background: {
    light: "#FFFFFF",
    dark: "#000000"
  },
  text: {
    light: "#000000",
    dark: "#FFFFFF"
  },
  gradient: {
    start: "#FF0000",
    middle: "#FFFF00",
    end: "#0000FF"
  },
  lightGradient: {
    start: "#FFFFFF",
    end: "#FFCCCC"
  }
},
winterFrost: {
  name: "Winter Frost",
  description: "Cool whites and blues evoking a frosty winter morning.",
  primary: "#D0E8F2",
  secondary: "#73C2FB",
  accent: "#4682B4",
  background: {
    light: "#F5F7FA",
    dark: "#2C3E50"
  },
  text: {
    light: "#2C3E50",
    dark: "#F5F7FA"
  },
  gradient: {
    start: "#D0E8F2",
    middle: "#73C2FB",
    end: "#4682B4"
  },
  lightGradient: {
    start: "#F5F7FA",
    end: "#EBF5FB"
  }
},
rainforestAdventure: {
  name: "Rainforest Adventure",
  description: "Earthy greens and vibrant accents inspired by the jungle theme.",
  primary: "#4CAF50", // Jungle green
  secondary: "#8B4513", // Rich brown
  accent: "#FFD700", // Bright yellow (sunlight through leaves)
  background: {
    light: "#F5F5DC", // Light beige, representing sand
    dark: "#2F4F4F" // Dark greenish gray for deep forest
  },
  text: {
    light: "#2F4F4F", // Dark forest green for readability
    dark: "#F5F5DC" // Light beige on dark background
  },
  gradient: {
    start: "#4CAF50", // Jungle green
    middle: "#8B4513", // Rich brown
    end: "#FFD700" // Bright yellow accent
  },
  lightGradient: {
    start: "#F5F5DC", // Light beige
    end: "#D2B48C" // Tan
  }
}








};

// Set the active color palette here
// Available palettes: 
// - Forest: Earthy tones inspired by lush forests
// - Ocean: Cool blues reminiscent of the deep sea
// - Sunset: Warm hues inspired by a tropical sunset
// - Lavender: Soft purple tones for a calm and soothing atmosphere
// - Citrus: Bright and energetic colors inspired by citrus fruits
// - Mint: Fresh and clean palette with mint green accents
// - Berry: Rich berry tones for a bold and playful look
// - Monochrome: Classic black and white with shades of gray
// - Neon: Vibrant neon colors for a high-energy interface
// - Pastel: Soft, muted colors for a gentle and approachable feel
// - Earth: Natural, earthy tones inspired by soil and clay
// - Jewel: Rich, deep colors inspired by precious gemstones
// - Arctic: Cool, icy tones reminiscent of polar landscapes
// - Tropical: Vibrant colors inspired by exotic flowers and fruits
// - Retro: Nostalgic colors reminiscent of vintage design
// - Vintage: Muted and nostalgic tones inspired by classic design
// - Coral: Bright and fresh coral-inspired tones
// - Metallic: Sleek and modern metallic tones
// - Rose Gold: Luxurious pinkish-gold tones
// - Desert: Warm, earthy tones inspired by desert landscapes
// - Cyberpunk: Futuristic, neon-inspired palette with dark contrasts
// - Galaxy: Deep blues and purples inspired by outer space
// - Autumn: Warm reds and browns inspired by fall foliage
// - Solarized: Balanced light and dark tones inspired by the Solarized color scheme
// - Midnight: Dark, moody palette inspired by the night sky
// - Sakura: Delicate pinks and whites inspired by cherry blossoms
// - Vaporwave: Retro-futuristic colors with neon pink and blue tones
// - Zen: Peaceful, neutral tones inspired by a Zen garden
// - Sunrise: Soft pastels inspired by the colors of dawn
// - Indigo Night: Dark blues and purples inspired by a starlit night
// - Flamingo: Bright, playful colors inspired by flamingos
// - Espresso: Rich, deep browns and creams inspired by coffee
// - Rainbow: Vibrant, multicolored tones representing a rainbow
// - Winter Frost: Cool whites and blues evoking a frosty winter morning

const activePalette = colorPalettes.earth; // Set your active palette here






export const chatConfig = {
  // Paths to logo images for light and dark modes
  logoLight: "/images/logo.png",
  logoDark: "/images/logo.png",
  logoHeight: 160,
  
  // User and bot icons for light and dark modes
  icons: {
    user: {
      light: "/images/user.png",
      dark: "/images/user.png",
    },
    bot: {
      light: "/images/bot.png",
      dark: "/images/bot.png",
    },
  },

  // Default AI model settings
  defaultModel: 'gpt-4o',
  defaultTemperature: 0.7,
  defaultKValue: 7,
  
  // Colors
  colors: {
    primary: {
      light: activePalette.primary,
      dark: activePalette.primary,
    },
    secondary: {
      light: activePalette.secondary,
      dark: activePalette.secondary,
    },
    accent: activePalette.accent,
    background: activePalette.background,
    text: activePalette.text,
  },

  // Header Settings
  header: {
    padding: '12px 24px',
    backgroundColor: {
      light: `${activePalette.primary}39`, // 60% opacity
      dark: `${activePalette.primary}66`, // 40% opacity
    },
    backdropFilter: 'blur(2px)',
    borderBottomWidth: '0px',
  },

  // Loading Progress Bar Settings
  loadingIndicator: {
    height: 9,
    backgroundColor: 'transparent',
    gradient: {
      type: 'linear',
      direction: 'to right',
      colors: [activePalette.gradient.start, activePalette.gradient.middle, activePalette.gradient.end],
    },
    borderRadius: 20,
    zIndex: 9999,
    position: {
      top: '400px',
      left: 0,
      right: 0,
    },
    animation: {
      duration: '1.5s',
      timingFunction: 'ease-in-out',
      variant: 'indeterminate',
      effect: 'slide',
    },
    glow: {
      color: `${activePalette.accent}10`, // 50% opacity
      size: '40px',
    },
    boxShadow: `0 0 10px ${activePalette.accent}80`, // 50% opacity
  },

  welcomeMessage: {
    text: 'Welcome to Rainforest Adventure Golf',
    typingSpeed: 30,
    fontSize: {
      min: '.5rem',    // Minimum font size for very small screens
      max: '4.5rem',  // Maximum font size for large screens
      default: '3rem' // Default size for medium screens
    },
    fontWeight: 'normal',
    fontFamily: {
      default: 'Papyrus, sans-serif',
    },
    fontStyle: {
      default: 'normal',
    },
    padding: {
      default: '10px 20px',
      mobile: '8px 16px'
    },
    animation: {
      type: 'bounceIn',
      duration: '1s',
      easing: 'ease-out',
      delay: '0s'
    },
    iconSpacing: {
      default: 3,
      mobile: 2
    },
    color: activePalette.text.light,
  },
  
  //Bot Icon
  botIcon: {
    src: "/images/bot.png",
    size: 80,
    margin: {
      left: 15,
      right: 20
    },
    animation: {
      type: 'tada', // 'pulse', 'spin', 'bounce', 'shake', 'jello', 'tada'
      duration: '3s',
      timingFunction: 'ease-in-out',
      iterationCount: 'infinite'
    },
    isCircular: true // Set to false for non-circular images
  },

  // Message bubble colors
  messageBubble: {
    user: {
      darkGradient: `linear-gradient(135deg, ${activePalette.primary} 0%, ${activePalette.secondary} 100%)`,
      lightGradient: `linear-gradient(135deg, ${activePalette.lightGradient.start} 0%, ${activePalette.lightGradient.end} 100%)`,
      borderRadius: '10px 10px 0 10px',
      padding: '12px 16px',
      maxWidth: '100%',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      shape: 'default',
      customShape: '',
      tail: {
        size: '10px',
        position: 'bottom-right',
      },
    },
    bot: {
      darkGradient: `linear-gradient(135deg, ${activePalette.secondary} 0%, ${activePalette.primary} 100%)`,
      lightGradient: `linear-gradient(135deg, ${activePalette.lightGradient.end} 0%, ${activePalette.lightGradient.start} 100%)`,
      borderRadius: '10px 10px 10px 20px',
      padding: '12px 16px',
      maxWidth: '75%',
      boxShadow: '0 10px 2px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      shape: 'default',
      customShape: '',
      tail: {
        size: '12px',
        position: 'bottom-left',
      },
    },
  },

  // Background Style
  backgroundStyle: {
    type: 'gradient', // or 'gradient' or 'solid'
    image: {
      light: {
        url: '/images/front_light.png',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      dark: {
        url: '/images/front.jpg',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    },
    gradient: {
      light: {
        colors: [
          `${activePalette.primary}33`,
          `${activePalette.secondary}1A`,
          `${activePalette.accent}0D`
        ],
        direction: 'to bottom right',
      },
      dark: {
        colors: [
          `${activePalette.primary}FF`,
          `${activePalette.secondary}B3`,
          `${activePalette.accent}4D`
        ],
        direction: 'to bottom right',
      },
    },
    solidColor: {
      light: activePalette.background.light,
      dark: activePalette.background.dark,
    },
    overlay: {
      enable: true,
      color: {
        light: 'rgba(255, 255, 255, 0.7)',
        dark: 'rgba(0, 0, 0, 0.3)',
      },
      blendMode: 'multiply',
    },
    blur: {
      enable: true,
      amount: '10px',
    },
    parallax: {
      enable: true,
      speed: 0.2,
    },
  },
  
  
  
  
  
  
  
  
  

  // Input area
  inputArea: {
    backgroundColor: {
      light: `${activePalette.background.light}99`, // 60% opacity
      dark: `${activePalette.background.dark}99`, // 60% opacity
    },
    padding: '22px',
    borderRadius: '30px',
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)'
  },

  // Question input field
  inputField: {
    backgroundColor: {
      light: `${activePalette.background.light}99`, // 60% opacity
      dark: `${activePalette.background.dark}99`, // 60% opacity
    },
    borderRadius: '20px',
    padding: '18px 16px',
    fontSize: '16px',
    fontFamily: 'Arial, sans-serif',
    placeholder: 'Type your message here...',
    borderColor: {
      
    },
    focusBorderColor: {
      light: activePalette.accent,
      dark: activePalette.accent
    },
    textColor: {
      light: activePalette.text.light,
      dark: activePalette.text.dark
    },
    maxRows: 4,
    minRows: 1
  },

  // Ask Button
  askButton: {
    backgroundColor: {
      light: activePalette.accent,
      dark: activePalette.accent,
    },
    hoverBackgroundColor: {
      light: activePalette.secondary,
      dark: activePalette.secondary,
    },
    textColor: {
      light: '#000000', // Set to black for light mode
      dark: '#ffffff',
    },
    fontSize: '1rem',
    fontWeight: 'bold',
    borderRadius: '7px',
    padding: '10px 20px',
    minWidth: '200px',
    transition: 'background-color 0.3s ease',
    boxShadow: `0 2px 5px ${activePalette.primary}40`, // 25% opacity
    icon: {
      size: '20px',
      color: {
        light: '#000000',
        dark: '#ffffff',
      },
    },
    disabledOpacity: 0.7,
  },

  // Copy Button
  copyButton: {
    size: 'small',
    color: {
      light: activePalette.accent,
      dark: activePalette.accent
    }
  },

  // Footer
  footer: {
    backgroundColor: {
      light: `${activePalette.background.light}1A`, // 10% opacity
      dark: `${activePalette.background.dark}1A`, // 10% opacity
    },
    padding: '6px',
  },

  // Snackbars (Notifications)
  snackbars: {
    duration: 3000,
    position: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    fontSize: '1rem',
    borderRadius: '8px',
    maxWidth: '700px',
    feedback: {
      thumbs_up: {
        backgroundColor: activePalette.primary,
        color: activePalette.text.light,
        icon: 'thumbs_up',
        message: 'Thank you for the positive feedback!'
      },
      thumbs_down: {
        backgroundColor: activePalette.secondary,
        color: activePalette.text.light,
        icon: 'thumbs_down',
        message: 'We appreciate your feedback. We will try to improve.'
      },
      heart: {
        backgroundColor: activePalette.accent,
        color: activePalette.text.dark,
        icon: 'heart',
        message: 'Thank you for loving this response!'
      },
      neutral: {
        backgroundColor: activePalette.secondary,
        color: activePalette.text.light,
        icon: 'thumbs_down',
        message: 'Feedback submitted successfully.'
      },
      error: {
        backgroundColor: '#d32f2f', // Keep this as a standard error color
        color: '#ffffff',
        icon: 'error',
        message: 'Failed to submit feedback. Please try again.'
      }
    },
    copy: {
      backgroundColor: activePalette.primary,
      color: activePalette.text.light,
      icon: 'ContentCopy',
      message: 'Message copied to clipboard'
    },
    animation: {
      initial: { opacity: .5, x: -120, y: -250},
      animate: { opacity: 1, x: -120, y: -250 },
      exit: { opacity: 1, y: 100 },
      transition: { 
        type: 'spring', 
        stiffness: 200, 
        damping: 70 
      },
    },
  },

  // Thinking Animation
  thinkingAnimation: {
    dotSize: 8,
    dotSpacing: 5,
    dotColor: {
      light: activePalette.primary,
      dark: activePalette.accent
    },
    animationDuration: 0.7,
    animationHeight: 15,
    containerHeight: 30,
    delayBetweenDots: 0.2
  },

  // Feedback Buttons
   feedbackButtons: {
    color: {
      light: '#757575',  // Default color in light mode
      dark: '#9e9e9e'    // Default color in dark mode
    },
    activeColor: {
      thumbs_up: '#4caf50',   // Green
      thumbs_down: '#f44336', // Red
      heart: '#e91e63'        // Pink
    },
    hoverColor: {
      thumbs_up: '#45a049',   // Slightly darker green for hover
      thumbs_down: '#d32f2f', // Slightly darker red for hover
      heart: '#c2185b'        // Slightly darker pink for hover
    }
  },

  // Timestamp and Copy Icon
  messageControls: {
    timestamp: {
      fontSize: '0.75rem',
      fontWeight: 'normal',
      color: {
        light: `${activePalette.text.light}99`, // 60% opacity
        dark: `${activePalette.text.dark}99`, // 60% opacity
      },
      format: 'en-US',
      options: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      },
    },
    copyIcon: {
      size: 'small',
      color: {
        light: activePalette.secondary,
        dark: activePalette.accent,
      },
      hoverColor: {
        light: activePalette.primary,
        dark: activePalette.secondary,
      },
      marginLeft: '8px',
    },
    container: {
      marginTop: '18px',
      display: 'flex',
      alignItems: 'center',
    },
  },

  // Media display settings
  media: {
    display: {
      maxWidth: '100%',
      maxHeight: '600px',
      objectFit: 'contain',
    },
    video: {
      width: '100%',
      height: 'auto',
      style: {
        backgroundColor: activePalette.background.dark,
      },
      autoplay: false,
      controls: true,
    },
    zoomIcon: {
      enable: true,
      position: {
        right: '10px',
        bottom: '10px',
      },
      style: {
        backgroundColor: `${activePalette.primary}80`, // 50% opacity
        color: activePalette.text.light,
      },
    },
    lightbox: {
      width: '90vw',
      height: '90vh',
      style: {
        backgroundColor: activePalette.background.light,
      },
      imageStyle: {
        maxWidth: '90%',
        maxHeight: '90%',
        objectFit: 'contain',
      },
      videoWidth: '100%',
      videoHeight: '70vh',
      videoStyle: {
        backgroundColor: activePalette.background.dark,
      },
      pdfHeight: '85vh',
      zoomControls: {
        enable: true,
        minZoom: 0.5,
        maxZoom: 3,
        defaultZoom: 1,
      },
      downloadButton: {
        enable: true,
      },
      fullscreenButton: {
        enable: true,
      },
    },
    audio: {
      controls: true,
      style: {
        width: '100%',
        maxWidth: '500px',
      },
    },
    document: {
      height: '500px',
      pdfViewer: {
        height: '700px',
        width: '100%',
        border: `1px solid ${activePalette.primary}`,
        toolbarPosition: 'top',
        enableAnnotations: true,
        scale: 'page-fit',
      },
    },
    pdf: {
      inMessage: {
        height: '600px',
        width: '100%',
        controls: true,
        scale: 'page-fit',
      },
      inLightbox: {
        height: '85vh',
        width: '100%',
        controls: true,
        scale: 'page-fit',
      },
    },
    carousel: {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    },
  },
};

export default chatConfig;
import React, { useState, useEffect, useMemo, forwardRef } from 'react';
import { 
  Box, Card, CardContent, Typography, CircularProgress, Alert, Grid, Button, 
  useTheme, LinearProgress, List, ListItem, ListItemText, ListItemIcon, 
  Chip, Tooltip, IconButton, Paper, ToggleButtonGroup, ToggleButton
} from '@mui/material';
import { 
  ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip as RechartsTooltip
} from 'recharts';
import { getCTAAnalytics, getCTAButtons } from '../../services/api';
import { selectedIcons } from '../iconList';
import InfoButton from '../InfoButton';

const getButtonIcon = (iconName) => {
  const IconComponent = selectedIcons[iconName];
  return IconComponent ? <IconComponent /> : null;
};

const ButtonIcon = forwardRef(({ button, ...props }, ref) => {
  const IconComponent = selectedIcons[button.buttonDetails.icon];

  if (IconComponent) {
    return <IconComponent ref={ref} {...props} />;
  }

  return (
    <Box
      ref={ref}
      sx={{
        width: 24,
        height: 24,
        backgroundColor: button.buttonDetails.color,
        borderRadius: '50%',
      }}
      {...props}
    />
  );
});

ButtonIcon.displayName = 'ButtonIcon';

const CTAButtonAnalyticsDashboard = ({ startDate, endDate }) => {
  const [ctaAnalytics, setCTAAnalytics] = useState(null);
  const [ctaButtons, setCTAButtons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedButtonId, setSelectedButtonId] = useState('all');
  const theme = useTheme();

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [analyticsData, buttonsData] = await Promise.all([
          getCTAAnalytics(startDate, endDate),
          getCTAButtons()
        ]);
        

        setCTAAnalytics(analyticsData);
        setCTAButtons(buttonsData);
        setError(null);
      } catch (error) {
        
        setError('Failed to load CTA data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const combinedData = useMemo(() => {
    if (!ctaAnalytics || !ctaAnalytics.cta_analytics) return [];
    return (ctaAnalytics.cta_analytics || []).map(analytics => {
      const matchingButton = ctaButtons.find(button => button.id === analytics._id);
      return {
        ...analytics,
        buttonDetails: matchingButton || { text: `Button ${analytics._id.slice(-4)}`, color: '#999999' }
      };
    });
  }, [ctaAnalytics, ctaButtons]);

  const selectedButton = useMemo(() => {
    if (selectedButtonId === 'all') {
      return {
        _id: 'all',
        buttonDetails: { text: 'All Buttons', color: '#666666' },
        total_displays: combinedData.reduce((sum, button) => sum + (button.total_displays || 0), 0),
        total_clicks: combinedData.reduce((sum, button) => sum + (button.total_clicks || 0), 0),
      };
    }
    return combinedData.find(button => button._id === selectedButtonId) || combinedData[0] || {};
  }, [selectedButtonId, combinedData]);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (combinedData.length === 0) {
    return <Typography>No CTA analytics data available for the selected period.</Typography>;
  }

  const pieData = [
    { name: 'Displays', value: selectedButton.total_displays || 0 },
    { name: 'Clicks', value: selectedButton.total_clicks || 0 },
  ];

  const ctr = ((selectedButton.total_clicks || 0) / (selectedButton.total_displays || 1)) * 100 || 0;

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" gutterBottom color="text.primary">
            CTA Button Analytics Dashboard
          </Typography>
          <InfoButton title="Analysis of CTA button performance including displays, clicks, and CTR" />
        </Box>
        
        {/* Button Selection */}
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
          <Typography variant="h6" gutterBottom>Select CTA Button</Typography>
          <ToggleButtonGroup
      value={selectedButtonId}
      exclusive
      onChange={(event, newValue) => setSelectedButtonId(newValue || 'all')}
      aria-label="CTA Button selection"
    >
      <ToggleButton value="all" aria-label="All Buttons">
        <Tooltip title="All Buttons">
          <ButtonIcon button={{ buttonDetails: { icon: 'Star' } }} />
        </Tooltip>
      </ToggleButton>
      {combinedData.map((button) => (
        <ToggleButton key={button._id} value={button._id} aria-label={button.buttonDetails.text}>
          <Tooltip title={button.buttonDetails.text}>
            <ButtonIcon button={button} />
          </Tooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
        </Paper>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>Performance Overview</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    innerRadius={80}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    fillOpacity={0.6}
              strokeWidth={0} 
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <RechartsTooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>Button Details</Typography>
              <Box display="flex" alignItems="center" mb={2}>
                {selectedButtonId !== 'all' && (
                  <Button
                    variant={selectedButton.buttonDetails.variant || 'contained'}
                    startIcon={getButtonIcon(selectedButton.buttonDetails.icon)}
                    style={{ backgroundColor: selectedButton.buttonDetails.color, color: 'white', marginRight: theme.spacing(2) }}
                  >
                    {selectedButton.buttonDetails.text}
                  </Button>
                )}
                <Typography variant="body2">
                  {selectedButtonId === 'all' ? 'All Buttons' : `ID: ${selectedButton._id}`}
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2">Total Displays</Typography>
                  <Typography variant="h6">{selectedButton.total_displays?.toLocaleString() || 0}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Total Clicks</Typography>
                  <Typography variant="h6">{selectedButton.total_clicks?.toLocaleString() || 0}</Typography>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Typography variant="body2">Click-Through Rate (CTR)</Typography>
                <Box display="flex" alignItems="center">
                  <LinearProgress 
                    variant="determinate" 
                    value={ctr} 
                    sx={{
                      height: 10,
                      borderRadius: 6,
                      flexGrow: 1,
                      mr: 1,
                      backgroundColor: theme.palette.grey[300],
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 6,
                        backgroundColor: theme.palette.success.main,
                      },
                    }}
                  />
                  <Typography variant="body2">{`${ctr.toFixed(2)}%`}</Typography>
                </Box>
              </Box>
              {selectedButtonId !== 'all' && (
                <Box mt={2}>
                  <Typography variant="body2">Keywords</Typography>
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {selectedButton.buttonDetails.keywords?.map((keyword, index) => (
                      <Chip key={index} label={keyword} size="small" />
                    ))}
                  </Box>
                </Box>
              )}
            </Paper>
          </Grid>
          {selectedButtonId !== 'all' && (
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Actions</Typography>
                <List dense>
                  {selectedButton.buttonDetails.actions?.map((action, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        {getButtonIcon(action.type === 'link' ? 'Link' : action.type)}
                      </ListItemIcon>
                      <ListItemText 
                        primary={`${action.type.charAt(0).toUpperCase() + action.type.slice(1)}`}
                        secondary={action.url ? `URL: ${action.url}` : ''}
                      />
                      {action.url && (
                        <Tooltip title="Open link">
                          <IconButton size="small" onClick={() => window.open(action.url, '_blank')}>
                            {getButtonIcon('OpenInNew')}
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CTAButtonAnalyticsDashboard;
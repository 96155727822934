import { parseISO, format, isValid } from 'date-fns';

export const formatChartDate = (dateString) => {
  try {
    const date = parseISO(dateString);
    if (!isValid(date)) {
      console.error('Invalid chart date:', dateString);
      return '';
    }
    return format(date, 'MMM dd');
  } catch (error) {
    console.error('Error formatting chart date:', error);
    return '';
  }
};

import React, { useMemo } from 'react';
import { Card, CardContent, Typography, useTheme, alpha, CircularProgress } from '@mui/material';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import InfoButton from '../InfoButton';

const BusiestTimesChart = ({ interactionsData, isLoading }) => {
  const theme = useTheme();

  // Process the data internally
  const timeOfDayChartData = useMemo(() => {
    if (!interactionsData || interactionsData.length === 0) return [];

    console.log('Sample timestamps:', interactionsData.slice(0, 5).map(i => i.timestamp));

    const timeOfDayData = {};
    interactionsData.forEach(interaction => {
      // Parse the custom date format
      const [timePart] = interaction.timestamp.split(', ');
      const [hour] = timePart.split(':');
      const parsedHour = parseInt(hour, 10);
      
      console.log('Original timestamp:', interaction.timestamp);
      console.log('Parsed hour:', parsedHour);

      if (!isNaN(parsedHour)) {
        timeOfDayData[parsedHour] = (timeOfDayData[parsedHour] || 0) + 1;
      }
    });

    const result = Object.entries(timeOfDayData)
      .map(([time, value]) => ({
        time: parseInt(time, 10),
        value
      }))
      .sort((a, b) => a.time - b.time);

    console.log('Processed timeOfDayChartData:', result);
    return result;
  }, [interactionsData]);

  // Custom tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: theme.palette.background.paper, padding: '10px', border: `1px solid ${theme.palette.divider}` }}>
          <p>{`Time: ${label.toString().padStart(2, '0')}:00 - ${((label + 1) % 24).toString().padStart(2, '0')}:00`}</p>
          <p>{`Questions: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  // Render loading state
  if (isLoading) {
    return (
      <Card>
        <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  // Render error state if no data
  if (!timeOfDayChartData || timeOfDayChartData.length === 0) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom color="text.primary">
            Busiest Times of Day
          </Typography>
          <Typography color="text.secondary">No data available for Busiest Times of Day</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="text.primary">
          Busiest Times of Day
        </Typography>
        <InfoButton title="Displays the distribution of questions asked throughout the day, helping identify peak usage times" />
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={timeOfDayChartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" stroke={alpha(theme.palette.text.primary, 0.1)} />
            <XAxis 
              dataKey="time" 
              tickFormatter={(value) => `${value.toString().padStart(2, '0')}:00`} 
              stroke={theme.palette.text.primary}
            />
            <YAxis stroke={theme.palette.text.primary} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Area 
              type="monotone" 
              dataKey="value" 
              name="Questions" 
              stroke={theme.palette.primary.main}
              fill={alpha(theme.palette.primary.main, 0.4)}
              fillOpacity={0.8}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default BusiestTimesChart;
import React from 'react';
import { ButtonGroup, Button, Stack, useTheme } from '@mui/material';
import { subDays, startOfMonth, endOfMonth, subMonths } from 'date-fns';

const DateRangePresets = ({ handleDateRangeChange, activePreset, isMobile }) => {
  const theme = useTheme();
  
  const presets = [
    { label: 'Last 7 days', start: subDays(new Date(), 6), end: new Date() },
    { label: 'Yesterday', start: subDays(new Date(), 1), end: subDays(new Date(), 1) },
    { label: 'Last 30 days', start: subDays(new Date(), 29), end: new Date() },
    { label: 'This month', start: startOfMonth(new Date()), end: new Date() },
    { label: 'Last month', start: startOfMonth(subMonths(new Date(), 1)), end: endOfMonth(subMonths(new Date(), 1)) },
  ];

  if (isMobile) {
    return (
      <Stack spacing={1} width="100%">
        {presets.map((preset, index) => (
          <Button 
            key={index}
            onClick={() => handleDateRangeChange(preset.start, preset.end, index)}
            variant={activePreset === index ? "contained" : "outlined"}
            sx={{
              backgroundColor: activePreset === index ? theme.palette.primary.main : 'transparent',
              color: activePreset === index ? theme.palette.primary.contrastText : theme.palette.text.primary,
              '&:hover': {
                backgroundColor: activePreset === index ? theme.palette.primary.dark : theme.palette.action.hover,
              },
            }}
            fullWidth
          >
            {preset.label}
          </Button>
        ))}
      </Stack>
    );
  }

  return (
    <ButtonGroup variant="outlined" size="medium" aria-label="date range presets">
      {presets.map((preset, index) => (
        <Button 
          key={index}
          onClick={() => handleDateRangeChange(preset.start, preset.end, index)}
          variant={activePreset === index ? "contained" : "outlined"}
          sx={{
            backgroundColor: activePreset === index ? theme.palette.primary.main : 'transparent',
            color: activePreset === index ? theme.palette.primary.contrastText : theme.palette.text.primary,
            '&:hover': {
              backgroundColor: activePreset === index ? theme.palette.primary.dark : theme.palette.action.hover,
            },
          }}
        >
          {preset.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default DateRangePresets;
import React, { useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import EnhancedCTAButton from './EnhancedCTAButton';
import { recordCTAInteraction } from '../services/api';

const CTAButtonRenderer = ({ message, ctaButtons, onAnalytics, sessionId }) => {
  const matchingButtons = useMemo(() => {
    if (!ctaButtons || ctaButtons.length === 0) return [];
    
    const lowerMessage = message.toLowerCase();
    return ctaButtons.filter(button => 
      button.keywords.some(keyword => lowerMessage.includes(keyword.toLowerCase()))
    );
  }, [message, ctaButtons]);

  const displayedButtonsRef = useRef(new Set());

  useEffect(() => {
    const newDisplayedButtons = new Set();
    matchingButtons.forEach(button => {
      if (!displayedButtonsRef.current.has(button.id)) {
        recordCTAInteraction({
          session_id: sessionId,
          cta_button_id: button.id,
          action_type: 'display'
        });
        newDisplayedButtons.add(button.id);
      }
    });
    displayedButtonsRef.current = newDisplayedButtons;
  }, [matchingButtons, sessionId]);

  if (matchingButtons.length === 0) return null;

  return (
    <Box mt={2} display="flex" flexDirection="column" gap={1}>
      {matchingButtons.map(button => (
        <EnhancedCTAButton 
          key={button.id} 
          button={button} 
          onAnalytics={onAnalytics}
          sessionId={sessionId}
          // Pass a prop to indicate that display has been recorded
          displayRecorded={true}
        />
      ))}
    </Box>
  );
};

export default CTAButtonRenderer;
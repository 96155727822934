import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Paper, 
  Grid, 
  TextField, 
  Button, 
  Avatar, 
  Snackbar, 
  CircularProgress,
  Divider,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EmailIcon from '@mui/icons-material/Email';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { updateUserProfile, getUserProfile, getAdminEmail } from '../services/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  marginRight: theme.spacing(2),
}));

const FormSection = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const UserProfile = ({ username }) => {
  const [profile, setProfile] = useState({
    email: '',
    phone: '',
    location: ''
  });
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [adminEmail, setAdminEmail] = useState('');
  const [avatarError, setAvatarError] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchUserProfile();
    fetchAdminEmail();
  }, []);

  const fetchUserProfile = async () => {
    setIsLoading(true);
    try {
      const userData = await getUserProfile();
      setProfile(userData);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setSnackbar({ open: true, message: 'Failed to load profile', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAdminEmail = async () => {
    try {
      const email = await getAdminEmail();
      setAdminEmail(email);
    } catch (error) {
      console.error('Error fetching admin email:', error);
    }
  };

  const handleSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        await updateUserProfile(profile);
        setIsEditing(false);
        setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
      } catch (error) {
        console.error('Error updating profile:', error);
        setSnackbar({ open: true, message: 'Failed to update profile', severity: 'error' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: null });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!profile.email) newErrors.email = 'Email is required';
    if (!profile.phone) newErrors.phone = 'Phone is required';
    if (!profile.location) newErrors.location = 'Location is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEmailAdmin = () => {
    window.location.href = `mailto:${adminEmail}`;
  };

  const handleAvatarError = () => {
    setAvatarError(true);
  };

  return (
    <StyledPaper elevation={0}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} container alignItems="center" justifyContent="flex-start">
          <StyledAvatar 
            alt={`Profile picture of ${username}`}
            src={avatarError ? '' : '/images/user.png'}
            onError={handleAvatarError}
          >
            {avatarError && <SmartToyIcon aria-label="Default profile icon" />}
          </StyledAvatar>
          <Typography variant="h1" sx={{ fontSize: 'h4.fontSize' }}>{username}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider aria-hidden="true" />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSection>
            <div>
              <Typography variant="h2" sx={{ fontSize: 'h6.fontSize' }} gutterBottom>Personal Information</Typography>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={profile.email}
                onChange={handleChange}
                disabled={!isEditing}
                margin="normal"
                error={!!errors.email}
                helperText={errors.email}
                inputProps={{
                  'aria-label': 'Email address',
                  'aria-invalid': !!errors.email,
                  'aria-describedby': errors.email ? 'email-error' : undefined,
                }}
              />
              {errors.email && <span id="email-error" style={{ display: 'none' }}>{errors.email}</span>}
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={profile.phone}
                onChange={handleChange}
                disabled={!isEditing}
                margin="normal"
                error={!!errors.phone}
                helperText={errors.phone}
                inputProps={{
                  'aria-label': 'Phone number',
                  'aria-invalid': !!errors.phone,
                  'aria-describedby': errors.phone ? 'phone-error' : undefined,
                }}
              />
              {errors.phone && <span id="phone-error" style={{ display: 'none' }}>{errors.phone}</span>}
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={profile.location}
                onChange={handleChange}
                disabled={!isEditing}
                margin="normal"
                error={!!errors.location}
                helperText={errors.location}
                inputProps={{
                  'aria-label': 'Location',
                  'aria-invalid': !!errors.location,
                  'aria-describedby': errors.location ? 'location-error' : undefined,
                }}
              />
              {errors.location && <span id="location-error" style={{ display: 'none' }}>{errors.location}</span>}
            </div>
            <Box mt={2}>
              {isEditing ? (
                <Grid container spacing={2} justifyContent={isMobile ? 'center' : 'flex-end'}>
                  <Grid item xs={isMobile ? 12 : 'auto'}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      disabled={isLoading}
                      startIcon={isLoading ? <CircularProgress size={20} /> : <SaveIcon />}
                      fullWidth={isMobile}
                      aria-label={isLoading ? "Saving profile changes" : "Save profile changes"}
                    >
                      {isLoading ? 'Saving...' : 'Save'}
                    </Button>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 'auto'}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setIsEditing(false);
                        setErrors({});
                        fetchUserProfile();
                      }}
                      startIcon={<CancelIcon />}
                      fullWidth={isMobile}
                      aria-label="Cancel profile changes"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsEditing(true)}
                  startIcon={<EditIcon />}
                  fullWidth={isMobile}
                  aria-label="Edit profile information"
                >
                  Edit Profile
                </Button>
              )}
            </Box>
          </FormSection>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSection>
            <div>
              <Typography variant="h2" sx={{ fontSize: 'h6.fontSize' }} gutterBottom>Contact Admin</Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                If you need assistance or have any questions, feel free to contact the admin directly.
              </Typography>
            </div>
            <Box mt={2}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<EmailIcon />}
                onClick={handleEmailAdmin}
                disabled={!adminEmail}
                fullWidth
                aria-label="Send email to admin"
              >
                Email Admin
              </Button>
            </Box>
          </FormSection>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
        role="alert"
        aria-live="polite"
      />
    </StyledPaper>
  );
};

export default UserProfile;
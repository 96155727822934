import React from 'react';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import InfoButton from '../InfoButton'; // Assuming you'll create this as a separate component

const DailyQuestionsSessionsChart = ({ dailyData }) => {
  const theme = useTheme();

  const labelStyle = {
    fill: theme.palette.text.primary,
    fontSize: 12,
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="text.primary">
          Daily Questions and Sessions
        </Typography>
        <InfoButton title="Shows the daily number of questions asked and unique user sessions over time" />
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={dailyData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
  dataKey="formattedDate" 
  tick={labelStyle || { fill: '#000', fontSize: 12 }} 
  tickFormatter={(value = '') => value} 
/>
            <YAxis tick={labelStyle || { fill: '#000', fontSize: 12 }} />

            <Tooltip 
              formatter={(value, name, props) => [value, name]} 
              labelFormatter={(label) => label} 
              contentStyle={{
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '10px',
              }}
            />
            <Legend />
            <Area 
              type="monotone" 
              dataKey="interactions" 
              name="Questions" 
              stroke="#8884d8" 
              fill="#8884d8" 
              fillOpacity={0.3} 
            />
            <Area 
              type="monotone" 
              dataKey="sessions" 
              name="Sessions" 
              stroke="#82ca9d" 
              fill="#82ca9d" 
              fillOpacity={0.3} 
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default DailyQuestionsSessionsChart;

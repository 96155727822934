import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import AnimatedBotIcon from './AnimatedBotIcon';
import { getSettings } from '../services/api';
import chatConfig from './chatConfig';


// Define animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const bounceIn = keyframes`
  0% { transform: scale(0.3); opacity: 0; }
  50% { transform: scale(1.05); opacity: 1; }
  70% { transform: scale(0.9); }
  100% { transform: scale(1); }
`;

const zoomIn = keyframes`
  from { transform: scale(0); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
`;

const flipIn = keyframes`
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
`;

const swingIn = keyframes`
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
`;

// Function to get the animation based on type
const getAnimation = (type) => {
  switch (type) {
    case 'fadeIn': return fadeIn;
    case 'slideIn': return slideIn;
    case 'bounceIn': return bounceIn;
    case 'zoomIn': return zoomIn;
    case 'flipIn': return flipIn;
    case 'swingIn': return swingIn;
    default: return fadeIn;
  }
};

// Styled Typography with dynamic styles
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: chatConfig.welcomeMessage.fontSize.default,
  fontWeight: chatConfig.welcomeMessage.fontWeight,
  color: theme.palette.text.primary,
  animation: `${getAnimation(chatConfig.welcomeMessage.animation.type)} ${chatConfig.welcomeMessage.animation.duration} ${chatConfig.welcomeMessage.animation.easing} ${chatConfig.welcomeMessage.animation.delay}`,
  [theme.breakpoints.down('sm')]: {
    fontSize: chatConfig.welcomeMessage.fontSize.mobile,
  },
}));

// Container for the welcome message
const WelcomeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2),
  maxWidth: '100%',
}));

// Wrapper for the icon
// Wrapper for the icon with padding
const IconWrapper = styled(Box)(({ theme }) => {
  const padding = chatConfig.botIcon?.padding?.default || '0'; // Default to '0' if padding is not defined
  const mobilePadding = chatConfig.botIcon?.padding?.mobile || '0'; // Default to '0' if mobile padding is not defined

  return {
    flexShrink: 0,
    width: chatConfig.botIcon?.size || '50px', // Default size if not defined
    height: chatConfig.botIcon?.size || '50px', // Default size if not defined
    marginRight: theme.spacing(chatConfig.welcomeMessage?.iconSpacing?.default || 3), // Default spacing if not defined
    padding: padding, // Apply default padding
    [theme.breakpoints.down('sm')]: {
      width: (chatConfig.botIcon?.size || 50) * 0.8,
      height: (chatConfig.botIcon?.size || 50) * 0.8,
      marginRight: theme.spacing(chatConfig.welcomeMessage?.iconSpacing?.mobile || 2), // Mobile spacing
      padding: mobilePadding, // Apply mobile padding
    },
  };
});



// Wrapper for the text with padding
const TextWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  
  paddingLeft: chatConfig.welcomeMessage.padding.default.split(' ')[1], // left-right padding
  paddingRight: chatConfig.welcomeMessage.padding.default.split(' ')[1],
  paddingTop: chatConfig.welcomeMessage.padding.default.split(' ')[0], // top padding
  paddingBottom: chatConfig.welcomeMessage.padding.default.split(' ')[0],
  [theme.breakpoints.down('sm')]: {
    paddingLeft: chatConfig.welcomeMessage.padding.mobile.split(' ')[1],
    paddingRight: chatConfig.welcomeMessage.padding.mobile.split(' ')[1],
    paddingTop: chatConfig.welcomeMessage.padding.mobile.split(' ')[0],
    paddingBottom: chatConfig.welcomeMessage.padding.mobile.split(' ')[0],
  },
}));

const AnimatedWelcomeText = () => {
  const [text, setText] = useState('');
  const [fullText, setFullText] = useState(chatConfig.welcomeMessage.text);
  const typingSpeed = chatConfig.welcomeMessage.typingSpeed;

  useEffect(() => {
    const fetchWelcomeMessage = async () => {
      try {
        const settings = await getSettings();
        if (settings && settings.welcomeMessage) {
          setFullText(settings.welcomeMessage);
        } else {
          console.warn('Welcome message not found in settings, using default from chatConfig');
        }
      } catch (error) {
        console.error('Error fetching welcome message:', error);
        // Keep the default message set from chatConfig
      }
    };

    fetchWelcomeMessage();
  }, []);

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, typingSpeed);

    return () => clearInterval(intervalId);
  }, [fullText, typingSpeed]);

  return (
    <WelcomeContainer>
      <IconWrapper>
        <AnimatedBotIcon />
      </IconWrapper>
      <TextWrapper>
        <StyledTypography variant="h1" component="h1">
          {text}
        </StyledTypography>
      </TextWrapper>
    </WelcomeContainer>
  );
};

export default AnimatedWelcomeText;

import React, { useRef, useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Tooltip, IconButton, useTheme } from '@mui/material';
import { TagCloud } from 'react-tagcloud';
import InfoIcon from '@mui/icons-material/Info';

const WordCloudWithTooltips = ({ wordCloudData, maxFontSize = 100, isLoading }) => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const InfoButton = ({ title }) => (
    <Tooltip title={title}>
      <IconButton size="small">
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  const customRenderer = (tag, size, color) => {
    return (
      <Tooltip
        key={tag.value}
        title={
          <Typography variant="body2">
            <strong>{tag.value}</strong>: {tag.count} occurrences
          </Typography>
        }
      >
        <span
          style={{
            fontSize: `${size}px`,
            color: color,
            display: 'inline-block',
            margin: '3px',
            cursor: 'pointer',
          }}
        >
          {tag.value}
        </span>
      </Tooltip>
    );
  };

  return (
    <Card sx={{ boxShadow: theme.shadows[1], borderRadius: 2, height: '100%' }}>
      <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="text.primary" fontWeight="medium">
            Questions Word Cloud
          </Typography>
          <InfoButton title="Visual representation of word frequency in questions, with larger words appearing more often" />
        </Box>
        <Box
          ref={containerRef}
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <TagCloud 
            minSize={12}
            maxSize={Math.min(dimensions.width / 8, dimensions.height / 8, maxFontSize)}
            tags={wordCloudData}
            renderer={customRenderer}
            onClick={(tag) => console.log(`'${tag.value}' was selected!`)}
            style={{
              width: '100%',
              height: '100%',
              padding: '10px',
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default WordCloudWithTooltips;
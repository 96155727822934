import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import {
  Box, Typography, IconButton, Modal, Fade, CircularProgress,
  Tooltip, styled
} from '@mui/material';
import {
  Close, ZoomIn, ZoomOut, Fullscreen, FullscreenExit,
  PlayArrow, Pause, VolumeUp, VolumeOff, ChevronLeft, ChevronRight,
  ZoomOutMap, GetApp
} from '@mui/icons-material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ErrorBoundary from './ErrorBoundary';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';

const LightboxContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullscreen' && prop !== 'config'
})(({ theme, isFullscreen, config }) => ({
  position: isFullscreen ? 'fixed' : 'absolute',
  top: isFullscreen ? 0 : '50%',
  left: isFullscreen ? 0 : '50%',
  transform: isFullscreen ? 'none' : 'translate(-50%, -50%)',
  width: isFullscreen ? '100vw' : config.width,
  height: isFullscreen ? '100vh' : config.height,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: isFullscreen ? 0 : theme.spacing(2),
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  ...config.style,
}));

const MediaContent = styled('div')(({ theme, contentType, config = {}, isLightbox }) => ({
  maxWidth: '100%',
  height: isLightbox ? '80vh' : '400px',
  width: isLightbox ? '80vw' : '100%',
  objectFit: contentType?.startsWith('image') ? 'contain' : 'cover',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...(config.imageStyle || {}),
}));

const ControlsWrapper = styled(Box)(({ theme, config }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
  ...config.controls,
}));

const CarouselContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  '& .slick-prev, & .slick-next': {
    zIndex: 1,
    '&::before': {
      display: 'none',
    },
  },
  '& .slick-dots': {
    display: 'none !important', // Force hide any remaining dots
  },
  paddingBottom: theme.spacing(4),
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: theme.palette.common.white,
  padding: theme.spacing(1),
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
  },
}));

const CustomArrow = React.forwardRef(({ onClick, direction, ...props }, ref) => {
  const isLeft = direction === 'left';
  return (
    <ArrowButton
      ref={ref}
      onClick={onClick}
      style={{ [isLeft ? 'left' : 'right']: 8 }}
      {...props}
    >
      {isLeft ? <ChevronLeft /> : <ChevronRight />}
    </ArrowButton>
  );
});



const MediaRenderer = ({ mediaItems = [], initialIndex = 0, config = {}, darkMode }) => {
 
  
  const isYouTubeUrl = useCallback((url) => {
  return url && (url.includes('youtube.com') || url.includes('youtu.be'));
}, []);
  
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [open, setOpen] = useState(false);
  const [loading] = useState(false);
  const [error] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const sliderRef = useRef(null);
  const videoRef = useRef(null);

  const handleZoomIn = useCallback(() => setZoom(prev => Math.min(prev + 0.1, config.lightbox.zoomControls.maxZoom)), [config.lightbox.zoomControls.maxZoom]);
  const handleZoomOut = useCallback(() => setZoom(prev => Math.max(prev - 0.1, config.lightbox.zoomControls.minZoom)), [config.lightbox.zoomControls.minZoom]);
  const handleResetZoom = useCallback(() => setZoom(1), []);

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setIsFullscreen(prev => !prev);
  }, []);

  const togglePlay = useCallback(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  }, [isPlaying]);

  const toggleMute = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  }, [isMuted]);

  const handleNext = useCallback(() => {
    if (mediaItems.length > 1) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaItems.length);
    }
  }, [mediaItems.length]);

  const handlePrevious = useCallback(() => {
    if (mediaItems.length > 1) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + mediaItems.length) % mediaItems.length);
    }
  }, [mediaItems.length]);

  const getFileNameWithoutExtension = useCallback((filename) => {
    if (!filename) return '';
    return filename.split('.').slice(0, -1).join('.') || filename;
  }, []);

  const handleDownload = useCallback((item) => {
    if (item && (item.url || item.filename)) {
      const link = document.createElement('a');
      link.href = item.url || `${BACKEND_URL}/api/media/${item.filename}`;
      link.download = item.original_filename || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, []);

  const getDisplayName = useCallback((item) => {
  let displayName = item.original_filename || item.custom_filename || item.filename || '';
  return getFileNameWithoutExtension(displayName);
}, [getFileNameWithoutExtension]);

  const isMediaType = useCallback((type, item) => {
  if (item.file_type) {
    return item.file_type.toLowerCase() === type.toLowerCase();
  }
  if (item.type) {
    return item.type.toLowerCase() === type.toLowerCase();
  }
  if (type === 'video' && item.url && isYouTubeUrl(item.url)) return true;
  return false;
}, [isYouTubeUrl]);

  const isPDF = useCallback((item) => {
    if (item.type && item.type.toLowerCase().includes('pdf')) return true;
    if (item.filename && item.filename.toLowerCase().endsWith('.pdf')) return true;
    if (item.url && item.url.toLowerCase().endsWith('.pdf')) return true;
    return false;
  }, []);

  

  const getYouTubeEmbedUrl = useCallback((url) => {
  if (!url) return null;
  let videoId;
  if (url.includes('youtu.be')) {
    videoId = url.split('/').pop().split('?')[0];
  } else if (url.includes('youtube.com')) {
    const urlParams = new URLSearchParams(new URL(url).search);
    videoId = urlParams.get('v');
  }
  return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
}, []);

  
  
  
  
  

  const renderMediaItem = useCallback((item, inLightbox = false) => {
    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;
    if (!item) return <Typography>No media available</Typography>;

    const mediaUrl = item.url || (item.filename ? `${BACKEND_URL}/api/media/${item.filename}` : null);
    if (!mediaUrl) return <Typography>Invalid media source</Typography>;

    const commonStyle = inLightbox 
    ? { 
        transform: `scale(${zoom})`, 
        transition: 'transform 0.2s',
        ...(config.lightbox?.imageStyle || {})
      } 
    : { ...(config.display || {}) };

  const videoStyle = inLightbox
    ? { ...commonStyle, ...(config.lightbox?.videoStyle || {}) }
    : { ...commonStyle, ...(config.video?.style || {}) };

  if (isYouTubeUrl(item.url)) {
    const embedUrl = getYouTubeEmbedUrl(item.url);
    if (!embedUrl) return <Typography>Invalid YouTube URL</Typography>;
    
    return (
      <MediaContent style={videoStyle} contentType="video" config={config} isLightbox={inLightbox}>
        <iframe
          src={embedUrl}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video"
        />
      </MediaContent>
    );
  } 

    if (isMediaType('image', item)) {
      return (
        <MediaContent style={commonStyle} contentType="image" config={config} isLightbox={inLightbox}>
          <img 
            src={mediaUrl} 
            alt={item.original_filename || 'Image preview'} 
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          />
        </MediaContent>
      );
    } else if (isMediaType('video', item) || isYouTubeUrl(item.url)) {
  const videoStyle = inLightbox
    ? { ...commonStyle, ...(config.lightbox?.videoStyle || {}) }
    : { ...commonStyle, ...(config.video?.style || {}) };
  
  if (isYouTubeUrl(mediaUrl)) {
  const embedUrl = getYouTubeEmbedUrl(mediaUrl);
  if (!embedUrl) return <Typography>Invalid YouTube URL</Typography>;
  
  return (
    <MediaContent style={videoStyle} contentType="video" config={config} isLightbox={inLightbox}>
      <iframe
        src={embedUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video"
      />
    </MediaContent>
  );
} else {
        return (
          <MediaContent style={videoStyle} contentType="video" config={config} isLightbox={inLightbox}>
            <video
              ref={videoRef}
              src={mediaUrl}
              controls={!inLightbox}
              style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
              width="100%"
              height="100%"
              {...(config.video || {})}
            />
            {inLightbox && (
              <Box position="absolute" bottom={16} left="50%" sx={{ transform: 'translateX(-50%)' }}>
                <IconButton onClick={togglePlay} color="primary" aria-label={isPlaying ? "Pause" : "Play"}>
                  {isPlaying ? <Pause /> : <PlayArrow />}
                </IconButton>
                <IconButton onClick={toggleMute} color="primary" aria-label={isMuted ? "Unmute" : "Mute"}>
                  {isMuted ? <VolumeOff /> : <VolumeUp />}
                </IconButton>
              </Box>
            )}
          </MediaContent>
        );
      }
    } else if (isMediaType('audio', item)) {
      return (
        <MediaContent style={commonStyle} contentType="audio" config={config} isLightbox={inLightbox}>
          <audio src={mediaUrl} controls style={config.audio || {}} />
        </MediaContent>
      );
    } else if (isPDF(item)) {
      return (
        <MediaContent style={commonStyle} contentType="pdf" config={config} isLightbox={inLightbox}>
          <iframe
            src={`${mediaUrl}#view=FitH`}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            title="PDF Viewer"
          />
        </MediaContent>
      );
    } else {
      console.warn('Unsupported media type:', item.type);
      return <Typography>Unsupported media type: {item.type}</Typography>;
    }
  }, [loading, error, zoom, config, isPlaying, isMuted, togglePlay, toggleMute, videoRef, isMediaType, isPDF, isYouTubeUrl, getYouTubeEmbedUrl]);

  const CustomPrevArrow = useCallback((props) => {
    const { currentSlide, slideCount, ...rest } = props;
    return <CustomArrow direction="left" {...rest} />;
  }, []);

  const CustomNextArrow = useCallback((props) => {
    const { currentSlide, slideCount, ...rest } = props;
    return <CustomArrow direction="right" {...rest} />;
  }, []);

  const carouselSettings = useMemo(() => ({
  dots: config.showBottomDots ?? true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  beforeChange: (_, next) => setCurrentIndex(next),
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  appendDots: dots => (
    <div
      style={{
        bottom: '10px',
        padding: '50px'
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
  customPaging: i => (
    <div
      style={{
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        background: i === currentIndex ? "#1976d2" : "#bdbdbd"
      }}
    />
  ),
  ...config.carousel
}), [config.carousel, config.showBottomDots, currentIndex]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  if (!mediaItems || mediaItems.length === 0) {
    return <Typography>No media items available</Typography>;
  }

  return (
    <ErrorBoundary>
      <Box>
        <CarouselContainer>
          <Slider ref={sliderRef} {...carouselSettings}>
            {mediaItems.map((item, index) => (
              <ErrorBoundary key={index}>
                <Box position="relative">
                  <MediaContent
                    contentType={item.type}
                    config={config}
                    isLightbox={false}
                  >
                    {renderMediaItem(item, false)}
                  </MediaContent>
                  {config.zoomIcon?.enable && (
                    <Tooltip title="Open in lightbox" arrow>
                      <IconButton 
                        onClick={() => {
                          setCurrentIndex(index);
                          setOpen(true);
                        }}
                        sx={{ 
                          position: 'absolute',
                          right: config.zoomIcon?.position?.right,
                          bottom: config.zoomIcon?.position?.bottom,
                          backgroundColor: config.zoomIcon?.style?.backgroundColor,
                          color: config.zoomIcon?.style?.color,
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          },
                        }}
                      >
                        <ZoomOutMap />
                      </IconButton>
                    </Tooltip>
                    )}
                </Box>
              </ErrorBoundary>
            ))}
          </Slider>
        </CarouselContainer>

        <Modal 
          open={open} 
          onClose={() => setOpen(false)}
          closeAfterTransition
          aria-labelledby="lightbox-modal-title"
        >
          <Fade in={open}>
            <LightboxContainer isFullscreen={isFullscreen} config={config.lightbox}>
              <IconButton
                onClick={() => setOpen(false)}
                sx={{ position: 'absolute', right: 8, top: 8, zIndex: 1, color: 'rgba(69, 69, 69, 0.8)' }}
                aria-label="Close lightbox"
              >
                <Close />
              </IconButton>
              {mediaItems[currentIndex] && (
                <Typography 
                  variant="h6" 
                  id="lightbox-modal-title"
                  sx={{ 
                    marginTop: 2,
                    marginBottom: 2,
                    color: 'rgba(69, 69, 69, 0.8)',
                    textAlign: 'center',
                  }}
                >
                  {getDisplayName(mediaItems[currentIndex])}
                </Typography>
              )}
              <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', width: '100%' }}>
                {renderMediaItem(mediaItems[currentIndex], true)}
              </Box>
              <ControlsWrapper config={config.lightbox}>
                {mediaItems.length > 1 && (
                  <>
                    <IconButton onClick={handlePrevious} sx={{ color: 'rgba(69, 69, 69, 0.8)' }} aria-label="Previous item">
                      <ChevronLeft />
                    </IconButton>
                    <Typography sx={{ color: 'rgba(69, 69, 69, 0.8)', mx: 1 }}>
                      {`${currentIndex + 1} / ${mediaItems.length}`}
                    </Typography>
                    <IconButton onClick={handleNext} sx={{ color: 'rgba(69, 69, 69, 0.8)' }} aria-label="Next item">
                      <ChevronRight />
                    </IconButton>
                  </>
                )}
                {config.lightbox.zoomControls.enable && (
                  <>
                    <Tooltip title="Zoom out">
                      <IconButton onClick={handleZoomOut} sx={{ color: 'rgba(69, 69, 69, 0.8)' }} aria-label="Zoom out">
                        <ZoomOut />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Zoom in">
                      <IconButton onClick={handleZoomIn} sx={{ color: 'rgba(69, 69, 69, 0.8)' }} aria-label="Zoom in">
                        <ZoomIn />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reset zoom">
                      <IconButton onClick={handleResetZoom} sx={{ color: 'rgba(69, 69, 69, 0.8)' }} aria-label="Reset zoom">
                        <ZoomOutMap />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {config.lightbox.downloadButton.enable && (
                  <Tooltip title="Download">
                    <IconButton 
                      onClick={() => handleDownload(mediaItems[currentIndex])} 
                      sx={{ color: 'rgba(69, 69, 69, 0.8)' }}
                      aria-label="Download media"
                    >
                      <GetApp />
                    </IconButton>
                  </Tooltip>
                )}
                {config.lightbox.fullscreenButton.enable && (
                  <Tooltip title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}>
                    <IconButton 
                      onClick={toggleFullscreen} 
                      sx={{ color: 'rgba(69, 69, 69, 0.8)' }}
                      aria-label={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
                    >
                      {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                  </Tooltip>
                )}
              </ControlsWrapper>
            </LightboxContainer>
          </Fade>
        </Modal>
      </Box>
    </ErrorBoundary>
  );
};

export default MediaRenderer;
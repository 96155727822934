import React from 'react';
import { Button, Fab, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import * as Icons from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

const ButtonPreview = ({ buttonData }) => {
  const IconComponent = buttonData.icon && Icons[buttonData.icon] ? Icons[buttonData.icon] : null;

  if (buttonData.isToggle) {
    return (
      <ToggleButtonGroup
        value={buttonData.toggleOptions[0]}
        exclusive
        aria-label={buttonData.text}
      >
        {buttonData.toggleOptions.map((option, index) => (
          <ToggleButton key={index} value={option} style={{ backgroundColor: buttonData.color }}>
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }

  if (buttonData.isIconOnly) {
    return (
      <IconButton
        color="primary"
        aria-label={buttonData.text}
        style={{ backgroundColor: buttonData.color }}
      >
        {IconComponent && <IconComponent />}
      </IconButton>
    );
  }

  if (buttonData.isFab) {
    return (
      <Fab
        color="primary"
        aria-label={buttonData.text}
        style={{ backgroundColor: buttonData.color }}
      >
        {IconComponent && <IconComponent />}
        {!IconComponent && buttonData.text}
      </Fab>
    );
  }

  const ButtonComponent = buttonData.isLoading ? LoadingButton : Button;

  return (
    <ButtonComponent
      variant={buttonData.variant}
      size={buttonData.size}
      startIcon={IconComponent && <IconComponent />}
      loading={buttonData.isLoading}
      style={{ backgroundColor: buttonData.color }}
    >
      {buttonData.text}
    </ButtonComponent>
  );
};

export default ButtonPreview;

import React, { useState, useCallback, useMemo } from 'react';
import { Card, CardContent, Typography, Grid, Box, LinearProgress, useTheme, CircularProgress} from '@mui/material';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip, Sector } from 'recharts';
import InfoButton from '../InfoButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import RemoveIcon from '@mui/icons-material/Remove';

const UserFeedbackAnalysisChart = ({ totalFeedback = {}, isLoading }) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);

  const feedbackColors = {
    heart: '#E30B5C',
    thumbs_up: '#228B22',
    thumbs_down: '#F44336',
    no_feedback: '#9E9E9E'
  };

  const processFeedbackData = useMemo(() => {
    if (!totalFeedback || Object.keys(totalFeedback).length === 0) {
      return [];
    }
    return Object.entries(totalFeedback).map(([name, value]) => ({
      name,
      value,
      displayName: name === 'thumbs_up' ? 'Thumbs Up' :
                   name === 'thumbs_down' ? 'Thumbs Down' :
                   name === 'heart' ? 'Heart' : 
                   name === 'no_feedback' ? 'No Feedback' : name,
    })).filter(item => item.value > 0);
  }, [totalFeedback]);

  const getFeedbackIcon = (feedback) => {
    switch (feedback) {
      case 'thumbs_up':
        return <ThumbUpIcon color='#000000' />;
      case 'thumbs_down':
        return <ThumbDownIcon color="error" />;
      case 'heart':
        return <FavoriteIcon color="secondary" />;
      default:
        return <RemoveIcon color="action" />;
    }
  };

  const onPieEnter = useCallback((_, index) => {
    setActiveIndex(index);
  }, [setActiveIndex]);

  const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={theme.palette.text.primary}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={theme.palette.text.primary}>
          {`${value} (${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const calculateFeedbackPercentages = (totalFeedback) => {
    const total = Object.values(totalFeedback).reduce((sum, value) => sum + value, 0);
    if (total === 0) return { positivePercent: 0, userSatisfaction: 0 };

    const thumbsUp = totalFeedback.thumbs_up || 0;
    const heart = totalFeedback.heart || 0;
    const thumbsDown = totalFeedback.thumbs_down || 0;
    
    const positivePercent = ((thumbsUp + heart) / total) * 100;
    const userSatisfaction = ((thumbsUp + heart) / (thumbsUp + heart + thumbsDown)) * 100 || 0;
    
    return { positivePercent, userSatisfaction };
  };

  const { positivePercent, userSatisfaction } = calculateFeedbackPercentages(totalFeedback);

  if (isLoading) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom color="text.primary">
            User Feedback Analysis
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center" height="300px">
            <CircularProgress />
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (processFeedbackData.length === 0) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom color="text.primary">
            User Feedback Analysis
          </Typography>
          <Typography>No feedback data available.</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="text.primary">
          User Feedback Analysis
        </Typography>
        <InfoButton title="Breakdown of user feedback types (thumbs up, thumbs down, heart) and overall satisfaction rate" />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={processFeedbackData}
                  cx="50%"
                  cy="50%"
                  innerRadius={80}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                  fillOpacity={2}
                  strokeWidth={0} 
                >
                  {processFeedbackData.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={feedbackColors[entry.name] || '#8884d8'}
                    />
                  ))}
                </Pie>
                <Tooltip formatter={(value, name, props) => [value, props.payload.displayName]} />
                <Legend formatter={(value, entry) => entry.payload.displayName} />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
              <Box display="flex" justifyContent="space-around" alignItems="center" flexWrap="wrap">
                {processFeedbackData.map((item, index) => (
                  <Box key={index} textAlign="center" m={1}>
                    {getFeedbackIcon(item.name)}
                    <Typography variant="h6">{((item.value / Object.values(totalFeedback).reduce((a, b) => a + b, 0)) * 100).toFixed(1)}%</Typography>
                    <Typography variant="body2">{item.displayName}</Typography>
                  </Box>
                ))}
              </Box>
              <Box mt={3}>
                <Typography variant="body2" gutterBottom>Overall Positive Feedback</Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={positivePercent} 
                  sx={{
                    height: 10,
                    borderRadius: 6,
                    backgroundColor: theme.palette.grey[300],
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 6,
                      backgroundColor: theme.palette.success.main,
                    },
                  }}
                />
                <Typography variant="body2" align="right">{`${positivePercent.toFixed(1)}%`}</Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="body2" gutterBottom>User Satisfaction (excluding No Feedback)</Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={userSatisfaction} 
                  sx={{
                    height: 10,
                    borderRadius: 6,
                    backgroundColor: theme.palette.grey[300],
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 6,
                      backgroundColor: theme.palette.success.main,
                    },
                  }}
                />
                <Typography variant="body2" align="right">{`${userSatisfaction.toFixed(1)}%`}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserFeedbackAnalysisChart;
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  List, 
  ListItem, 
  ListItemIcon,
  ListItemText, 
  Drawer,
  useTheme,
  useMediaQuery,
  Divider,
  Fade,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import UserManagement from './UserManagement';
import SystemSettings from './SystemSettings';
import AdminOverview from './AdminOverview';
import { getSettings, updateSettings } from '../services/api';

const drawerWidth = 240;

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const fetchedSettings = await getSettings();
      setSettings(fetchedSettings);
      setError(null);
    } catch (err) {
      console.error('Error fetching settings:', err);
      setError('Failed to load settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setSettings(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await updateSettings(settings);
      setError(null);
    } catch (err) {
      console.error('Error updating settings:', err);
      setError('Failed to update settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography color="error">{error}</Typography>
        </Box>
      );
    }

    switch (activeTab) {
      case 'overview':
        return <AdminOverview />;
      case 'User Management':
        return <UserManagement />;
      case 'System Settings':
        return (
          <SystemSettings
            settings={settings}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            loading={loading}
            error={error}
          />
        );
      default:
        return <AdminOverview />;
    }
  };

  const navigationItems = [
    { text: 'Overview', icon: <DashboardIcon />, value: 'overview' },
    { text: 'User Management', icon: <PeopleIcon />, value: 'User Management' },
    { text: 'System Settings', icon: <SettingsIcon />, value: 'System Settings' },
  ];

  const drawer = (
    <List>
      {navigationItems.map((item) => (
        <ListItem 
          button 
          key={item.text} 
          onClick={() => setActiveTab(item.value)} 
          selected={activeTab === item.value}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  );

  const mobileNavigation = (
    <ToggleButtonGroup
      value={activeTab}
      exclusive
      onChange={(event, newValue) => {
        if (newValue !== null) {
          setActiveTab(newValue);
        }
      }}
      aria-label="Admin navigation"
      size="small"
      fullWidth
    >
      {navigationItems.map((item) => (
        <ToggleButton key={item.value} value={item.value} aria-label={item.text}>
          {item.icon}
          <Typography variant="body2" sx={{ ml: 1 }}>
            {item.text}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 66px)' }}>
      {!isMobile && (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              height: 'calc(100% - 66px)',
              top: '66px',
            },
          }}
        >
          {drawer}
        </Drawer>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: '100%',
          overflow: 'auto'
        }}
      >
        {isMobile && mobileNavigation}
        <Typography variant="h4" gutterBottom>
          {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Fade in={!loading}>
          <div>{renderContent()}</div>
        </Fade>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
import React, { useCallback, useRef, useEffect } from 'react';
import { Button, Fab, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import * as Icons from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { recordCTAInteraction } from '../services/api';
import { useTheme } from '@mui/material/styles';

const EnhancedCTAButton = ({ button, onAnalytics, sessionId, displayRecorded }) => {
  const theme = useTheme();
  const IconComponent = button.icon && Icons[button.icon] ? Icons[button.icon] : null;
  const interactionRecordedRef = useRef(false);

  const handleInteraction = useCallback(async (actionType, additionalData = {}) => {
    if (actionType === 'display' && (displayRecorded || interactionRecordedRef.current)) {
      return;
    }

    const interactionKey = `${button.id}-${actionType}-${Date.now()}`;
    if (localStorage.getItem(interactionKey)) {
      
      return;
    }
    
    localStorage.setItem(interactionKey, 'true');
    setTimeout(() => localStorage.removeItem(interactionKey), 5000);  // Clean up after 5 seconds

    try {
      const interactionData = {
        session_id: sessionId,
        cta_button_id: button.id,
        action_type: actionType,
        ...additionalData
      };
      await recordCTAInteraction(interactionData);
     
      if (actionType === 'display') {
        interactionRecordedRef.current = true;
      }
    } catch (error) {
      
    }
  }, [button.id, sessionId, displayRecorded]);

  const handleClick = useCallback(() => {
    handleInteraction('click');
    if (button.actions && button.actions.length > 0) {
      const action = button.actions[0];
      if (action.type === 'link' && action.url) {
        window.open(action.url, '_blank');
      } else if (action.type === 'phone' && action.number) {
        window.location.href = `tel:${action.number}`;
      } else if (action.type === 'email' && action.address) {
        window.location.href = `mailto:${action.address}`;
      }
    }
    if (button.action) {
      button.action();
    }
  }, [button, handleInteraction]);

  const handleToggleChange = useCallback((event, newValue) => {
    handleInteraction('toggle', { value: newValue });
    if (button.onToggle) {
      button.onToggle(newValue);
    }
  }, [button, handleInteraction]);

  useEffect(() => {
    handleInteraction('display');
  }, [handleInteraction]);

  if (button.isToggle) {
    return (
      <ToggleButtonGroup
        value={button.toggleValue}
        exclusive
        onChange={handleToggleChange}
        aria-label={button.text}
      >
        {button.toggleOptions.map((option, index) => (
          <ToggleButton key={index} value={option}>
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }

  if (button.isIconOnly) {
    return (
      <IconButton
        onClick={handleClick}
        color="primary"
        aria-label={button.text}
        style={{ backgroundColor: button.color }}
      >
        {IconComponent && <IconComponent />}
      </IconButton>
    );
  }

  if (button.isFab) {
    return (
      <Fab
        color="primary"
        aria-label={button.text}
        onClick={handleClick}
        style={{ backgroundColor: button.color }}
      >
        {IconComponent && (
          <IconComponent 
            sx={{ 
              color: theme.palette.mode === 'dark' ? 'white' : 'inherit'
            }} 
          />
        )}
        {!IconComponent && button.text}
      </Fab>
    );
  }

  const ButtonComponent = button.isLoading ? LoadingButton : Button;
  const buttonProps = {
    variant: button.variant || "contained",
    size: button.size || "medium",
    onClick: handleClick,
    startIcon: IconComponent && <IconComponent />,
    style: { backgroundColor: button.color },
    onMouseEnter: () => !displayRecorded && !interactionRecordedRef.current && handleInteraction('display'),
  };

  if (button.isLoading) {
    buttonProps.loading = true;
  }

  return (
    <ButtonComponent {...buttonProps}>
      {button.text}
    </ButtonComponent>
  );
};

export default EnhancedCTAButton;
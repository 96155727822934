import React, { useMemo } from 'react';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell } from 'recharts';
import InfoButton from '../InfoButton';

const TopWordsChart = ({ allQAData, isLoading }) => {
  const theme = useTheme();

  const topWords = useMemo(() => {
    const words = allQAData.flatMap(qa => qa.question.toLowerCase().split(/\W+/));
    const wordFreq = words.reduce((acc, word) => {
      if (word.length > 3) {  // Ignore short words
        acc[word] = (acc[word] || 0) + 1;
      }
      return acc;
    }, {});
    return Object.entries(wordFreq)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([word, count]) => ({ word, count }));
  }, [allQAData]);

  const pastelColors = [
    '#8884d8',  // Soft purple
    '#82ca9d',  // Soft green
    '#ffc658',  // Soft yellow
    '#ff8042',  // Soft orange
    '#0088FE',  // Soft blue
  ];

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="text.primary">
          Top 5 Most Common Words in Questions
        </Typography>
        <InfoButton title="Highlights the most frequently used words in user questions, giving insight into common topics" />
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={topWords} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
            <XAxis type="number" tick={{ fill: theme.palette.text.primary }} />
            <YAxis dataKey="word" type="category" tick={{ fill: theme.palette.text.primary }} />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 10,
                color: theme.palette.text.primary,
              }}
            />
            <Bar dataKey="count" name="Word Frequency">
              {topWords.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={pastelColors[index % pastelColors.length]} fillOpacity={0.7}/>
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default TopWordsChart;

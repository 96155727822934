import React, { useState } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TablePagination, 
  Chip, 
  useTheme,
  Box
} from '@mui/material';
import { Tooltip as MuiTooltip } from '@mui/material';
import InfoButton from '../InfoButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FavoriteIcon from '@mui/icons-material/Favorite';

const QAEntriesTable = ({ qaData }) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getFeedbackIcon = (feedback) => {
    switch (feedback) {
      case 'thumbs_up':
        return <ThumbUpIcon color='success' />;
      case 'thumbs_down':
        return <ThumbDownIcon color="error" />;
      case 'heart':
        return <FavoriteIcon color="secondary" />;
      default:
        return null;
    }
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" color="text.primary">
            Question & Answer Entries
          </Typography>
          <InfoButton title="Detailed log of all Q&A interactions, including timestamps, feedback, and response times" />
        </Box>
        <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '15%', minWidth: 120 }}>Time & Date</TableCell>
                <TableCell style={{ width: '25%', minWidth: 150 }}>Question</TableCell>
                <TableCell style={{ width: '30%', minWidth: 200 }}>Answer</TableCell>
                <TableCell style={{ width: '10%', minWidth: 100 }}>Feedback</TableCell>
                <TableCell style={{ width: '10%', minWidth: 80 }}>Follow-up</TableCell>
                <TableCell style={{ width: '10%', minWidth: 80 }}>Response Time (s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {qaData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((qa, index) => (
                  <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover } }}>
                    <TableCell>{qa.timestamp}</TableCell>
                    <TableCell>
                      <MuiTooltip title={qa.question}>
                        <Typography
                          sx={{
                            maxWidth: 150,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {qa.question}
                        </Typography>
                      </MuiTooltip>
                    </TableCell>
                    <TableCell>
                      <MuiTooltip title={qa.answer}>
                        <Typography
                          sx={{
                            maxWidth: 200,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {qa.answer}
                        </Typography>
                      </MuiTooltip>
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={getFeedbackIcon(qa.feedback)}
                        label={qa.feedback || 'No feedback'}
                        color={qa.feedback === 'thumbs_up' ? 'success' : qa.feedback === 'thumbs_down' ? 'error' : 'default'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={qa.is_follow_up ? 'Yes' : 'No'}
                        color={qa.is_follow_up ? 'primary' : 'default'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {qa.responseTime !== null && qa.responseTime !== undefined
                        ? Number(qa.responseTime).toFixed(2)
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={qaData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
};

export default QAEntriesTable;

import axios from 'axios';

export const API_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const getToken = () => localStorage.getItem('token');


api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }
        const response = await axios.post(`${API_URL}/refresh-token`, { refresh_token: refreshToken });
        const { access_token } = response.data;
        localStorage.setItem('token', access_token);
        api.defaults.headers['Authorization'] = `Bearer ${access_token}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const login = async (username, password) => {
  try {
    const response = await api.post('/token', `username=${username}&password=${password}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    localStorage.setItem('token', response.data.access_token);
    localStorage.setItem('refreshToken', response.data.refresh_token);
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const getSettings = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.log('No token found, skipping settings fetch');
    return null;
  }

  try {
    const response = await api.get('/api/settings');
    return response.data;
  } catch (error) {
    console.error('Error fetching settings:', error);
    console.error('Error details:', error.response ? error.response.data : 'No response data');
    if (error.response && error.response.status === 401) {
      console.log('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.href = '/login';
    }
    return null;
  }
};

export const getUserInfo = async () => {
  try {
    const response = await api.get('/api/user-info');
    return response.data;
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
};


export const scrapeAndCreateDb = async (params) => {
  try {
    const response = await api.post('/api/scrape-and-create-db', null, { params });
    return response.data;
  } catch (error) {
    console.error('Error scraping and creating database:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      if (error.response.status === 422) {
        throw new Error('Invalid parameters: ' + JSON.stringify(error.response.data.detail));
      }
      throw error.response.data;
    } else if (error.request) {
      console.error('Error request:', error.request);
      throw new Error('No response received from server');
    } else {
      console.error('Error message:', error.message);
      throw new Error('Error setting up the request');
    }
  }
};



export const submitFeedback = async (feedbackData) => {
  try {
    const response = await api.post('/feedback', feedbackData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting feedback:', error);
    throw error;
  }
};


export const getDatabases = async () => {
  try {
    const response = await api.get('/api/databases');
    return response.data;
  } catch (error) {
    console.error('Error fetching databases:', error);
    throw error;
  }
};


export const changeActiveDatabase = async (databases) => {
  try {
    const response = await api.post('/api/change-active-databases', { databases });
    return response.data;
  } catch (error) {
    console.error('Error changing active databases:', error);
    throw error;
  }
};


export const processDocuments = async (formData) => {
  try {
    const response = await api.post('/api/process-documents', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error processing documents:', error);
    throw error.response?.data || error;
  }
};


export const getAdminEmail = async () => {
  try {
    const response = await api.get('/api/admin-email');
    return response.data.email;
  } catch (error) {
    console.error('Error fetching admin email:', error);
    throw error;
  }
};


export const testPostRequest = async () => {
  try {
    const response = await api.post('/api/test');
    return response.data;
  } catch (error) {
    console.error('Error making test POST request:', error);
    throw error;
  }
};


export const testUpload = async () => {
  try {
    const response = await api.post('/api/upload-media', {});  // Changed from '/api/media/upload'
    return response.data;
  } catch (error) {
    throw error;
  }
};



// Wrap all API calls with this function
const apiCall = async (method, url, data = null) => {
  try {
    const response = await method(url, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error('Your session has expired. Please log in again.');
    }
    throw error;
  }
};


export const updateSettings = async (settings) => {
  try {
    const response = await api.post('/api/settings', settings);
    return response.data;
  } catch (error) {
    console.error('Error updating settings:', error.response?.data || error.message);
    throw error.response?.data || error;
  }
};



export const recordCTAInteraction = async (data) => {
  try {
    const response = await api.post('/api/record-cta-interaction', data);
    
    return response.data;
  } catch (error) {
    console.error('Error recording CTA interaction:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      if (error.response.status === 404) {
        console.warn('No matching interaction found. A new one might have been created.');
      }
    }
    throw error;
  }
};





// In your API service file (e.g., api.js)
export const getMediaAnalytics = async (startDate, endDate, mediaId = null) => {
  const params = new URLSearchParams({ start_date: startDate, end_date: endDate });
  if (mediaId) params.append('media_id', mediaId);
  const response = await fetch(`/api/media-analytics?${params}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  if (!response.ok) throw new Error('Failed to fetch media analytics');
  return await response.json();
};


export const getCTAAnalytics = async (startDate, endDate, buttonId = null) => {
  try {
    const formatDate = (date) => {
      return date.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    };

    const params = new URLSearchParams({ 
      start_date: formatDate(new Date(startDate)), 
      end_date: formatDate(new Date(endDate))
    });
    if (buttonId) params.append('button_id', buttonId);
    
    const response = await api.get(`/api/cta-analytics?${params}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching CTA analytics:', error);
    throw error;
  }
};



export const getCustomPrompt = async () => {
  try {
    const response = await api.get('/api/custom-prompt');
    return response.data;
  } catch (error) {
    console.error('Error fetching custom prompt:', error);
    throw error;
  }
};

export const updateCustomPrompt = async (customPrompt) => {
  try {
    const response = await api.post('/api/custom-prompt', { customPrompt });
    return response.data;
  } catch (error) {
    console.error('Error updating custom prompt:', error.response?.data || error.message);
    throw error.response?.data || error;
  }
};

const getAdminOverview = async () => {
  try {
    const response = await api.get('/api/admin/overview');
    return response.data;
  } catch (error) {
    console.error('Error fetching admin overview:', error);
    throw error;
  }
};

export const updateUserProfile = async (profileData) => {
  try {
    const response = await api.put('/api/user-profile', profileData);
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};



export const getUserProfile = async () => {
  try {
    const response = await api.get('/api/user-profile');
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        console.error('User profile not found');
        return null;
      } else if (error.response.status === 500) {
        console.error('Server error:', error.response.data.detail);
        throw new Error('An unexpected error occurred. Please try again later.');
      }
    }
    console.error('Error fetching user profile:', error);
    throw error;
  }
};


export const uploadMedia = async (formData) => {
  try {
    const response = await api.post('/api/upload-media', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Upload response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error uploading media:', error);
    throw error;
  }
};

export const addMediaUrl = async (mediaData) => {
  try {
    console.log('Sending media data to server:', mediaData);
    const response = await api.post('/api/upload-media/add-url', mediaData);
    console.log('Server response:', response.data);
    console.log('Returned item:', response.data.item);
    return response.data.item;
  } catch (error) {
    console.error('Error adding media URL:', error);
    if (error.response) {
      console.error('Error response from server:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export const getAllMedia = async () => {
  try {
    
    const response = await api.get('/api/media');
    
   

    // Add some validation and logging
    if (Array.isArray(response.data)) {
      
      if (response.data.length > 0) {
        
      }
    } else {
      console.warn('Response data is not an array:', response.data);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching media:', error.response || error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
    }
    throw error;
  }
};



export const deleteMedia = async (mediaId) => {
  try {
    const response = await api.delete(`/api/upload-media/${mediaId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting media:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      throw new Error(`Server error: ${error.response.data.detail || error.response.statusText}`);
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from server. Please check your network connection.');
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(`Error: ${error.message}`);
    }
  }
};


export const updateKeywords = async (mediaId, keywords) => {
  try {
    if (!mediaId) {
      throw new Error('Media ID is required');
    }
    // Ensure keywords is an array
    const keywordsArray = Array.isArray(keywords) ? keywords : [keywords];
    const response = await api.put(`/api/upload-media/${mediaId}/keywords`, { keywords: keywordsArray });
    return response.data;
  } catch (error) {
    console.error('Error updating keywords:', error.response?.data || error.message);
    throw error;
  }
};


export const updateMediaExclusive = async (mediaId, exclusive) => {
  try {
    console.log(`Updating media ${mediaId} with exclusive status: ${exclusive}`);
    const response = await api.put(`/api/upload-media/${mediaId}/exclusive`, { exclusive });
    console.log('Update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating media exclusive flag:', error.response?.data || error.message);
    throw error;
  }
};


export const updateMediaItem = async (mediaId, mediaData) => {
  try {
    const response = await api.put(`/api/upload-media/${mediaId}`, mediaData);
    return response.data;
  } catch (error) {
    console.error('Error updating media item:', error);
    throw error;
  }
};




export const getCTAButtons = async () => {
  try {
    const response = await api.get('/api/cta-buttons');
   
    if (!Array.isArray(response.data)) {
      console.warn('CTA buttons data is not an array:', response.data);
      return [];
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching CTA buttons:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
    }
    throw error;
  }
};

export const createCTAButton = async (buttonData) => {
  try {
    console.log('Sending CTA button data to server:', JSON.stringify(buttonData, null, 2));
    const response = await api.post('/api/cta-buttons', buttonData);
    console.log('Server response:', JSON.stringify(response.data, null, 2));
    return response.data.button;
  } catch (error) {
    console.error('Error creating CTA button:', error.response?.data || error.message);
    throw error;
  }
};

export const updateCTAButton = async (buttonId, buttonData) => {
  try {
    console.log('Updating CTA button with ID:', buttonId);
    console.log('Update data:', JSON.stringify(buttonData, null, 2));
    
    const response = await api.put(`/api/cta-buttons/${buttonId}`, buttonData);
    
    console.log('Update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating CTA button:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Error request:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

// In api.js
export const deleteCTAButton = async (buttonId) => {
  try {
    console.log('Sending delete request for button ID:', buttonId);
    const response = await api.delete(`/api/cta-buttons/${buttonId}`);
    console.log('Server response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in deleteCTAButton:', error.response?.data || error.message);
    throw error;
  }
};






export const getUsers = () => apiCall(api.get, '/api/users');
export const createUser = (userData) => apiCall(api.post, '/api/users', userData);
export const updateUser = (userId, userData) => apiCall(api.put, `/api/users/${userId}`, userData);
export const deleteUser = (userId) => apiCall(api.delete, `/api/users/${userId}`);
export const changePassword = (changePasswordData) => apiCall(api.post, '/api/users/change-password', changePasswordData);
export const resetPassword = (userId, newPassword) => apiCall(api.post, `/api/users/${userId}/reset-password`, { new_password: newPassword });
export const askQuestion = async (questionData) => {
  try {
    const response = await api.post('/ask', questionData);
    return response.data;
  } catch (error) {
    console.error('Error in askQuestion:', error);
    throw error;
  }
};

export const getAnalytics = (params) => apiCall(api.get, '/analytics', { params });

export { 
  // ... other exports
  getAdminOverview,
  
  // ... other exports
};

export default api;
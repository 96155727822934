import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  
  Avatar,
  LinearProgress,
  Card,
  CardContent,
  Grid,
  Tooltip,
  useTheme
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FavoriteIcon from '@mui/icons-material/Favorite';
import InfoButton from '../InfoButton'; // Make sure this path is correct

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const TopMediaList = ({ mediaData }) => {
  const theme = useTheme();
  const [maxUsage, setMaxUsage] = useState(0);
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    const max = Math.max(...mediaData.map(item => item.total_usage));
    setMaxUsage(max);
  }, [mediaData]);

  const getYouTubeUrl = (item) => {
    if (item.url && (item.url.includes('youtube.com') || item.url.includes('youtu.be'))) {
      return item.url;
    }
    if (item.media_details && item.media_details.url && 
        (item.media_details.url.includes('youtube.com') || item.media_details.url.includes('youtu.be'))) {
      return item.media_details.url;
    }
    if (item.youtube_url) {
      return item.youtube_url;
    }
    if (item.media_details && item.media_details.youtube_url) {
      return item.media_details.youtube_url;
    }
    return null;
  };

  const getYouTubeVideoId = (url) => {
  if (!url) return null;
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

  const getMediaUrl = (item) => {
    const youtubeUrl = getYouTubeUrl(item);
    if (youtubeUrl) return youtubeUrl;
    if (item.url) return item.url;
    if (item.filename) return `${API_URL}/api/media/${item.filename}`;
    if (item.media_details && item.media_details.filename) return `${API_URL}/api/media/${item.media_details.filename}`;
    return null;
  };

  const getMediaType = (item) => {
    if (getYouTubeUrl(item)) return 'youtube';
    if (item.type) return item.type;
    if (!item.filename && !item.media_details?.filename) return 'Other';
    const filename = item.filename || item.media_details?.filename;
    const fileExtension = filename.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(fileExtension)) return 'image';
    if (['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm'].includes(fileExtension)) return 'video';
    if (['mp3', 'wav', 'ogg', 'aac', 'flac'].includes(fileExtension)) return 'audio';
    if (['pdf'].includes(fileExtension)) return 'pdf';
    if (['doc', 'docx', 'txt', 'rtf', 'xls', 'xlsx', 'ppt', 'pptx'].includes(fileExtension)) return 'document';
    return 'Other';
  };

  const getMediaIcon = (type) => {
    switch (type) {
      case 'image': return <ImageIcon />;
      case 'video': return <VideocamIcon />;
      case 'audio': return <AudiotrackIcon />;
      case 'pdf':
      case 'document': return <DescriptionIcon />;
      case 'youtube': return <YouTubeIcon />;
      default: return <InsertDriveFileIcon />;
    }
  };

  const renderPreview = (item) => {
    const mediaUrl = getMediaUrl(item);
    const mediaType = getMediaType(item);
    

    const previewStyle = {
      width: '100%',
      height: '120px',
      objectFit: 'cover',
      borderRadius: theme.shape.borderRadius,
    };

    switch (mediaType) {
      case 'image':
        return <img src={mediaUrl} alt={item.original_filename} style={previewStyle} />;
      case 'video':
        return <video src={mediaUrl} controls style={previewStyle} />;
      case 'audio':
        return <audio src={mediaUrl} controls style={{ width: '100%' }} />;
      case 'pdf':
        return (
          <iframe
            src={`${mediaUrl}#view=FitH`}
            title={item.original_filename}
            style={previewStyle}
          />
        );
      case 'youtube':
        const videoId = getYouTubeVideoId(mediaUrl);
        
        if (videoId) {
          return (
            <iframe
              width="100%"
              height="120"
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={previewStyle}
            ></iframe>
          );
        } else {
          console.error('Invalid YouTube URL:', mediaUrl);
          return (
            <Box sx={{ ...previewStyle, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: theme.palette.error.light }}>
              <Typography variant="caption" color="error">Invalid YouTube URL</Typography>
            </Box>
          );
        }
      default:
        return (
          <Box sx={{ ...previewStyle, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: theme.palette.grey[200] }}>
            {getMediaIcon(mediaType)}
          </Box>
        );
    }
  };

  const renderFeedback = (feedback) => {
    const total = feedback.thumbs_up + feedback.thumbs_down + feedback.heart;
    const getPercentage = (value) => ((value / total) * 100).toFixed(1);

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
        <Tooltip title={`${feedback.thumbs_up} likes (${getPercentage(feedback.thumbs_up)}%)`}>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <ThumbUpIcon color="primary" fontSize="small" />
            <Typography variant="body2" sx={{ ml: 0.5 }}>{feedback.thumbs_up}</Typography>
          </Box>
        </Tooltip>
        <Tooltip title={`${feedback.thumbs_down} dislikes (${getPercentage(feedback.thumbs_down)}%)`}>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <ThumbDownIcon color="error" fontSize="small" />
            <Typography variant="body2" sx={{ ml: 0.5 }}>{feedback.thumbs_down}</Typography>
          </Box>
        </Tooltip>
        <Tooltip title={`${feedback.heart} hearts (${getPercentage(feedback.heart)}%)`}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FavoriteIcon color="secondary" fontSize="small" />
            <Typography variant="body2" sx={{ ml: 0.5 }}>{feedback.heart}</Typography>
          </Box>
        </Tooltip>
      </Box>
    );
  };

  const sortedMedia = [...mediaData].sort((a, b) => b.total_usage - a.total_usage).slice(0, 10);

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" gutterBottom color="text.primary" sx={{ flexGrow: 1 }}>
            Top 10 Most Used Media
          </Typography>
          <InfoButton title="Displays the most frequently used media items, including YouTube videos, showing usage statistics and user feedback" />
        </Box>
       <List>
          {sortedMedia.map((media, index) => {
            const filename = media.media_details?.original_filename || media.original_filename || media.custom_filename || media.media_details?.filename || 'Unnamed Media';
            const percentUsage = (media.total_usage / maxUsage) * 100;

            return (
              <ListItem
                key={media.id || media.media_id}
                sx={{
                  mb: 4,
                  transition: 'all 0.3s',
                  '&:hover': { backgroundColor: theme.palette.action.hover },
                  borderRadius: theme.shape.borderRadius,
                }}
                onMouseEnter={() => setHoveredItem(media.id || media.media_id)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={3} md={3}>
                    <Box sx={{ position: 'relative' }}>
                      {renderPreview(media)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <Box>
                      <Tooltip title={filename} placement="top">
                        <Typography variant="subtitle1" sx={{ flexGrow: 1, mr: 1 }} noWrap component="div">
                          {filename}
                        </Typography>
                      </Tooltip>
                      <Typography variant="body2" color="text.secondary" component="div" sx={{ mb: 0.5 }}>
                        Used {media.total_usage} times
                      </Typography>
                      <Tooltip title={`${percentUsage.toFixed(1)}% of top usage`} placement="right">
                        <LinearProgress
                          variant="determinate"
                          value={percentUsage}
                          sx={{
                            height: 8,
                            borderRadius: 5,
                            backgroundColor: theme.palette.grey[200],
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: theme.palette.primary.main,
                            },
                          }}
                        />
                      </Tooltip>
                      <Box sx={{ mt: 1 }}>{renderFeedback(media.feedback)}</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={1} md={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        width: 50,
                        height: 50,
                        fontSize: '1.4rem',
                        color: 'white',
                      }}
                    >
                      {index + 1}
                    </Avatar>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

export default TopMediaList;
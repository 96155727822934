import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Container, 
  Grid, 
  Typography, 
  ThemeProvider, 
  createTheme,
  CircularProgress, 
  useMediaQuery,
} from '@mui/material';

import { format, subDays, parseISO, isValid } from 'date-fns';

import SectionHeading from './SectionHeading';
import MetricCard from './charts/MetricCard';
import StickyHeader from './StickyHeader';
import { formatChartDate } from './utils/formatChartDate';
import { generateWordCloudData } from './utils/wordCloudUtils';
import TouchAppIcon from '@mui/icons-material/TouchApp';

import DailyQuestionsSessionsChart from './charts/DailyQuestionsSessionsChart';
import BusiestTimesChart from './charts/BusiestTimesChart';
import UserFeedbackAnalysisChart from './charts/UserFeedbackAnalysisChart';
import FollowUpQuestionsChart from './charts/FollowUpQuestionsChart';
import ResponseTimeAnalysisChart from './charts/ResponseTimeAnalysisChart';
import AverageResponseTimeTrendChart from './charts/AverageResponseTimeTrendChart';
import TopicAnalysisGrid from './charts/TopicAnalysisGrid';
import QuestionComplexityAnalysis from './charts/QuestionComplexityAnalysis';
import UserEngagementFunnel from './charts/UserEngagementFunnel';
import QuestionComplexityResponseTimeChart from './charts/QuestionComplexityResponseTimeChart';
import QuestionComplexityDistributionChart from './charts/QuestionComplexityDistributionChart';
import ResponseTimeByHourChart from './charts/ResponseTimeByHourChart';
import SourceDistributionChart from './charts/SourceDistributionChart';
import WordCloudWithTooltips from './charts/WordCloudWithTooltips';
import TopWordsChart from './charts/TopWordsChart';
import QAEntriesTable from './charts/QAEntriesTable';
import MediaAnalyticsSection from './MediaAnalyticsSection';
import CTAButtonAnalyticsDashboard from './charts/CTAButtonAnalyticsDashboard';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SpeedIcon from '@mui/icons-material/Speed';
import ImageIcon from '@mui/icons-material/Image';
import DashboardIcon from '@mui/icons-material/Dashboard';

const AnalyticsDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [mediaData, setMediaData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [darkMode, setDarkMode] = useState(false);
  const [allQAData, setAllQAData] = useState([]);
  const [activePreset, setActivePreset] = useState(0);

  const theme = useMemo(() => createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      background: {
        default: darkMode ? '#1c2331' : '#f4f6f8',
        paper: darkMode ? '#2c3e50' : '#ffffff',
      },
      text: {
        primary: darkMode ? '#ffffff' : '#2c3e50',
      },
    },
    typography: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 600,
      },
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '16px',
            boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 30px 0 rgba(0,0,0,0.16)',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 600,
            color: darkMode ? '#ffffff' : '#2c3e50',
            backgroundColor: darkMode ? '#3f51b5' : '#ffffff',
            '&:hover': {
              backgroundColor: darkMode ? '#303f9f' : '#f5f5f5',
            },
            '&:focus-visible': {
              outline: '2px solid #000',
              outlineOffset: '2px',
            },
          },
        },
      },
    },
  }), [darkMode]);

  const calculateTimeOfDayData = (qaData) => {
    const timeOfDayData = {};
    qaData.forEach(interaction => {
      const [, timePart] = interaction.timestamp.split(', ');
      const [hour] = timePart.split(':');
      const parsedHour = parseInt(hour, 10);
      if (!isNaN(parsedHour)) {
        timeOfDayData[parsedHour] = (timeOfDayData[parsedHour] || 0) + 1;
      }
    });
    return timeOfDayData;
  };

  const calculateUserEngagementFunnel = (qaData) => {
    const totalInteractions = qaData.length;
    const followUpQuestions = qaData.filter(qa => qa.is_follow_up === true).length;
    const interactionsWithFeedback = qaData.filter(qa => qa.feedback && qa.feedback !== 'no_feedback').length;
    const interactionsWithPositiveFeedback = qaData.filter(qa => ['heart', 'thumbs_up'].includes(qa.feedback)).length;

    return [
      { stage: 'Asked Question', value: totalInteractions },
      { stage: 'Asked Follow-up Question', value: followUpQuestions },
      { stage: 'Gave Feedback', value: interactionsWithFeedback },
      { stage: 'Gave Positive Feedback', value: interactionsWithPositiveFeedback },
    ];
  };
  
  const calculateResponseTimeData = (qaData) => {
  const buckets = {
    '0-1s': 0, '1-2s': 0, '2-3s': 0, '3-5s': 0,
    '5-10s': 0, '10-20s': 0, '20s+': 0
  };
  qaData.forEach(qa => {
    const responseTime = qa.responseTime || 0;
    if (responseTime <= 1) buckets['0-1s']++;
    else if (responseTime <= 2) buckets['1-2s']++;
    else if (responseTime <= 3) buckets['2-3s']++;
    else if (responseTime <= 5) buckets['3-5s']++;
    else if (responseTime <= 10) buckets['5-10s']++;
    else if (responseTime <= 20) buckets['10-20s']++;
    else buckets['20s+']++;
  });
  return Object.entries(buckets).map(([range, count]) => ({ range, count }));
};




  const calculateQuestionComplexityDistribution = (qaData) => {
    const complexityBuckets = {
      '1-5 words': { count: 0, totalResponseTime: 0 },
      '6-10 words': { count: 0, totalResponseTime: 0 },
      '11-20 words': { count: 0, totalResponseTime: 0 },
      '21-30 words': { count: 0, totalResponseTime: 0 },
      '31+ words': { count: 0, totalResponseTime: 0 },
    };

    qaData.forEach(qa => {
      const wordCount = qa.question.split(/\s+/).length;
      let bucket;
      if (wordCount <= 5) bucket = '1-5 words';
      else if (wordCount <= 10) bucket = '6-10 words';
      else if (wordCount <= 20) bucket = '11-20 words';
      else if (wordCount <= 30) bucket = '21-30 words';
      else bucket = '31+ words';

      complexityBuckets[bucket].count++;
      complexityBuckets[bucket].totalResponseTime += qa.responseTime || 0;
    });

    return Object.entries(complexityBuckets).map(([complexity, data]) => ({
      complexity,
      count: data.count,
      avgResponseTime: data.count > 0 ? data.totalResponseTime / data.count : 0,
    }));
  };

  const calculateQuestionComplexityResponseTime = (qaData) => {
  const complexityMap = {};
  qaData.forEach(qa => {
    const complexity = qa.question.split(/\s+/).length;
    const key = `${complexity}-${qa.responseTime.toFixed(1)}`;
    if (complexityMap[key]) {
      complexityMap[key].frequency += 1;
    } else {
      complexityMap[key] = {
        complexity,
        responseTime: qa.responseTime || 0,
        question: qa.question,
        frequency: 1
      };
    }
  });
  return Object.values(complexityMap);
};

  const calculateAverageResponseTimeTrend = (qaData) => {
    const trendData = qaData.reduce((acc, qa) => {
      const date = qa.timestamp.split(', ')[0];
      if (!acc[date]) {
        acc[date] = { date, totalTime: 0, count: 0 };
      }
      acc[date].totalTime += qa.responseTime || 0;
      acc[date].count += 1;
      return acc;
    }, {});

    return Object.values(trendData)
      .map(({ date, totalTime, count }) => ({
        date,
        averageResponseTime: totalTime / count
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const processedData = useMemo(() => {
  console.log('allQAData:', allQAData);
  const data = {
    timeOfDayData: calculateTimeOfDayData(allQAData),
    userEngagementFunnel: calculateUserEngagementFunnel(allQAData),
    questionComplexityDistribution: calculateQuestionComplexityDistribution(allQAData),
    questionComplexityResponseTime: calculateQuestionComplexityResponseTime(allQAData),
    averageResponseTimeTrend: calculateAverageResponseTimeTrend(allQAData),
    responseTimeData: calculateResponseTimeData(allQAData),
  };
  console.log('processedData:', data);
  return data;
}, [allQAData]);

  const formatTimestamp = useCallback((timestamp) => {
    if (!timestamp) return 'N/A';
    try {
      const date = parseISO(timestamp);
      if (!isValid(date)) {
        console.error('Invalid date:', timestamp);
        return 'Invalid Date';
      }
      return format(date, 'dd/MM/yyyy, HH:mm:ss');
    } catch (error) {
      console.error('Error formatting timestamp:', error);
      return 'Error';
    }
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found. Please log in again.');
      }
      const params = new URLSearchParams({
        start_date: format(startDate, 'yyyy-MM-dd'),
        end_date: format(endDate, 'yyyy-MM-dd')
      });

      const [analyticsResponse, mediaAnalyticsResponse] = await Promise.all([
        fetch(`http://localhost:8000/analytics?${params}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        }),
        fetch(`http://localhost:8000/api/media-analytics?${params}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        })
      ]);

      if (!analyticsResponse.ok || !mediaAnalyticsResponse.ok) {
        if (analyticsResponse.status === 401 || mediaAnalyticsResponse.status === 401) {
          localStorage.removeItem('token');
          throw new Error('Your session has expired. Please log in again.');
        }
        throw new Error(`HTTP error! status: ${analyticsResponse.status}, ${mediaAnalyticsResponse.status}`);
      }

      const result = await analyticsResponse.json();
      const mediaResult = await mediaAnalyticsResponse.json();

      const processedData = {
        ...result,
        dailyData: result.dailyData.map(item => ({
          ...item,
          date: item.date,
          formattedDate: formatChartDate(item.date)
        })),
        qaData: result.qaData.map(item => ({
          ...item,
          timestamp: formatTimestamp(item.timestamp)
        }))
      };

      const questions = processedData.qaData.map(item => item.question);
      const wordCloudData = generateWordCloudData(questions);
      processedData.wordCloudData = wordCloudData;

      setData(processedData);
      setMediaData(mediaResult);
      setAllQAData(processedData.qaData);
    } catch (e) {
      console.error("Fetching data failed:", e);
      setError(e.message);
      if (e.message.includes('Please log in again')) {
        setTimeout(() => navigate('/login'), 3000);
      }
    } finally {
      setIsLoading(false);
    }
  }, [startDate, endDate, navigate, formatTimestamp]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareExportData = useCallback(() => {
    return {
      ...data,
      ...processedData
    };
  }, [data, processedData]);

  const exportAllDataToCSV = (preparedData, responseTimeData, topWords, allQAData, mediaData) => {
    try {
      let csvContent = "Data Export\n\n";

      // Metrics
      csvContent += "Metrics\nName,Value\n";
      ['totalSessions', 'totalInteractions', 'avgInteractionsPerSession', 'avgFollowUpsPerSession'].forEach(metric => {
        if (preparedData[metric] !== undefined) {
          csvContent += `${metric},${preparedData[metric]}\n`;
        }
      });
      csvContent += '\n';

      // Daily Data
      if (preparedData.dailyData) {
        csvContent += "Daily Data\nDate,Interactions,Sessions\n";
        preparedData.dailyData.forEach(({ date, interactions, sessions }) => {
          csvContent += `${date},${interactions},${sessions}\n`;
        });
        csvContent += '\n';
      }

      // Feedback Data
      if (preparedData.totalFeedback) {
        csvContent += "Feedback Data\nType,Count\n";
        Object.entries(preparedData.totalFeedback).forEach(([name, value]) => {
          csvContent += `${name},${value}\n`;
        });
        csvContent += '\n';
      }

      // Source Data
      if (preparedData.totalSources) {
        csvContent += "Source Data\nSource,Count\n";
        Object.entries(preparedData.totalSources).forEach(([name, value]) => {
          csvContent += `${name},${value}\n`;
        });
        csvContent += '\n';
      }

      // Busiest Times of Day
      if (preparedData.timeOfDayData) {
        csvContent += "Busiest Times of Day\nHour,Value\n";
        Object.entries(preparedData.timeOfDayData).forEach(([time, value]) => {
          csvContent += `${time},${value}\n`;
        });
        csvContent += '\n';
      }

      // Response Time Distribution
      if (responseTimeData) {
        csvContent += "Response Time Distribution\nRange,Count\n";
        
        responseTimeData.forEach(({ range, count }) => {
          csvContent += `${range},${count}\n`;
        });
        csvContent += '\n';
      }

      // Top Words
      if (topWords) {
        csvContent += "Top Words\nWord,Count\n";
        topWords.forEach(({ word, count }) => {
          csvContent += `${word},${count}\n`;
        });
        csvContent += '\n';
      }

      // Q&A Data
      if (allQAData) {
        csvContent += "Q&A Data\nTimestamp,Question,Answer,Feedback,Is Follow-up,Response Time\n";
        allQAData.forEach((qa) => {
          const timestamp = qa.timestamp || '';
          const question = (qa.question || '').replace(/"/g, '""');
          const answer = (qa.answer || '').replace(/"/g, '""').replace(/\n/g, ' ');
          const feedback = qa.feedback || '';
          const isFollowUp = qa.is_follow_up || false;
          const responseTime = qa.responseTime || '';

          csvContent += `"${timestamp}","${question}","${answer}","${feedback}",${isFollowUp},${responseTime}\n`;
        });
        csvContent += '\n';
      }

      // User Engagement Funnel
      if (preparedData.userEngagementFunnel) {
        csvContent += "User Engagement Funnel\nStage,Value\n";
        preparedData.userEngagementFunnel.forEach(({ stage, value }) => {
          csvContent += `${stage},${value}\n`;
        });
        csvContent += '\n';
      }

      // Question Complexity Analysis
      if (preparedData.questionComplexityDistribution) {
        csvContent += "Question Complexity Analysis\nComplexity,Count,Avg Response Time\n";
        preparedData.questionComplexityDistribution.forEach(({ complexity, count, avgResponseTime }) => {
          csvContent += `${complexity},${count},${avgResponseTime}\n`;
        });
        csvContent += '\n';
      }

      // Question Complexity Response Time
      if (preparedData.questionComplexityResponseTime) {
        csvContent += "Question Complexity Response Time\nComplexity,Response Time,Question\n";
        preparedData.questionComplexityResponseTime.forEach(({ complexity, responseTime, question }) => {
          csvContent += `${complexity},${responseTime},"${question.replace(/"/g, '""')}"\n`;
        });
        csvContent += '\n';
      }

      // Average Response Time Trend
      if (preparedData.averageResponseTimeTrend) {
        csvContent += "Average Response Time Trend\nDate,Average Response Time\n";
        preparedData.averageResponseTimeTrend.forEach(({ date, averageResponseTime }) => {
          csvContent += `${date},${averageResponseTime}\n`;
        });
        csvContent += '\n';
      }

      // Media Analytics
      if (mediaData) {
        csvContent += "Media Analytics\nType,Count\n";
        Object.entries(mediaData).forEach(([type, count]) => {
          if (typeof count === 'object') {
            csvContent += `${type},${JSON.stringify(count)}\n`;
          } else {
            csvContent += `${type},${count}\n`;
          }
        });
      }

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'all_dashboard_data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleExport = useCallback(() => {
    const preparedData = prepareExportData();
    exportAllDataToCSV(preparedData, processedData.responseTimeData, processedData.topWords, allQAData, mediaData);
  }, [prepareExportData, processedData, allQAData, mediaData]);

  const handleDateRangeChange = useCallback((start, end, presetIndex = null) => {
    setStartDate(start);
    setEndDate(end);
    setActivePreset(currentPreset => currentPreset === presetIndex ? null : presetIndex);
  }, []);

  const handleKeyDown = useCallback((event, action) => {
    if (event.key === 'Enter' || event.key === ' ') {
      action();
      event.preventDefault();
    }
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (error) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" flexDirection="column" role="alert">
      <Typography color="error" gutterBottom>{error}</Typography>
      {error.includes('Please log in again') && (
        <Typography>Redirecting to login page...</Typography>
      )}
    </Box>
  );

  const { 
    totalSessions = 0, 
    totalInteractions = 0, 
    avgInteractionsPerSession = 0, 
    avgFollowUpsPerSession = 0, 
    dailyData = [], 
    totalFeedback = {}, 
    totalSources = {}, 
     
    qaData = [], 
    wordCloudData = []
  } = data || {};

  const sourceData = Object.entries(totalSources).map(([name, value]) => ({ name, value }));

  const gradients = [
    'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)',
    'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    'linear-gradient(45deg, #6B66FF 30%, #4641FF 90%)',
    'linear-gradient(45deg, #44b700 30%, #9eff00 90%)',
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box 
        sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}
        role="main"
        aria-label="Analytics Dashboard"
      >
        <StickyHeader 
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          isMobile={isMobile}
          startDate={startDate}
          endDate={endDate}
          handleDateRangeChange={handleDateRangeChange}
          fetchData={fetchData}
          exportAllDataToCSV={handleExport}
          handleKeyDown={handleKeyDown}
          theme={theme}
          activePreset={activePreset}
        />
        <Container maxWidth="lg" sx={{ mt: 2 }}>
          {data ? (
            <Grid container spacing={3}>
              {/* Chatbot Performance Overview Section */}
              <Grid item xs={12} id="overview">
                
                <Grid container spacing={3}>
                  {/* Metric Cards */}
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      {[
                        { title: "Total Sessions", value: totalSessions, gradient: gradients[0], isInteger: true },
                        { title: "Total Questions", value: totalInteractions, gradient: gradients[1], isInteger: true },
                        { title: "Avg Questions/Session", value: avgInteractionsPerSession, gradient: gradients[2], prefix: "~" },
                        { title: "Avg Follow-ups/Session", value: avgFollowUpsPerSession, gradient: gradients[3], prefix: "~" }
                      ].map((metric, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <MetricCard {...metric} isLoading={isLoading} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  {/* User Interaction Trends Section */}
                  <Grid item xs={12}>
                    <SectionHeading icon={DashboardIcon}>
                      Chatbot Activity Overview
                    </SectionHeading>
                    <DailyQuestionsSessionsChart dailyData={dailyData} isLoading={isLoading} />
                  </Grid>
                  
                  {/* Busiest Times of Day */}
                  <Grid item xs={12}>
                    <BusiestTimesChart interactionsData={allQAData} isLoading={isLoading} />
                  </Grid>
                  
                  {/* Question & Answer Entries */}
                  <Grid item xs={12}>
                    <QAEntriesTable qaData={qaData} />
                  </Grid>
                </Grid>
              </Grid>

              {/* User Satisfaction and Engagement Metrics */}
              <Grid item xs={12} id="user-satisfaction">
                <SectionHeading icon={SentimentSatisfiedAltIcon}>
                  User Satisfaction and Engagement Metrics
                </SectionHeading>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <UserFeedbackAnalysisChart totalFeedback={totalFeedback} isLoading={isLoading} />
                  </Grid>
                  <Grid item xs={12}>
                    <UserEngagementFunnel allQAData={allQAData} isLoading={isLoading} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SourceDistributionChart sourceData={sourceData} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FollowUpQuestionsChart followUpData={processedData.userEngagementFunnel} isLoading={isLoading} />
                  </Grid>
                </Grid>
              </Grid>

              {/* Response Time Performance Section */}
              <Grid item xs={12} id="response-time">
                <SectionHeading icon={SpeedIcon}>Response Time Performance</SectionHeading>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <ResponseTimeAnalysisChart responseTimeData={processedData.responseTimeData} isLoading={isLoading} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ResponseTimeByHourChart allQAData={allQAData} />
                  </Grid>
                  <Grid item xs={12}>
                    <AverageResponseTimeTrendChart allQAData={allQAData} isLoading={isLoading} />
                  </Grid>
                  <Grid item xs={12}>  
                    <QuestionComplexityAnalysis allQAData={allQAData} />
                  </Grid>
                  <Grid item xs={12}>
                    <QuestionComplexityResponseTimeChart 
                      processedComplexityData={processedData.questionComplexityResponseTime}
                      maxFrequency={Math.max(...processedData.questionComplexityResponseTime.map(item => item.frequency))}
                      isLoading={isLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Question Content and Topic Analysis Section */}
              <Grid item xs={12} id="content-analysis">
                <SectionHeading icon={QuestionAnswerIcon}>
                  Question Content and Topic Analysis
                </SectionHeading>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TopWordsChart allQAData={allQAData} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <QuestionComplexityDistributionChart allQAData={allQAData} isLoading={isLoading} />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ height: { xs: 400, sm: 500, md: 700 } }}>
                      {wordCloudData && wordCloudData.length > 0 && (
                        <WordCloudWithTooltips wordCloudData={wordCloudData} isLoading={isLoading} />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TopicAnalysisGrid allQAData={allQAData} />
                  </Grid>
                </Grid>
              </Grid>

              {/* CTA Button Analytics Section */}
              <Grid item xs={12} id="cta-analytics">
                <SectionHeading icon={TouchAppIcon}>
                  CTA Button Analytics
                </SectionHeading>
                <CTAButtonAnalyticsDashboard startDate={startDate} endDate={endDate} />
              </Grid>

              {/* Media Analytics Section */}
              <Grid item xs={12} id="media-analytics">
                <SectionHeading icon={ImageIcon}>Media Analytics</SectionHeading>
                <MediaAnalyticsSection mediaData={mediaData} />
              </Grid>
            </Grid>
          ) : (
            <Box 
              display="flex" 
              justifyContent="center" 
              alignItems="center" 
              height="50vh"
              role="status"
              aria-label="Loading dashboard data"
            >
              <CircularProgress />
            </Box>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default AnalyticsDashboard;
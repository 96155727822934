import React, { useState, useMemo, useCallback } from 'react';
import { 
  Card, CardContent, Typography, Grid, Box, useTheme,
} from '@mui/material';
import { 
  ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip as RechartsTooltip, Sector 
} from 'recharts';
import InfoButton from '../InfoButton';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const MediaTypeDistributionChart = ({ mediaData }) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);

  const COLORS = {
    PDF: '#FF6384',
    Word: '#36A2EB',
    Excel: '#FFCE56',
    PowerPoint: '#4BC0C0',
    Image: '#9966FF',
    Video: '#FF9F40',
    Audio: '#FF6384',
    Other: '#C9CBCF'
  };

  const getMediaIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'pdf': return <DescriptionIcon />;
      case 'word': return <DescriptionIcon />;
      case 'excel': return <DescriptionIcon />;
      case 'powerpoint': return <DescriptionIcon />;
      case 'image': return <ImageIcon />;
      case 'video': return <VideocamIcon />;
      case 'audio': return <AudiotrackIcon />;
      default: return <InsertDriveFileIcon />;
    }
  };

  const processedData = useMemo(() => {
    

    if (!mediaData || !Array.isArray(mediaData) || mediaData.length === 0) {
      
      return { chartData: [], tableData: [] };
    }

    const typeCount = {};
    let totalUsage = 0;
    
    const tableData = mediaData.map((media, index) => {
      

      let fileType = 'Other';
      if (media && typeof media === 'object') {
        if (media.media_details && typeof media.media_details === 'object') {
          fileType = media.media_details.file_type || media.media_details.type || fileType;
        } else if (typeof media.type === 'string') {
          fileType = media.type;
        }
      }

      typeCount[fileType] = (typeCount[fileType] || 0) + 1;
      totalUsage += media.total_usage || 0;

      return {
        id: media.media_id || `unknown-${index}`,
        filename: (media.media_details && media.media_details.original_filename) || 'Unnamed Media',
        fileType,
        totalUsage: media.total_usage || 0,
        feedback: media.feedback || { thumbs_up: 0, thumbs_down: 0, heart: 0 }
      };
    });

    const chartData = Object.entries(typeCount)
      .map(([name, value]) => ({ name, value }))
      .filter(item => item.value > 0);

    
    return { chartData, tableData, totalUsage };
  }, [mediaData]);

  const onPieEnter = useCallback((_, index) => {
    setActiveIndex(index);
  }, [setActiveIndex]);

  const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={theme.palette.text.primary}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={theme.palette.text.primary}>
          {`${value} (${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  if (processedData.chartData.length === 0) {
    return <div>No media type distribution data available.</div>;
  }

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" gutterBottom color="text.primary">
            Media Type Distribution
          </Typography>
          <InfoButton title="Distribution of media types based on file_type from the database" />
        </Box>
        {processedData.chartData.length > 0 ? (
          <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={processedData.chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={80}
                    outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                  fillOpacity={0.6}
              strokeWidth={0} 
                >
                  {processedData.chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[entry.name] || COLORS.Other} />
                  ))}
                </Pie>
                <RechartsTooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Type</th>
                    <th style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ddd' }}>Count</th>
                    <th style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ddd' }}>Usage %</th>
                  </tr>
                </thead>
                <tbody>
                  {processedData.chartData.map((item) => (
                    <tr key={item.name}>
                      <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {getMediaIcon(item.name)}
                          <Typography variant="body2" sx={{ ml: 1 }}>{item.name}</Typography>
                        </Box>
                      </td>
                      <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ddd' }}>{item.value}</td>
                      <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ddd' }}>
                        {((item.value / processedData.chartData.reduce((sum, d) => sum + d.value, 0)) * 100).toFixed(2)}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Grid>
         </Grid>
        ) : (
          <Typography>No media type distribution data available for the selected date range.</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MediaTypeDistributionChart;
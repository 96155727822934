import React, { useState } from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  useScrollTrigger,
  Slide,
  Drawer,
  useMediaQuery,
  useTheme,
  Button,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
} from '@mui/material';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SpeedIcon from '@mui/icons-material/Speed';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ImageIcon from '@mui/icons-material/Image';
import { styled } from '@mui/material/styles';
import DatePickerSection from './DatePickerSection';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.05)',
  borderRadius: '12px',
  padding: theme.spacing(1.5),
  margin: theme.spacing(1, 3),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-2px)',
    boxShadow: theme.palette.mode === 'dark' ? '0 4px 8px rgba(255, 255, 255, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
}));

const DrawerIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.05)',
  borderRadius: '12px',
  padding: theme.spacing(1.5),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-2px)',
    boxShadow: theme.palette.mode === 'dark' 
      ? '0 4px 8px rgba(255, 255, 255, 0.2), 0 0 0 2px rgba(255, 255, 255, 0.1)' 
      : '0 4px 8px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: theme.spacing(1.5),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.palette.mode === 'dark' ? '0 4px 8px rgba(255, 255, 255, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
}));

const LargerTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '2.125rem',
  fontWeight: 400,
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const navItems = [
  { icon: DashboardIcon, label: 'Overview', href: '#overview' },
  { icon: SentimentSatisfiedAltIcon, label: 'User Satisfaction', href: '#user-satisfaction' },
  { icon: SpeedIcon, label: 'Response Time', href: '#response-time' },
  { icon: QuestionAnswerIcon, label: 'Content Analysis', href: '#content-analysis' },
  { icon: TouchAppIcon, label: 'CTA Analytics', href: '#cta-analytics' },
  { icon: ImageIcon, label: 'Media Analytics', href: '#media-analytics' },
];

const StickyHeader = ({
  darkMode,
  setDarkMode,
  startDate,
  endDate,
  handleDateRangeChange,
  fetchData,
  exportAllDataToCSV,
  activePreset
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const datePickerSection = (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box flexGrow={1}>
        <DatePickerSection
          isMobile={isMobile}
          startDate={startDate}
          endDate={endDate}
          handleDateRangeChange={handleDateRangeChange}
          theme={theme}
          activePreset={activePreset}
        />
      </Box>
    </LocalizationProvider>
  );

  const actionButtons = (
    <Stack direction="row" spacing={1}>
      <StyledButton
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={fetchData}
        size="small"
      >
        <StyledTypography>Refresh</StyledTypography>
      </StyledButton>
      <StyledButton
        variant="outlined"
        startIcon={<FileDownloadIcon />}
        onClick={exportAllDataToCSV}
        size="small"
      >
        <StyledTypography>Export</StyledTypography>
      </StyledButton>
    </Stack>
  );

  const navigationMenu = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', py: 1 }}>
      {navItems.map((item) => (
        <Tooltip key={item.label} title={item.label} arrow>
          <StyledIconButton
            component="a"
            href={item.href}
            aria-label={item.label}
            size="large"
          >
            <item.icon fontSize="medium" />
          </StyledIconButton>
        </Tooltip>
      ))}
    </Box>
  );

  const drawerContent = (
    <Box
      sx={{ 
        width: 300, 
        p: 3,
        bgcolor: 'background.paper',
        color: 'text.primary',
        height: '100%',
        overflowY: 'auto'
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <LargerTitleTypography variant="h6" component="div">
          Menu
        </LargerTitleTypography>
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      
      <List>
        {navItems.map((item) => (
          <ListItem 
            button 
            key={item.label} 
            component="a" 
            href={item.href}
          >
            <ListItemIcon>
              <DrawerIconButton>
                <item.icon fontSize="medium" />
              </DrawerIconButton>
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
      
      <Divider sx={{ my: 2 }} />
      
      {datePickerSection}
      
      <Divider sx={{ my: 2 }} />
      
      <StyledTypography variant="subtitle1" gutterBottom>Actions</StyledTypography>
      <Stack direction="column" spacing={2} mb={2}>
        {actionButtons}
      </Stack>
      
      <Divider sx={{ my: 2 }} />
      
      <StyledTypography variant="subtitle1" gutterBottom>Theme</StyledTypography>
      <StyledButton 
        variant="outlined" 
        onClick={() => setDarkMode(!darkMode)}
        startIcon={darkMode ? <LightModeRoundedIcon /> : <DarkModeRoundedIcon />}
        fullWidth
      >
        <StyledTypography>
          {darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
        </StyledTypography>
      </StyledButton>
    </Box>
  );

  return (
    <HideOnScroll>
      <AppBar position="sticky" color="default" elevation={0} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Toolbar variant="dense" sx={{ justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center">
            <Avatar
              src="/images/user.png"
              alt="Bot Icon"
              sx={{ width: 40, height: 40, mr: 2 }}
            />
            <LargerTitleTypography color="text.primary" noWrap>
              Analytics Dashboard
            </LargerTitleTypography>
          </Box>
          <Box display="flex" alignItems="center">
            {!isMobile && actionButtons}
            <StyledIconButton
              onClick={() => setDarkMode(!darkMode)}
              size="large"
            >
              {darkMode ? <LightModeRoundedIcon /> : <DarkModeRoundedIcon />}
            </StyledIconButton>
            {isMobile && (
              <StyledIconButton
                edge="end"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                size="large"
              >
                <MenuIcon />
              </StyledIconButton>
            )}
          </Box>
        </Toolbar>
        {!isMobile && (
          <>
            <Box px={2} py={1} bgcolor="background.paper" display="flex" alignItems="center">
              {datePickerSection}
            </Box>
            {navigationMenu}
          </>
        )}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          {drawerContent}
        </Drawer>
      </AppBar>
    </HideOnScroll>
  );
};

export default StickyHeader;
import React from 'react';
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: 280,
  overflow: 'hidden',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
  borderRadius: theme.shape.borderRadius,
}));

const UserInfoBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CustomUserMenu = ({ 
  open, 
  anchorEl,
  onClose, 
  username, 
  companyName,
  handleMenuItemClick,
  handleLogout,
  setOpenChangePasswordDialog
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <StyledPaper elevation={0}>
        <UserInfoBox>
          <Avatar 
            src="/images/user.png"
            sx={{ width: 40, height: 40, mr: 2 }}
          />
          <Avatar 
            sx={{ width: 40, height: 40, mr: 2, bgcolor: 'primary.light' }}
          >
            {username ? username.charAt(0).toUpperCase() : 'U'}
          </Avatar>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{username}</Typography>
            <Typography variant="body2">{companyName}</Typography>
          </Box>
        </UserInfoBox>
        <List disablePadding>
          <StyledListItem button onClick={() => handleMenuItemClick('/profile')}>
            <ListItemIcon><AccountCircleIcon /></ListItemIcon>
            <ListItemText primary="Profile" />
          </StyledListItem>
          <StyledListItem button onClick={() => {
            onClose();
            setOpenChangePasswordDialog(true);
          }}>
            <ListItemIcon><LockIcon /></ListItemIcon>
            <ListItemText primary="Change Password" />
          </StyledListItem>
          <StyledListItem button onClick={handleLogout}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </StyledListItem>
        </List>
      </StyledPaper>
    </Popover>
  );
};

export default CustomUserMenu;
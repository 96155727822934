import React, { useMemo } from 'react';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import InfoButton from '../InfoButton';

const AverageResponseTimeTrendChart = ({ allQAData, isLoading }) => {
  const theme = useTheme();

  const parseDate = (dateString) => {
    const [datePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  const responseTimeTrend = useMemo(() => {
    const trendData = allQAData.reduce((acc, qa) => {
      const date = parseDate(qa.timestamp);
      if (!acc[date]) {
        acc[date] = { date, totalTime: 0, count: 0 };
      }
      acc[date].totalTime += qa.responseTime || 0;
      acc[date].count += 1;
      return acc;
    }, {});

    return Object.values(trendData)
      .map(({ date, totalTime, count }) => ({
        date,
        averageResponseTime: totalTime / count
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [allQAData]);

  

  if (responseTimeTrend.length === 0) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom color="text.primary">
            Average Response Time Trend
          </Typography>
          <Typography>
            No valid data available for this chart.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="text.primary">
          Average Response Time Trend
        </Typography>
        <InfoButton title="Displays how average response time changes over the selected period, useful for tracking performance improvements" />
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={responseTimeTrend}>
            <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
            <XAxis 
              dataKey="date" 
              tick={{ fill: theme.palette.text.primary }}
              tickFormatter={(value) => {
                const [year, month, day] = value.split('-');
                return `${day}/${month}/${year}`;
              }}
            />
            <YAxis 
              tick={{ fill: theme.palette.text.primary }}
              label={{ 
                value: 'Average Response Time (s)', 
                angle: -90, 
                position: 'insideLeft',
                fill: theme.palette.text.primary
              }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 10,
              }}
              formatter={(value) => [`${value.toFixed(2)} s`, 'Avg Response Time']}
              labelFormatter={(value) => {
                const [year, month, day] = value.split('-');
                return `${day}/${month}/${year}`;
              }}
            />
            <Line 
              type="monotone" 
              dataKey="averageResponseTime" 
              stroke={theme.palette.primary.main} 
              strokeWidth={2}
              dot={{ r: 4, fill: theme.palette.primary.main }}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default AverageResponseTimeTrendChart;
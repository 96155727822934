import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField, FormControl, InputLabel, Select, MenuItem,
  Switch, FormControlLabel, Box, Typography, Accordion, AccordionSummary, AccordionDetails,
  ListSubheader, Alert
} from '@mui/material';
import { Add, ExpandMore } from '@mui/icons-material';
import { createCTAButton, updateCTAButton } from '../services/api';
import ButtonPreview from './ButtonPreview';
import { selectedIcons, iconCategories } from './iconList';

const CTAButtonDialog = ({ open, onClose, currentButton, onSave }) => {
  const [buttonData, setButtonData] = useState({
    text: '',
    color: '#1976d2',
    icon: '',
    keywords: '',
    actions: [{ type: 'link', url: '' }],
    variant: 'contained',
    size: 'medium',
    isLoading: false,
    isFab: false,
    isIconOnly: false,
    isToggle: false,
    toggleOptions: ['Option 1', 'Option 2', 'Option 3']
  });
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (currentButton) {
      setButtonData({
        ...currentButton,
        keywords: Array.isArray(currentButton.keywords) ? currentButton.keywords.join(', ') : currentButton.keywords,
        toggleOptions: Array.isArray(currentButton.toggleOptions) ? currentButton.toggleOptions.join(', ') : currentButton.toggleOptions
      });
    } else {
      // Reset to default values
      setButtonData({
        text: '',
        color: '#1976d2',
        icon: '',
        keywords: '',
        actions: [{ type: 'link', url: '' }],
        variant: 'contained',
        size: 'medium',
        isLoading: false,
        isFab: false,
        isIconOnly: false,
        isToggle: false,
        toggleOptions: ['Option 1', 'Option 2', 'Option 3']
      });
    }
    setSuccess(null);
    setError(null);
  }, [currentButton, open]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    
    setButtonData(prevData => {
      if (name === 'keywords') {
        return { ...prevData, [name]: value };
      } else if (name === 'toggleOptions') {
        return { ...prevData, [name]: value.split(',').map(option => option.trim()) };
      } else if (type === 'checkbox') {
        return { ...prevData, [name]: checked };
      } else {
        return { ...prevData, [name]: value };
      }
    });
  };

  const handleActionChange = (index, field, value) => {
    setButtonData(prevData => {
      const newActions = [...prevData.actions];
      if (field === 'type') {
        // When changing action type, reset all fields except 'type'
        newActions[index] = { type: value };
        // Initialize default fields based on new type
        switch (value) {
          case 'link':
            newActions[index].url = '';
            break;
          case 'phone':
            newActions[index].number = '';
            break;
          case 'email':
            newActions[index].address = '';
            break;
          case 'message':
            newActions[index].content = '';
            break;
          case 'copy':
            newActions[index].text = '';
            break;
          default:
            // Handle unexpected action types
            console.warn(`Unexpected action type: ${value}`);
            newActions[index] = { type: value };
            break;
        }
      } else {
        newActions[index] = { ...newActions[index], [field]: value };
      }
      return { ...prevData, actions: newActions };
    });
  };

  const handleAddAction = () => {
    setButtonData(prevData => ({
      ...prevData,
      actions: [...prevData.actions, { type: 'link', url: '' }]
    }));
  };

  const handleRemoveAction = (index) => {
    setButtonData(prevData => ({
      ...prevData,
      actions: prevData.actions.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const formattedButtonData = {
      ...buttonData,
      id: buttonData.id || buttonData._id, // Ensure id is included
      keywords: Array.isArray(buttonData.keywords) 
        ? buttonData.keywords 
        : buttonData.keywords.split(',').map(k => k.trim()),
      toggleOptions: Array.isArray(buttonData.toggleOptions)
        ? buttonData.toggleOptions
        : buttonData.toggleOptions.split(',').map(o => o.trim()),
    };

    console.log('Submitting button data:', formattedButtonData);

    let updatedButton;
    if (currentButton) {
      updatedButton = await updateCTAButton(formattedButtonData.id, formattedButtonData);
    } else {
      updatedButton = await createCTAButton(formattedButtonData);
    }

    console.log('Button after create/update:', updatedButton);

    setSuccess(currentButton ? 'CTA Button updated successfully' : 'CTA Button created successfully');
    onSave(updatedButton.button || updatedButton); // Handle both possible response formats
    setTimeout(() => {
      setSuccess(null);
      onClose();
    }, 1500);
  } catch (error) {
    console.error('Error saving CTA button:', error);
    setError('Failed to save CTA Button: ' + (error.response?.data?.detail || error.message || 'Unknown error'));
  }
};

  const renderActionFields = (action, index) => {
    switch (action.type) {
      case 'link':
        return (
          <TextField
            fullWidth
            margin="normal"
            label="URL"
            value={action.url}
            onChange={(e) => handleActionChange(index, 'url', e.target.value)}
          />
        );
      case 'phone':
        return (
          <TextField
            fullWidth
            margin="normal"
            label="Phone Number"
            value={action.number}
            onChange={(e) => handleActionChange(index, 'number', e.target.value)}
          />
        );
      case 'email':
        return (
          <TextField
            fullWidth
            margin="normal"
            label="Email Address"
            value={action.address}
            onChange={(e) => handleActionChange(index, 'address', e.target.value)}
          />
        );
      case 'message':
        return (
          <TextField
            fullWidth
            margin="normal"
            label="Message Content"
            multiline
            rows={3}
            value={action.content}
            onChange={(e) => handleActionChange(index, 'content', e.target.value)}
          />
        );
      case 'copy':
        return (
          <TextField
            fullWidth
            margin="normal"
            label="Text to Copy"
            value={action.text}
            onChange={(e) => handleActionChange(index, 'text', e.target.value)}
          />
        );
      default:
        return null;
    }
  };

  const renderIconOptions = () => {
    return iconCategories.map(category => [
      <ListSubheader key={category.name}>{category.name}</ListSubheader>,
      ...category.icons.map(iconName => (
        <MenuItem key={iconName} value={iconName}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {selectedIcons[iconName] && React.createElement(selectedIcons[iconName], { sx: { mr: 1 } })}
            {iconName}
          </Box>
        </MenuItem>
      ))
    ]);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{currentButton ? 'Edit CTA Button' : 'Add CTA Button'}</DialogTitle>
      <DialogContent>
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>Preview:</Typography>
          <ButtonPreview buttonData={buttonData} />
        </Box>
        <TextField
          fullWidth
          margin="normal"
          label="Button Text"
          name="text"
          value={buttonData.text}
          onChange={handleChange}
        />
        <Box mt={2}>
          <Typography variant="subtitle1">Button Color</Typography>
          <input
            type="color"
            name="color"
            value={buttonData.color}
            onChange={handleChange}
            style={{ width: '100%', height: '50px' }}
          />
        </Box>
        <FormControl fullWidth margin="normal">
          <InputLabel>Icon</InputLabel>
          <Select
            name="icon"
            value={buttonData.icon}
            onChange={handleChange}
          >
            <MenuItem value="">None</MenuItem>
            {renderIconOptions()}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          label="Keywords (comma-separated)"
          name="keywords"
          value={buttonData.keywords}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Variant</InputLabel>
          <Select
            name="variant"
            value={buttonData.variant}
            onChange={handleChange}
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="contained">Contained</MenuItem>
            <MenuItem value="outlined">Outlined</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Size</InputLabel>
          <Select
            name="size"
            value={buttonData.size}
            onChange={handleChange}
          >
            <MenuItem value="small">Small</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="large">Large</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={buttonData.isLoading}
              onChange={handleChange}
              name="isLoading"
            />
          }
          label="Loading Button"
        />
        <FormControlLabel
          control={
            <Switch
              checked={buttonData.isFab}
              onChange={handleChange}
              name="isFab"
            />
          }
          label="Floating Action Button (FAB)"
        />
        <FormControlLabel
          control={
            <Switch
              checked={buttonData.isIconOnly}
              onChange={handleChange}
              name="isIconOnly"
            />
          }
          label="Icon Only Button"
        />
        <FormControlLabel
          control={
            <Switch
              checked={buttonData.isToggle}
              onChange={handleChange}
              name="isToggle"
            />
          }
          label="Toggle Button"
        />
        {buttonData.isToggle && (
          <TextField
            fullWidth
            margin="normal"
            label="Toggle Options (comma-separated)"
            name="toggleOptions"
            value={Array.isArray(buttonData.toggleOptions) ? buttonData.toggleOptions.join(', ') : buttonData.toggleOptions}
            onChange={(e) => handleChange({ target: { name: 'toggleOptions', value: e.target.value.split(',').map(option => option.trim()) } })}
          />
        )}
        <Box mt={2}>
          <Typography variant="h6">Actions</Typography>
          {buttonData.actions.map((action, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Action {index + 1}: {action.type}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Action Type</InputLabel>
                  <Select
                    value={action.type}
                    onChange={(e) => handleActionChange(index, 'type', e.target.value)}
                  >
                    <MenuItem value="link">Link</MenuItem>
                    <MenuItem value="phone">Phone</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="message">Message</MenuItem>
                    <MenuItem value="copy">Copy to Clipboard</MenuItem>
                  </Select>
                </FormControl>
                {renderActionFields(action, index)}
                <Button onClick={() => handleRemoveAction(index)} color="secondary">
                  Remove Action
                </Button>
              </AccordionDetails>
            </Accordion>
          ))}
          <Button onClick={handleAddAction} startIcon={<Add />} sx={{ mt: 2 }}>
            Add Action
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {currentButton ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CTAButtonDialog;
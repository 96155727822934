import React from 'react';
import { Box, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { chatConfig } from './chatConfig';

const ThinkingAnimation = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const config = chatConfig.thinkingAnimation;

  const dotVariants = {
    start: { y: 0 },
    end: { y: -config.animationHeight },
  };

  const dotTransition = {
    duration: config.animationDuration,
    repeat: Infinity,
    repeatType: 'reverse',
    ease: 'easeInOut',
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: config.containerHeight }}>
      {[0, 1, 2].map((index) => (
        <motion.div
          key={index}
          style={{
            width: config.dotSize,
            height: config.dotSize,
            backgroundColor: isDarkMode ? config.dotColor.dark : config.dotColor.light,
            borderRadius: '50%',
            margin: `0 ${config.dotSpacing}px`,
          }}
          variants={dotVariants}
          initial="start"
          animate="end"
          transition={{ ...dotTransition, delay: index * config.delayBetweenDots }}
        />
      ))}
    </Box>
  );
};

export default ThinkingAnimation;
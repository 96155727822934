import React, { useMemo } from 'react';
import { Grid, Card, CardContent, Typography, useTheme, Tooltip as MuiTooltip, IconButton, Box } from '@mui/material';
import { ResponsiveContainer, Treemap, Tooltip as RechartsTooltip } from 'recharts';
import InfoIcon from '@mui/icons-material/Info';
import { alpha } from '@mui/material/styles';

const TopicAnalysisGrid = ({ allQAData, isLoading }) => {
  const theme = useTheme();

  const topicData = useMemo(() => {
    const topics = {};
    allQAData.forEach(qa => {
      const words = qa.question.toLowerCase().split(/\W+/);
      words.forEach(word => {
        if (word.length > 3) {  // Ignore short words
          topics[word] = (topics[word] || 0) + 1;
        }
      });
    });
    
    return Object.entries(topics)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 20)  // Top 20 topics
      .map(([name, value]) => ({ name, value }));
  }, [allQAData]);

  const colorScale = [
    '#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c'
  ];

  const InfoButton = ({ title }) => (
    <MuiTooltip title={title}>
      <IconButton size="small">
        <InfoIcon fontSize="small" />
      </IconButton>
    </MuiTooltip>
  );

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            padding: '18px',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '40px',
            boxShadow: theme.shadows[2],
             
          }}
        >
          <Typography variant="body2">
            <strong>{data.name}</strong>: {data.value} occurrences
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const CustomizedTreemapContent = ({ x, y, width, height, index, name, value }) => {
    const fontSize = Math.min(width / 12, height / 2, 22);
    const color = colorScale[index % colorScale.length];
    const textColor = theme.palette.mode === 'dark' ? '#ffffff' : '#000000';
    const rectColor = theme.palette.mode === 'dark' ? alpha(color, 0.7) : color;
    
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: rectColor,
            stroke: 'none',  // Remove the stroke to eliminate the outline
          }}
        />
        {width > 30 && height > 30 && (
          <text
            x={x + width / 2}
            y={y + height / 2}
            textAnchor="middle"
            fill={textColor}
            fontSize={fontSize}
            
            dy=".3em"
            style={{
              pointerEvents: 'none',  // Prevent text from interfering with hover events
            }}
          >
            {name}
          </text>
        )}
      </g>
    );
  };

  return ( 
    <Grid item xs={12}>
      <Card sx={{ boxShadow: theme.shadows[1], borderRadius: 2 }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" color="text.primary" fontWeight="medium">
              Top 20 Topics in User Questions
            </Typography>
            <InfoButton title="Treemap showing the most common topics in user questions, with size representing frequency" />
          </Box>
          <ResponsiveContainer width="100%" height={450}>
            <Treemap
              data={topicData}
              dataKey="value"
              aspectRatio={1}
              stroke="none"  // Remove the stroke from the Treemap component
              content={<CustomizedTreemapContent />}
            >
              <RechartsTooltip content={<CustomTooltip />} />
            </Treemap>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TopicAnalysisGrid;
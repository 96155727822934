import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import chatTheme from './components/chatTheme';
import CssBaseline from '@mui/material/CssBaseline';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Divider,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tabs,
  Tab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';



import ChatIcon from '@mui/icons-material/Chat';
import AnalyticsIcon from '@mui/icons-material/BarChart';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import CustomUserMenu from './components/CustomUserMenu';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import TouchAppIcon from '@mui/icons-material/TouchApp';

import Login from './components/Login';
import ChatInterface from './components/ChatInterface';
import AdminDashboard from './components/AdminDashboard';
import AnalyticsDashboard from './components/AnalyticsDashboard';
import KnowledgeBase from './components/KnowledgeBase';
import UserProfile from './components/UserProfile';
import ChangePassword from './components/ChangePassword';
import { getSettings, getUserInfo } from './services/api';
import MediaManager from './components/MediaManager';
import CTAButtonManager from './components/CTAButtonManager';
import packageInfo from '../package.json';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.mode === 'dark' ? 'rgba(18, 18, 18, 0.8)' : 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: '66px',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem', // Reduced from 1rem
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    height: '100%',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 'auto',
  padding: '4px 12px', // Reduced from '6px 16px'
  marginRight: theme.spacing(1), // Reduced from theme.spacing(2)
  borderRadius: theme.shape.borderRadius,
  flexDirection: 'row',
  textTransform: 'none',
  fontSize: '0.875rem', // Reduced from 1rem
  fontWeight: 400,
  height: '100%',
  color: theme.palette.text.primary,
  '& .MuiTab-iconWrapper': {
    marginBottom: 0,
    marginRight: theme.spacing(0.5), // Reduced from theme.spacing(1)
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.primary,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: '2px',
  },
}));

const StyledUserButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1),
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.08)',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'auto',
  minWidth: '250px', // Reduced from 300px
}));

function AppHeader({ 
  navigateFunc, 
  currentLocation, 
  isAuthenticated, 
  isAdmin, 
  username, 
  setDarkMode, 
  darkMode, 
  handleLogout, 
  setOpenChangePasswordDialog 
}) {
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg')); // Changed from 'md' to 'lg'


  const handleUserMenuOpen = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    handleUserMenuClose();
    navigateFunc(path);
  };

 const menuItems = [
    { path: '/', label: 'Chat', icon: <ChatIcon /> },
    { path: '/analytics', label: 'Analytics Dashboard', icon: <AnalyticsIcon /> },
    ...(isAdmin ? [
      { path: '/admin', label: 'Admin', icon: <AdminPanelSettingsIcon /> },
      { path: '/knowledge-base', label: 'Knowledge Base', icon: <LibraryBooksIcon /> },
      { path: '/media-manager', label: 'Media Manager', icon: <PermMediaIcon /> },
      { path: '/cta-buttons', label: 'CTA Buttons', icon: <TouchAppIcon /> }
    ] : []),
  ];
  
  const getCurrentTabValue = () => {
    const index = menuItems.findIndex(item => item.path === currentLocation.pathname);
    return index !== -1 ? index : false;
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <StyledAppBar position="static" color="default">
      <Toolbar variant="dense" sx={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}>
        {isMobile ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={(event) => setMobileMenuAnchorEl(event.currentTarget)}
            size="small"
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <StyledTabs 
            value={getCurrentTabValue()} 
            indicatorColor="primary" 
            textColor="primary"
            sx={{ minHeight: '56px' }}
          >
            {menuItems.map((item, index) => (
              <StyledTab
                key={item.path}
                label={item.label}
                icon={item.icon}
                iconPosition="start"
                onClick={() => navigateFunc(item.path)}
                value={index}
                sx={{ minHeight: '56px' }}
              />
            ))}
          </StyledTabs>
        )}
        
         <Box display="flex" alignItems="center">
          <StyledUserButton
            onClick={handleUserMenuOpen}
            color="inherit"
            size="small"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
          >
            <Box display="flex" alignItems="center">
              <Avatar 
                src="/images/user.png" 
                alt="Bot Icon" 
                sx={{ width: 34, height: 34, mr: 1 }}
              />
              <StyledTypography sx={{ mr: 1 }}>
                {packageInfo.name}
              </StyledTypography>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <Box display="flex" alignItems="center">
              <Avatar sx={{ width: 34, height: 34, fontSize: '0.875rem', bgcolor: 'primary.main', color: 'primary.contrastText', mr: 1 }}>
                {username ? username.charAt(0).toUpperCase() : 'U'}
              </Avatar>
              <StyledTypography>
                {username}
              </StyledTypography>
            </Box>
           </StyledUserButton>
          <IconButton 
            onClick={() => setDarkMode(!darkMode)} 
            color="inherit" 
            size="small" 
            sx={{ ml: 1 }}
          >
            {darkMode ? <LightModeRoundedIcon /> : <DarkModeRoundedIcon />}
          </IconButton>
        </Box>
        
        <CustomUserMenu 
          open={Boolean(userMenuAnchorEl)}
          anchorEl={userMenuAnchorEl}
          onClose={handleUserMenuClose}
          username={username}
          companyName={packageInfo.name}
          handleMenuItemClick={handleMenuItemClick}
          handleLogout={() => {
            handleLogout();
            handleUserMenuClose();
          }}
          setOpenChangePasswordDialog={(value) => {
            setOpenChangePasswordDialog(value);
            handleUserMenuClose();
          }}
        />
        <Menu
          id="mobile-menu"
          anchorEl={mobileMenuAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(mobileMenuAnchorEl)}
          onClose={() => setMobileMenuAnchorEl(null)}
        >
          {menuItems.map((item) => (
            <MenuItem key={item.path} onClick={() => handleMenuItemClick(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={<StyledTypography>{item.label}</StyledTypography>} />
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
}

function AppContent({ 
  isAuthenticated, 
  isAdmin, 
  username, 
  handleLogin, 
  handleLogout, 
  settings,
  setSettings,
  darkMode,
  setDarkMode,
  openChangePasswordDialog,
  setOpenChangePasswordDialog,
  welcomeMessage,
  setWelcomeMessage
}) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      {isAuthenticated && (
        <AppHeader 
          navigateFunc={navigate} 
          currentLocation={location}
          isAuthenticated={isAuthenticated}
          isAdmin={isAdmin}
          username={username}
          setDarkMode={setDarkMode}
          darkMode={darkMode}
          handleLogout={handleLogout}
          setOpenChangePasswordDialog={setOpenChangePasswordDialog}
        />
      )}
      <Routes>
        <Route path="/login" element={
          isAuthenticated ? <Navigate to="/" /> : <Login 
              onLogin={handleLogin} 
              darkMode={darkMode} 
              onToggleDarkMode={() => setDarkMode(prevMode => !prevMode)} 
            />
        } />
        <Route path="/" element={
  <ChatInterface 
    darkMode={darkMode}
    setDarkMode={setDarkMode}
    settings={settings}
    setSettings={setSettings}
    welcomeMessage={welcomeMessage}
    setWelcomeMessage={setWelcomeMessage}
  />
} />
		<Route path="/" element={
          <ChatInterface 
            darkMode={darkMode}
            setDarkMode={setDarkMode}
            settings={settings}
            setSettings={setSettings}
            welcomeMessage={welcomeMessage}
            setWelcomeMessage={setWelcomeMessage}
          />
        } />
        <Route path="/analytics" element={
          isAuthenticated ? <AnalyticsDashboard /> : <Navigate to="/login" state={{ from: location }} replace />
        } />
        <Route 
          path="/admin" 
          element={
            isAuthenticated && isAdmin 
              ? <AdminDashboard /> 
              : <Navigate to="/" replace />
          } 
        />
        <Route 
          path="/knowledge-base" 
          element={
            isAuthenticated && isAdmin 
              ? <KnowledgeBase /> 
              : <Navigate to="/" replace />
          } 
        />
        <Route 
          path="/media-manager" 
          element={
            isAuthenticated && isAdmin 
              ? <MediaManager /> 
              : <Navigate to="/" replace />
          } 
        />
        <Route 
          path="/cta-buttons" 
          element={
            isAuthenticated && isAdmin 
              ? <CTAButtonManager /> 
              : <Navigate to="/" replace />
          } 
        />
        <Route path="/profile" element={
          isAuthenticated ? <UserProfile username={username} /> : <Navigate to="/login" state={{ from: location }} replace />
        } />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {isAuthenticated && (
        <ChangePassword 
          open={openChangePasswordDialog} 
          onClose={() => setOpenChangePasswordDialog(false)}
          username={username}
        />
      )}
    </>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState('');
  const [settings, setSettings] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('Welcome to the Chat Interface!');
  const theme = chatTheme(darkMode ? 'dark' : 'light', settings || {});



  const handleLogout = useCallback(() => {
    console.log("Logging out...");
    setIsAuthenticated(false);
    setIsAdmin(false);
    setUsername('');
    setSettings(null);
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('username');
  }, []);

  const fetchSettings = useCallback(async () => {
  try {
    const fetchedSettings = await getSettings();
    if (fetchedSettings) {
      setSettings(fetchedSettings);
    } else {
      
      setSettings({
        welcomeMessage: 'Welcome to the Chat Interface!',
        primaryColor: '#1976d2',
        secondaryColor: '#dc004e',
      });
    }
  } catch (error) {
    
    setSettings({}); // Set to empty object on error
  }
}, []);

  useEffect(() => {
    const checkAuthStatus = async () => {
      
      const token = localStorage.getItem('token');
      
      
      if (token) {
        try {
          
          const userInfo = await getUserInfo();
          
          setIsAuthenticated(true);
          setIsAdmin(userInfo.is_admin);
          setUsername(userInfo.username);
        } catch (error) {
          
          setIsAuthenticated(false);
          setIsAdmin(false);
          setUsername('');
        }
      } else {
        
        setIsAuthenticated(false);
      }
      try {
        await fetchSettings();
      } catch (error) {
        
      }
      setIsLoading(false);
    };

    checkAuthStatus();
  }, [fetchSettings]);

  const handleLogin = useCallback((adminStatus, username, token) => {
    
    setIsAuthenticated(true);
    setIsAdmin(adminStatus);
    setUsername(username);
    localStorage.setItem('token', token);
    localStorage.setItem('isAdmin', adminStatus);
    localStorage.setItem('username', username);
    fetchSettings(token);
  }, [fetchSettings]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent 
          isAuthenticated={isAuthenticated}
          isAdmin={isAdmin}
          username={username}
          handleLogin={handleLogin}
          handleLogout={handleLogout}
          settings={settings}
          setSettings={setSettings}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          openChangePasswordDialog={openChangePasswordDialog}
          setOpenChangePasswordDialog={setOpenChangePasswordDialog}
          welcomeMessage={welcomeMessage}
          setWelcomeMessage={setWelcomeMessage}
        />
      </Router>
    </ThemeProvider>
  );
}

export default App;
          
          
          
          
          
          
          
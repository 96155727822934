import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Typography, Box, Avatar, IconButton, useTheme, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ErrorIcon from '@mui/icons-material/Error';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MediaRenderer from './MediaRenderer';
import chatConfig from './chatConfig';
import { getAllMedia } from '../services/api';
import CTAButtonRenderer from './CTAButtonRenderer';
import { getCTAButtons } from '../services/api';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';

const MessageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUser'
})(({ theme, isUser }) => ({
  display: 'flex',
  flexDirection: isUser ? 'row-reverse' : 'row',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const MessageContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUser'
})(({ theme, isUser }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: isUser ? 'flex-end' : 'flex-start',
  maxWidth: '80%',
  marginLeft: isUser ? 0 : theme.spacing(1),
  marginRight: isUser ? theme.spacing(1) : 0,
}));

const MessageBubble = styled(motion.div)(({ theme, sender, config }) => {
  const bubbleConfig = config.messageBubble[sender];
  const gradient = theme.palette.mode === 'dark' ? bubbleConfig.darkGradient : bubbleConfig.lightGradient;
  
  const primaryColor = gradient.match(/rgba?\([\d\s,.]+\)/)?.[0] || 
                     (theme.palette.mode === 'dark' ? '#333' : '#fff');
  
  return {
    padding: bubbleConfig.padding,
    maxWidth: bubbleConfig.maxWidth,
    width: '100%',
    boxShadow: bubbleConfig.boxShadow,
    fontFamily: bubbleConfig.fontFamily,
    fontSize: bubbleConfig.fontSize,
    background: gradient,
    color: theme.palette.getContrastText(primaryColor),
    borderRadius: bubbleConfig.borderRadius,
    position: 'relative',
    transition: 'all 0.3s ease',
    '&:hover': {
      boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
    },
    ...getBubbleShape(bubbleConfig),
  };
});

const getBubbleShape = (bubbleConfig) => {
  switch (bubbleConfig.shape) {
    case 'rounded':
      return { borderRadius: '30px' };
    case 'angular':
      return { borderRadius: '0px' };
    case 'tailLeft':
    case 'tailRight':
      return {
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          [bubbleConfig.shape === 'tailLeft' ? 'left' : 'right']: `-${bubbleConfig.tail.size}`,
          width: 0,
          height: 0,
          borderRight: `${bubbleConfig.tail.size} solid ${bubbleConfig.tail.color}`,
          borderTop: `${bubbleConfig.tail.size} solid transparent`,
        }
      };
    case 'custom':
      return { clipPath: bubbleConfig.customShape };
    default:
      return {};
  }
};

const CustomSnackbar = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== 'snackbarConfig' && prop !== 'config'
})(({ theme, snackbarConfig, config }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: snackbarConfig?.backgroundColor || theme.palette.grey[300],
  color: snackbarConfig?.color || theme.palette.text.primary,
  transition: `opacity ${config?.duration || 3000}ms ease-in-out`,
  padding: theme.spacing(2),
  borderRadius: config?.borderRadius || '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: config?.maxWidth || '300px',
  fontSize: config?.fontSize || '1rem',
  boxShadow: theme.shadows[10],
  zIndex: 9999,
}));

const Message = React.memo(({ 
  message, 
  darkMode, 
  onCopy, 
  onFeedback, 
  metadata, 
  config, 
  sessionId,
  isLatestBotMessage, 
  isLatestUserMessage,
  userIcon,
  botIcon
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [feedbackGiven, setFeedbackGiven] = useState(message.feedback || null);
  const [mediaItems, setMediaItems] = useState([]);
  const [ctaButtons, setCTAButtons] = useState([]);
  const theme = useTheme();
  const messageRef = useRef(null);
  const isUser = message.sender === 'user';

  const defaultSnackbarConfig = useMemo(() => ({
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    icon: null,
  }), [theme]);
  
  

  const avatarConfig = isUser ? config.avatars?.user : config.avatars?.bot;
  const messageControls = config.messageControls || {};

  // Scroll to message if it's the latest
  useEffect(() => {
    if (messageRef.current && ((isUser && isLatestUserMessage) || (!isUser && isLatestBotMessage))) {
      setTimeout(() => {
        if (messageRef.current) {  // Add this check
          messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
    }
  }, [isUser, isLatestBotMessage, isLatestUserMessage, message]);
  
  
  
  
  
  
  
  
  // Fetch media items
  useEffect(() => {
  const fetchData = async () => {
    try {
      const [media, buttons] = await Promise.all([getAllMedia(), getCTAButtons()]);
      
      setMediaItems(media);
      setCTAButtons(buttons);
    } catch (error) {
      console.error('Error fetching media or CTA buttons:', error);
    }
  };
  fetchData();
}, []);

  // Handle snackbar auto-close
  useEffect(() => {
    if (snackbarOpen) {
      const timer = setTimeout(() => setSnackbarOpen(false), chatConfig.snackbars.duration);
      return () => clearTimeout(timer);
    }
  }, [snackbarOpen]);

  const timestamp = useMemo(() => new Date().toLocaleString(
    messageControls.timestamp?.format || 'en-US',
    messageControls.timestamp?.options || {}
  ), [messageControls.timestamp]);

  const getMediaForKeywords = useCallback((text) => {
    if (!mediaItems.length) return [];
    const lowerText = text.toLowerCase();
  
  return mediaItems.filter(mediaItem => 
      mediaItem.keywords.some(keyword => lowerText.includes(keyword.toLowerCase()))
    ).map(mediaItem => ({
      id: mediaItem.id,
      type: mediaItem.type,
      filename: mediaItem.filename,
      url: mediaItem.url,
      original_filename: mediaItem.original_filename,
      keywords: mediaItem.keywords,
      cta_buttons: mediaItem.cta_buttons?.map(button => ({
        text: button.text,
        url: button.url,
        color: button.color || '#1976d2',
        icon: button.icon,
        delay: button.delay || 0,
        actions: button.actions || [],
        tooltip: button.tooltip || '',
        fontSize: button.fontSize,
        padding: button.padding,
        borderRadius: button.borderRadius
      })),
      pdfUrl: mediaItem.type === 'pdf' ? `${BACKEND_URL}/api/media/${mediaItem.filename}` : undefined
    }));
  }, [mediaItems]);

  const handleFeedback = useCallback(async (feedbackType, event) => {
  // Prevent default behavior and stop propagation
  event.preventDefault();
  event.stopPropagation();

  // Get the current scroll position
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

  try {
    await onFeedback(feedbackType);
    setFeedbackGiven(feedbackType);
    const feedbackConfig = chatConfig.snackbars.feedback[feedbackType] || chatConfig.snackbars.feedback.neutral;
    setSnackbarConfig({
      ...defaultSnackbarConfig,
      backgroundColor: feedbackConfig.backgroundColor,
      color: feedbackConfig.textColor,
    });
    
    const getFeedbackIcon = (type) => {
      switch (type) {
        case 'thumbs_up': return <ThumbUpIcon sx={{ mr: 1 }} />;
        case 'thumbs_down': return <ThumbDownIcon sx={{ mr: 1 }} />;
        case 'heart': return <FavoriteIcon sx={{ mr: 1 }} />;
        default: return null;
      }
    };

    setSnackbarMessage(
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {getFeedbackIcon(feedbackType)}
        <Typography variant="body2">
          {feedbackConfig.message || `Feedback submitted: ${feedbackType}`}
        </Typography>
      </Box>
    );
    setSnackbarOpen(true);
  } catch (error) {
    
    const errorConfig = chatConfig.snackbars.feedback.error || chatConfig.snackbars.feedback.neutral;
    setSnackbarConfig({
      ...defaultSnackbarConfig,
      backgroundColor: errorConfig.backgroundColor,
      color: errorConfig.textColor,
    });
    setSnackbarMessage(
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ErrorIcon sx={{ mr: 1 }} />
        <Typography variant="body2">
          {errorConfig.message || 'Failed to submit feedback. Please try again.'}
        </Typography>
      </Box>
    );
    setSnackbarOpen(true);
  } finally {
    // Force the scroll position to remain the same
    window.scrollTo(0, scrollPosition);
  }
}, [onFeedback, defaultSnackbarConfig]);

  const handleCopy = useCallback(() => {
    onCopy(timestamp);
    const copyConfig = config.snackbars.copy;
    setSnackbarConfig({ ...defaultSnackbarConfig, ...copyConfig });
    setSnackbarMessage(copyConfig.message || 'Message copied to clipboard');
    setSnackbarOpen(true);
  }, [onCopy, timestamp, config.snackbars.copy, defaultSnackbarConfig]);
  
  
  
  const handleAnalytics = useCallback((buttonId, action, additionalData = {}) => {
    // Log the analytics event
    console.log(`CTA Button Analytics: Button ${buttonId} - Action: ${action}`, additionalData);

    // Here you would typically send this data to your analytics service
    // For example:
    // sendToAnalyticsService({
    //   buttonId,
    //   action,
    //   timestamp: new Date().toISOString(),
    //   messageId: message.id, // Assuming each message has an id
    //   isUserMessage: isUser,
    //   ...additionalData
    // });

    // If you're using a third-party analytics service like Google Analytics or Mixpanel,
    // you might do something like this:
    // if (window.gtag) {
    //   window.gtag('event', action, {
    //     'event_category': 'CTA Button',
    //     'event_label': buttonId,
    //     ...additionalData
    //   });
    // }

    // You can also update local state or perform other actions based on the analytics event
    // For example, you might want to update a counter for button displays or clicks
    // setButtonStats(prevStats => ({
    //   ...prevStats,
    //   [buttonId]: {
    //     ...prevStats[buttonId],
    //     [action]: (prevStats[buttonId]?.[action] || 0) + 1
    //   }
    // }));
  }, []);
  
  
  
  
  

  const renderMedia = useCallback(() => {
    if (!isUser) {
      const mediaInfoArray = getMediaForKeywords(message.text);
      
      if (mediaInfoArray && mediaInfoArray.length > 0) {
        return (
          <Box mt={2} width="100%">
            <MediaRenderer 
              mediaItems={mediaInfoArray}
              darkMode={darkMode}
              config={config.media}
              onAnalytics={(buttonId, action) => {
                console.log(`Media interaction: Button ${buttonId} ${action}`);
              }}
            />
          </Box>
        );
      }
    }
    return null;
  }, [isUser, message.text, getMediaForKeywords, darkMode, config.media]);





  return (
    <MessageContainer 
      isUser={isUser} 
      ref={messageRef}
      data-message-type={isUser ? 'user' : 'bot'}
    >
      <Avatar
        src={isUser ? userIcon : botIcon}  // Use the correct icon based on the sender
        alt={`${isUser ? 'User' : 'Bot'} Avatar`}
        sx={{ 
          m: 1, 
          width: avatarConfig?.size || 40,
          height: avatarConfig?.size || 40,
          bgcolor: theme.palette.mode === 'dark' 
            ? avatarConfig?.backgroundColor?.dark || '#333'
            : avatarConfig?.backgroundColor?.light || '#eee'
        }}
      />
      <MessageContent isUser={isUser}>
        <MessageBubble
          sender={message.sender}
          config={config}
          theme={theme}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
        >
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={{
              a: ({ node, children, ...props }) => {
                const linkStyle = {
                  color: theme.palette.mode === 'dark' ? '#8ab4f8' : '#1a73e8',
                  textDecoration: 'underline',
                  '&:hover': {
                    color: theme.palette.mode === 'dark' ? '#aecbfa' : '#174ea6',
                    textDecoration: 'none',
                  },
                };
                if (children.length === 0) {
                  return (
                    <a 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      aria-label={`Link to ${props.href}`} 
                      {...props}
                      style={linkStyle}
                    >
                      {props.href}
                    </a>
                  );
                }
                return (
                  <a 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    {...props}
                    style={linkStyle}
                  >
                    {children}
                  </a>
                );
              },
            }}
          >
            {message.text}
          </ReactMarkdown>
          {!isUser && renderMedia()}
          {!isUser && (
        <CTAButtonRenderer 
          message={message.text}
          ctaButtons={ctaButtons}
          onAnalytics={handleAnalytics}
          sessionId={sessionId}  // Add this line
        />
      )}
        </MessageBubble>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: isUser ? 'flex-start' : 'flex-end', 
          alignItems: 'center', 
          mt: 1,
          ...(messageControls.container || {})
        }}>
          <Typography 
            variant="caption" 
            sx={{ 
              fontSize: messageControls.timestamp?.fontSize,
              fontWeight: messageControls.timestamp?.fontWeight,
              color: theme.palette.mode === 'dark' 
                ? messageControls.timestamp?.color?.dark 
                : messageControls.timestamp?.color?.light 
            }}
          >
            {timestamp}
          </Typography>
          <IconButton 
            size={messageControls.copyIcon?.size}
            onClick={handleCopy} 
            sx={{ 
              ml: messageControls.copyIcon?.marginLeft,
              color: theme.palette.mode === 'dark' 
                ? messageControls.copyIcon?.color?.dark 
                : messageControls.copyIcon?.color?.light,
              '&:hover': {
                color: theme.palette.mode === 'dark'
                  ? messageControls.copyIcon?.hoverColor?.dark
                  : messageControls.copyIcon?.hoverColor?.light,
              }
            }}
          >
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </Box>
        {!isUser && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          {['thumbs_up', 'thumbs_down', 'heart'].map((type) => (
            <Tooltip key={type} title={type.replace('_', ' ')}>
              <IconButton 
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleFeedback(type, e);
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                sx={{ 
                  color: feedbackGiven === type
                    ? config.feedbackButtons.activeColor[type]
                    : config.feedbackButtons.color[darkMode ? 'dark' : 'light'],
                  '&:hover': {
                    color: config.feedbackButtons.hoverColor[type],
                  }
                }}
              >
                {type === 'thumbs_up' && <ThumbUpIcon />}
                {type === 'thumbs_down' && <ThumbDownIcon />}
                {type === 'heart' && <FavoriteIcon />}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      )}
      </MessageContent>
      <AnimatePresence>
        {snackbarOpen && (
          <CustomSnackbar
            config={config.snackbars || {}}
            snackbarConfig={snackbarConfig}
            initial={config.snackbars?.animation?.initial}
            animate={config.snackbars?.animation?.animate}
            exit={config.snackbars?.animation?.exit}
            transition={config.snackbars?.animation?.transition}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {snackbarConfig.icon && (
                <Box component="span" sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                  {snackbarConfig.icon}
                </Box>
              )}
              {typeof snackbarMessage === 'string' ? (
                <Typography variant="body2">{snackbarMessage}</Typography>
              ) : (
                snackbarMessage
              )}
            </Box>
          </CustomSnackbar>
        )}
      </AnimatePresence>
    </MessageContainer>
  );
});

export default Message;
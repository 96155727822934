import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Chip } from '@mui/material';
import { Star } from '@mui/icons-material';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const MediaPreview = ({ item, customFileName }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    try {
      const url = getMediaUrl(item);
      setMediaUrl(url);
      setLoading(false);
    } catch (err) {
      console.error('Error in useEffect:', err);
      setError(err.message);
      setLoading(false);
    }
  }, [item]);

  const getMediaUrl = (item) => {
    if (item.url) {
      return item.url;
    }
    if (item.filename) {
      return `${API_URL}/api/media/${item.filename}`;
    }
    throw new Error('No valid media URL');
  };

  const getYoutubeEmbedUrl = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  if (match && match[2].length === 11) {
    return `https://www.youtube.com/embed/${match[2]}`;
  }
  return null;
};

  const handleLoad = () => {
    
    setLoading(false);
  };

  const handleError = (e) => {
    console.error('Error loading media:', e);
    setError('Failed to load media');
    setLoading(false);
  };

  const renderContent = () => {
  if (!mediaUrl) {
    return <Typography variant="body2">No valid media URL</Typography>;
  }

  const commonStyles = {
    width: '100%', 
    height: 'auto', 
    maxHeight: '400px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    ...(item.exclusive && {
      border: '2px solid gold',
      boxShadow: '0 0 10px gold',
    }),
  };
  
  // Check if it's a YouTube URL
  const youtubeEmbedUrl = getYoutubeEmbedUrl(mediaUrl);
  if (youtubeEmbedUrl) {
    return (
      <iframe
        width="100%"
        height="315"
        src={youtubeEmbedUrl}
        title={customFileName}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        onLoad={handleLoad}
        style={commonStyles}
      ></iframe>
    );
  }

    switch (item.type) {
    case 'image':
      return (
        <img 
          src={mediaUrl} 
          alt={customFileName}
          style={commonStyles}
          onLoad={handleLoad}
          onError={handleError}
        />
      );
    case 'video':
      const youtubeEmbedUrl = getYoutubeEmbedUrl(mediaUrl);
      if (youtubeEmbedUrl) {
        return (
          <iframe
            width="100%"
            height="400"
            style={{ ...commonStyles, objectFit: 'contain' }}
            src={youtubeEmbedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={customFileName}
            onLoad={handleLoad}
          ></iframe>
        );
      }
      return (
        <video 
          src={mediaUrl} 
          controls 
          style={commonStyles}
          onLoadedData={handleLoad}
          onError={handleError}
        />
      );
      case 'audio':
        return (
          <audio 
            src={mediaUrl} 
            controls 
            style={{ width: '100%', marginTop: '20px' }}
            onLoadedData={handleLoad}
            onError={handleError}
          />
        );
      case 'application':
        if (item.filename && item.filename.endsWith('.pdf')) {
          return (
            <iframe 
              src={mediaUrl}
              style={{ 
                width: '100%', 
                height: '400px', 
                border: 'none', 
                borderRadius: '8px', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              title={customFileName}
              onLoad={handleLoad}
              onError={handleError}
            />
          );
        }
        return <Typography variant="body2">Document preview not available</Typography>;
      default:
        return <Typography variant="body2">No preview available for type: {item.type}</Typography>;
    }
  };

  return (
    <Box sx={{ 
      height: 'auto', 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center',
      padding: '16px',
      backgroundColor: item.exclusive ? '#fff9c4' : '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      position: 'relative',
    }}>
      {item.exclusive && (
        <Chip
          icon={<Star />}
          label="Exclusive"
          color="secondary"
          size="small"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        />
      )}
      <Typography variant="h6" gutterBottom align="center">
        {customFileName}
      </Typography>
      {loading && (
        <CircularProgress sx={{ marginBottom: '16px' }} />
      )}
      {error && (
        <Box sx={{ textAlign: 'center' }}>
          <Typography color="error" variant="h6" gutterBottom>{error}</Typography>
          <Typography variant="caption" display="block">URL: {mediaUrl}</Typography>
        </Box>
      )}
      {!loading && !error && mediaUrl && renderContent()}
    </Box>
  );
};

export default MediaPreview;
import React, { useState, useEffect, useCallback, useMemo} from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, Typography, LinearProgress, Box, List, ListItem, ListItemIcon, ListItemText,
   CircularProgress, Paper, Grid, useTheme, useMediaQuery, Alert
} from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const ProcessingDialog = ({ 
  open, 
  onClose, 
  processDocuments, 
  scrapeAndCreateDb,
  files, 
  settings, 
  databaseAction, 
  databaseName, 
  scrapingUrl,
  scrapingDbName,
  maxPages,
  maxTime,
  onProcessingComplete,
  processingType
}) => {
  const [progress, setProgress] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [processingStats, setProcessingStats] = useState({
    documentsProcessed: 0,
    chunksCreated: 0,
    embeddingsGenerated: 0,
    mediaExtracted: 0,
    processingSpeed: [],
    totalProcessingTime: 0
  });
  const [error, setError] = useState(null);
  const [lastMessage, setLastMessage] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const steps = useMemo(() => 
    processingType === 'documents' 
      ? [
          "Initializing",
          "Loading documents",
          "Splitting documents",
          "Extracting media",
          "Generating embeddings",
          "Creating database",
          "Finalizing",
        ]
      : [
          "Initializing",
          "Scraping website",
          "Processing content",
          "Extracting media",
          "Generating embeddings",
          "Creating database",
          "Finalizing",
        ],
    [processingType]
  );

  const updateStepAndStats = useCallback((data) => {
    const stepIndex = steps.findIndex(step => step.toLowerCase() === data.step.toLowerCase());
    if (stepIndex !== -1) {
      setCurrentStep(stepIndex);
    }

    setProcessingStats(prev => ({
      ...prev,
      documentsProcessed: data.documentsProcessed || prev.documentsProcessed,
      chunksCreated: data.chunksCreated || prev.chunksCreated,
      embeddingsGenerated: data.embeddingsGenerated || prev.embeddingsGenerated,
      mediaExtracted: data.mediaExtracted ? (prev.mediaExtracted || 0) + data.mediaExtracted : 
                      data.totalMediaExtracted || prev.mediaExtracted,
      processingSpeed: data.processingSpeed 
        ? [...(prev.processingSpeed || []), data.processingSpeed].slice(-50)
        : prev.processingSpeed,
      totalProcessingTime: data.processingTime || prev.totalProcessingTime
    }));

    if (data.message) {
      setProgress(prev => [...prev, { step: data.step, message: data.message }]);
      setLastMessage(data.message);
    }

    if (data.step === "Completed") {
      setIsProcessing(false);
      onProcessingComplete();
    }

    if (data.step === "Error") {
      setError(data.message);
      setIsProcessing(false);
    }
  }, [steps, onProcessingComplete]);

  useEffect(() => {
    let eventSource;

    const startProcessing = async () => {
      if (open && !isProcessing) {
        setIsProcessing(true);
        setProgress([]);
        setCurrentStep(0);
        setLastMessage('');
        setProcessingStats({
          documentsProcessed: 0,
          chunksCreated: 0,
          embeddingsGenerated: 0,
          mediaExtracted: 0,
          processingSpeed: [],
          totalProcessingTime: 0
        });

        try {
          if (processingType === 'documents') {
            const formData = new FormData();
            files.forEach(file => formData.append('files', file));
            formData.append('settings', JSON.stringify(settings));
            formData.append('database_action', databaseAction);
            formData.append('database_name', databaseName);
            await processDocuments(formData);
          } else {
            await scrapeAndCreateDb({
              url: scrapingUrl,
              database_name: scrapingDbName,
              max_pages: maxPages,
              max_time: maxTime,
              chunk_size: settings.chunk_size,
              chunk_overlap: settings.chunk_overlap,
              splitter_type: settings.splitter_type,
              embedding_model: settings.embedding_model
            });
          }

          eventSource = new EventSource(`${API_URL}/api/process-documents-stream`);

          eventSource.onmessage = (event) => {
            console.log("Raw SSE data:", event.data);
            try {
              const data = JSON.parse(event.data);
              console.log("Parsed SSE data:", data);
              if (data.message !== 'heartbeat') {
                updateStepAndStats(data);
              }
            } catch (error) {
              console.error("Error parsing SSE data:", error);
              setProgress(prev => [...prev, { step: "Error", message: `Error parsing data: ${error.message}` }]);
            }
          };

          eventSource.onerror = (error) => {
            console.error('SSE Error:', error);
            setProgress(prev => [...prev, { step: "Error", message: `Error: ${error.message || 'Unknown error'}` }]);
            setIsProcessing(false);
            eventSource.close();
          };
        } catch (error) {
          console.error('Processing error:', error);
          setProgress(prev => [...prev, { step: "Error", message: `Error: ${error.message || 'Unknown error'}` }]);
          setIsProcessing(false);
        }
      }
    };

    startProcessing();

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [open, processDocuments, scrapeAndCreateDb, files, settings, databaseAction, databaseName, scrapingUrl, scrapingDbName, maxPages, maxTime, updateStepAndStats, isProcessing, processingType]);

  const renderProcessingChart = () => {
    const data = {
      labels: processingStats.processingSpeed.map((_, index) => index + 1),
      datasets: [
        {
          label: 'Processing Speed',
          data: processingStats.processingSpeed,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
          tension: 0.1
        }
      ]
    };

  
      const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        }
      },
      scales: {
        y: {
          title: {
            display: false,
          },
          ticks: {
            display: !isMobile,
          }
        },
        x: {
          title: {
            display: false,
          },
          ticks: {
            display: !isMobile,
          }
        }
      }
    };

    return (
      <Box sx={{ height: isMobile ? 100 : 150 }}>
        <Line data={data} options={options} />
      </Box>
    );
  };

  const renderProcessingStats = () => (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary">
          {processingType === 'documents' ? 'Documents' : 'Pages'}
        </Typography>
        <Typography variant="h6">{processingStats.documentsProcessed}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary">Chunks</Typography>
        <Typography variant="h6">{processingStats.chunksCreated}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary">Embeddings</Typography>
        <Typography variant="h6">{processingStats.embeddingsGenerated}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary">Media Extracted</Typography>
        <Typography variant="h6">{processingStats.mediaExtracted}</Typography>
      </Grid>
    </Grid>
  );

  const handleClose = () => {
    if (!isProcessing) {
      setProgress([]);
      setError(null);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{processingType === 'documents' ? 'Processing Documents' : 'Scraping Website'}</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box sx={{ mb: 2 }}>
          <LinearProgress 
            variant={isProcessing ? "indeterminate" : "determinate"} 
            value={(currentStep / (steps.length - 1)) * 100} 
            sx={{ height: 10, borderRadius: 5 }}
          />
        </Box>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          Current action: {lastMessage}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>Steps</Typography>
              <List dense>
                {steps.map((step, index) => (
                  <ListItem key={index} sx={{ py: 0.5 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      {index < currentStep ? (
                        <CheckCircle color="success" fontSize="small" />
                      ) : index === currentStep ? (
                        <CircularProgress size={20} />
                      ) : (
                        <RadioButtonUnchecked color="disabled" fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText 
                      primary={step} 
                      primaryTypographyProps={{
                        color: index <= currentStep ? 'textPrimary' : 'textSecondary',
                        variant: 'body2',
                        fontWeight: index === currentStep ? 'bold' : 'normal'
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>Processing Stats</Typography>
                  {renderProcessingStats()}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>Processing Speed</Typography>
                  {renderProcessingChart()}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, maxHeight: isMobile ? 150 : 200, overflowY: 'auto' }}>
              <Typography variant="h6" gutterBottom>Progress Log</Typography>
              {progress.map((item, index) => (
                <Typography key={index} variant="body2" gutterBottom>
                  {item.message}
                </Typography>
              ))}
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isProcessing}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ProcessingDialog);
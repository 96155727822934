import React from 'react';
import { LinearProgress, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import chatConfig from './chatConfig';

const pulseKeyframe = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const glowKeyframe = keyframes`
  0% {
    box-shadow: 0 0 5px ${chatConfig.loadingIndicator.glow.color};
  }
  50% {
    box-shadow: 0 0 20px ${chatConfig.loadingIndicator.glow.color};
  }
  100% {
    box-shadow: 0 0 5px ${chatConfig.loadingIndicator.glow.color};
  }
`;

const slideKeyframe = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => {
  const { loadingIndicator: config } = chatConfig;
  
  
  let gradientStyle;
  if (config.gradient.type === 'linear') {
    gradientStyle = `linear-gradient(${config.gradient.direction}, ${config.gradient.colors.join(', ')})`;
  } else {
    gradientStyle = `radial-gradient(circle, ${config.gradient.colors.join(', ')})`;
  }

  let animationStyle;
  switch (config.animation.effect) {
    case 'pulse':
      animationStyle = `${pulseKeyframe} ${config.animation.duration} ${config.animation.timingFunction} infinite`;
      break;
    case 'glow':
      animationStyle = `${glowKeyframe} ${config.animation.duration} ${config.animation.timingFunction} infinite`;
      break;
    case 'slide':
      animationStyle = `${slideKeyframe} ${config.animation.duration} ${config.animation.timingFunction} infinite`;
      break;
    default:
      animationStyle = 'none';
  }

  return {
    height: config.height,
    backgroundColor: config.backgroundColor,
    borderRadius: config.borderRadius,
    boxShadow: config.boxShadow,
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: gradientStyle,
      backgroundSize: '200% 100%',
      animation: animationStyle,
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: 'transparent',
    },
  };
});

const LoadingIndicator = ({ style }) => {
  return (
    <Box style={style}>
      <StyledLinearProgress variant={chatConfig.loadingIndicator.animation.variant} />
    </Box>
  );
};

export default LoadingIndicator;
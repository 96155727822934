import React from 'react';
import { Box, TextField, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateRangePresets from './DateRangePresets';

const DatePickerSection = ({
  isMobile,
  startDate,
  endDate,
  handleDateRangeChange,
  theme,
  activePreset
}) => {
  return (
    <Box>
      <Stack direction={isMobile ? "column" : "row"} spacing={7} alignItems="center" mb={2}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => handleDateRangeChange(newValue, endDate)}
          renderInput={(params) => (
            <TextField 
              {...params} 
              size="large"
              fullWidth={isMobile}
              sx={{ width: isMobile ? '100%' : 'auto' }}
            />
          )}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => handleDateRangeChange(startDate, newValue)}
          renderInput={(params) => (
            <TextField 
              {...params} 
              size="large"
              fullWidth={isMobile}
              sx={{ width: isMobile ? '100%' : 'auto' }}
            />
          )}
        />
      </Stack>
      <DateRangePresets 
        handleDateRangeChange={handleDateRangeChange}
        activePreset={activePreset}
        isMobile={isMobile}
      />
      
    </Box>
  );
};

export default DatePickerSection;
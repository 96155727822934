import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, Button, TextField, Grid, Card, CardContent,
  IconButton, Chip, Snackbar, Alert, Dialog, DialogTitle,
  DialogContent, DialogActions, FormControl, InputLabel, Select,
  MenuItem, AppBar, Toolbar, InputAdornment,
  Checkbox, FormControlLabel, Paper, Divider, Container,
  Badge, Tooltip, CardActions, CardMedia, Fade, Menu, ListItemText
} from '@mui/material';
import {
  Delete, Edit, Upload, Link, Search,
    CloudUpload,
  Star, Visibility, FilterList
} from '@mui/icons-material';
import { uploadMedia, getAllMedia, updateMediaItem, deleteMedia, addMediaUrl } from '../services/api';
import MediaPreview from './MediaPreview';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';


const isYouTubeUrl = (url) => {
  return url && (url.includes('youtube.com') || url.includes('youtu.be'));
};


const getYouTubeEmbedUrl = (url) => {
  if (!url) return null;
  let videoId;
  if (url.includes('youtu.be')) {
    videoId = url.split('/').pop().split('?')[0];
  } else if (url.includes('youtube.com')) {
    const urlParams = new URLSearchParams(new URL(url).search);
    videoId = urlParams.get('v');
  }
  return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
};



const MediaManager = () => {
  const [mediaItems, setMediaItems] = useState([]);
  const [filteredMediaItems, setFilteredMediaItems] = useState([]);
  const [currentMediaItem, setCurrentMediaItem] = useState(null);

  const [file, setFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [originalFileName, setOriginalFileName] = useState('');
  const [fileType, setFileType] = useState('');

  const [url, setUrl] = useState('');
  const [mediaType, setMediaType] = useState('image');

  const [keywords, setKeywords] = useState('');
  
  const [exclusive, setExclusive] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [mediaToDelete, setMediaToDelete] = useState(null);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    exclusive: false,
    fileTypes: []
  });

  useEffect(() => {
    fetchMedia();
  }, []);



  const fetchMedia = async () => {
    try {
      const media = await getAllMedia();
      setMediaItems(media);
    } catch (error) {
      setError('Failed to fetch media items: ' + (error.message || 'Unknown error'));
    }
  };

  const filterMedia = useCallback(() => {
    let filtered = mediaItems;
    if (selectedFilters.exclusive) {
      filtered = filtered.filter(item => item.exclusive);
    }
    if (selectedFilters.fileTypes.length > 0) {
      filtered = filtered.filter(item => selectedFilters.fileTypes.includes(item.file_type || item.type));
    }
    if (searchTerm) {
      filtered = filtered.filter(item => 
        item.original_filename?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.keywords?.some(keyword => keyword.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    setFilteredMediaItems(filtered);
  }, [mediaItems, selectedFilters, searchTerm]);

  useEffect(() => {
    filterMedia();
  }, [filterMedia]);
  
  

  const handleFilterChange = (filterType, value) => {
    setSelectedFilters(prev => {
      if (filterType === 'exclusive') {
        return { ...prev, exclusive: value };
      } else if (filterType === 'fileType') {
        const updatedFileTypes = prev.fileTypes.includes(value)
          ? prev.fileTypes.filter(type => type !== value)
          : [...prev.fileTypes, value];
        return { ...prev, fileTypes: updatedFileTypes };
      }
      return prev;
    });
  };

  const fileTypes = ['PDF', 'Word', 'Excel', 'PowerPoint', 'Image', 'Video', 'Audio', 'Other'];


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFileName(selectedFile ? selectedFile.name : '');
  };

  const handleUpload = async () => {
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('keywords', keywords);
    formData.append('exclusive', exclusive);
    formData.append('file_type', fileType);
    
    try {
      await uploadMedia(formData);
      setFile(null);
      setSelectedFileName('');
      setKeywords('');
      setExclusive(false);
      setFileType('');
      setSuccess('Media uploaded successfully');
      fetchMedia();
    } catch (error) {
      setError('Failed to upload media: ' + (error.message || 'Unknown error'));
    }
  };

  const handleAddUrl = async () => {
  try {
    const newItem = await addMediaUrl({ 
      url, 
      type: isYouTubeUrl(url) ? 'video' : mediaType,
      file_type: isYouTubeUrl(url) ? 'Video' : fileType,
        keywords: keywords.split(','), 
        exclusive,
        original_filename: originalFileName,
        
      });
      
      setMediaItems(prevItems => [...prevItems, newItem]);
      setUrl('');
      setMediaType('image');
      setKeywords('');
      setExclusive(false);
      setOriginalFileName('');
      setFileType('');
      setOpenDialog(false);
      setSuccess('Media URL added successfully');
      await fetchMedia();
    } catch (error) {
      setError(`Failed to add media URL: ${error.message || 'Unknown error'}`);
    }
  };

   const handleDeleteConfirm = useCallback((media) => {
    setMediaToDelete(media);
    setDeleteConfirmOpen(true);
  }, []);

  const handleDelete = async () => {
    if (!mediaToDelete || !mediaToDelete.id) {
      setError('Invalid media item');
      setDeleteConfirmOpen(false);
      return;
    }
    try {
      const result = await deleteMedia(mediaToDelete.id);
      setSuccess(result.message || 'Media deleted successfully');
      fetchMedia();
    } catch (error) {
      setError(`Failed to delete media: ${error.message}`);
    } finally {
      setDeleteConfirmOpen(false);
      setMediaToDelete(null);
    }
  };

  const handleOpenEditDialog = useCallback((media) => {
    setCurrentMediaItem({
      ...media,
      keywords: media.keywords.join(', '),
      exclusive: media.exclusive || false
    });
    setEditDialogOpen(true);
  }, []);

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setCurrentMediaItem(null);
  };

  const handleUpdateMedia = async () => {
  try {
    if (!currentMediaItem || !currentMediaItem.id) {
      throw new Error('Invalid media item');
    }
    
    const isYoutube = isYouTubeUrl(currentMediaItem.url);
    
    const updatedMedia = {
      original_filename: currentMediaItem.original_filename,
      url: currentMediaItem.url,
      file_type: isYoutube ? 'Video' : currentMediaItem.file_type,
      type: isYoutube ? 'video' : currentMediaItem.type,
      keywords: Array.isArray(currentMediaItem.keywords) 
        ? currentMediaItem.keywords 
        : currentMediaItem.keywords.split(',').map(k => k.trim()),
      exclusive: currentMediaItem.exclusive
    };
    
    // If it's a YouTube URL, ensure we have a valid video ID
    if (isYoutube) {
      const embedUrl = getYouTubeEmbedUrl(currentMediaItem.url);
      if (!embedUrl) {
        throw new Error('Invalid YouTube URL');
      }
    }
    
    await updateMediaItem(currentMediaItem.id, updatedMedia);
    setSuccess('Media item updated successfully');
    fetchMedia();
    handleCloseEditDialog();
  } catch (error) {
    setError('Failed to update media item: ' + (error.response?.data?.detail || error.message));
  }
};

  const renderMediaItems = useCallback(() => (
    <Grid container spacing={2}>
      {filteredMediaItems.map((item) => {
        const displayName = item.original_filename || item.custom_filename || 'Untitled';
        const fileType = item.file_type || item.type || 'Unknown';

        return (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Fade in={true} timeout={500}>
              <Card 
                elevation={3}
                sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                },
                ...(item.exclusive && {
                  border: '2px solid gold',
                  boxShadow: '0 0 10px gold',
                }),
              }}
            >
              {item.exclusive && (
                <Tooltip title="Exclusive Content">
                  <Badge
                    badgeContent={<Star fontSize="small" />}
                    color="secondary"
                    sx={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      zIndex: 1,
                    }}
                  />
                </Tooltip>
              )}
              <CardMedia
                component="div"
                sx={{
                  pt: '0.25%', // 16:9 aspect ratio
                  position: 'relative',
                }}
              >
                <MediaPreview item={item} />
              </CardMedia>
              <CardContent sx={{ flexGrow: 1, py: 1, px: 2 }}>
                <Typography variant="subtitle1" gutterBottom noWrap sx={{ fontWeight: 'bold', mb: 0.5 }}>
                  {displayName}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                  Type: {fileType}
                </Typography>
                <Typography variant="body2" color="text.secondary" noWrap sx={{ mb: 0.5 }}>
                  {item.url ? `URL: ${item.url}` : `File: ${item.filename}`}
                </Typography>
                <Box sx={{ mt: 0.5 }}>
                  {item.keywords && item.keywords.map((keyword, index) => (
                    <Chip 
                      key={index} 
                      label={keyword} 
                      size="small" 
                      sx={{ mr: 0.5, mb: 0.5 }} 
                      color={item.exclusive ? "secondary" : "default"}
                    />
                  ))}
                </Box>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', p: 1 }}>
                  <IconButton onClick={() => handleOpenEditDialog(item)} color="primary" size="small">
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteConfirm(item)} color="error" size="small">
                    <Delete />
                  </IconButton>
                  <IconButton 
                    onClick={() => window.open(item.url || `${API_URL}/api/media/${item.filename}`, '_blank')}
                    color="primary"
                    size="small"
                  >
                    <Visibility />
                  </IconButton>
                </CardActions>
              </Card>
            </Fade>
          </Grid>
        );
      })}
    </Grid>
  ), [filteredMediaItems, handleOpenEditDialog, handleDeleteConfirm]);

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', pb: 4 }}>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Typography variant="h4" component="div" sx={{ flexGrow: 1, fontWeight: 'normal' }}>
            Media Manager
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              variant="outlined"
              placeholder="Search media..."
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <Tooltip title="Filter">
              <IconButton onClick={(e) => setFilterAnchorEl(e.currentTarget)}>
                <FilterList />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>Upload New Media</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <input
                accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="raised-button-file">
                <Button 
                  variant="outlined" 
                  component="span" 
                  startIcon={<CloudUpload />} 
                  fullWidth
                >
                  Choose File
                </Button>
              </label>
              {selectedFileName && (
                <Typography variant="caption" display="block" gutterBottom>
                  Selected: {selectedFileName}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>File Type</InputLabel>
                <Select
                  value={fileType}
                  onChange={(e) => setFileType(e.target.value)}
                  label="File Type"
                >
                  <MenuItem value="PDF">PDF</MenuItem>
                  <MenuItem value="Word">Word Document</MenuItem>
                  <MenuItem value="Excel">Excel Spreadsheet</MenuItem>
                  <MenuItem value="PowerPoint">PowerPoint</MenuItem>
                  <MenuItem value="Image">Image</MenuItem>
                  <MenuItem value="Video">Video</MenuItem>
                  <MenuItem value="Audio">Audio</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                placeholder="Keywords (comma-separated)"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exclusive}
                    onChange={(e) => setExclusive(e.target.checked)}
                    name="exclusive"
                  />
                }
                label="Exclusive"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button 
                onClick={handleUpload} 
                disabled={!file || !fileType} 
                variant="contained" 
                fullWidth 
                startIcon={<Upload />}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Button 
            onClick={() => setOpenDialog(true)} 
            variant="outlined" 
            startIcon={<Link />}
          >
            Add Media URL
          </Button>
        </Paper>

        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" gutterBottom>Media Library</Typography>
          <Typography variant="body2" color="text.secondary">
            Total items: {filteredMediaItems.length} | Exclusive items: {filteredMediaItems.filter(item => item.exclusive).length}
          </Typography>
        </Box>

        {renderMediaItems()}
      </Container>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Media URL</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="URL"
            type="url"
            fullWidth
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Original File Name"
            fullWidth
            value={originalFileName}
            onChange={(e) => setOriginalFileName(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>File Type</InputLabel>
            <Select
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              label="File Type"
            >
              <MenuItem value="PDF">PDF</MenuItem>
              <MenuItem value="Word">Word Document</MenuItem>
              <MenuItem value="Excel">Excel Spreadsheet</MenuItem>
              <MenuItem value="PowerPoint">PowerPoint</MenuItem>
              <MenuItem value="Image">Image</MenuItem>
              <MenuItem value="Video">Video</MenuItem>
              <MenuItem value="Audio">Audio</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Keywords (comma-separated)"
            fullWidth
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={exclusive}
                onChange={(e) => setExclusive(e.target.checked)}
                name="exclusive"
              />
            }
            label="Exclusive"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddUrl} variant="contained" disabled={!url || !fileType}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this media item?</Typography>
          {mediaToDelete && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              {mediaToDelete.original_filename || mediaToDelete.url || mediaToDelete.filename}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog} maxWidth="md" fullWidth>
        <DialogTitle>Edit Media Item</DialogTitle>
        <DialogContent>
          {currentMediaItem && (
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Original Filename"
                  value={currentMediaItem.original_filename || ''}
                  onChange={(e) => setCurrentMediaItem({...currentMediaItem, original_filename: e.target.value})}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="URL"
                  value={currentMediaItem.url || ''}
                  onChange={(e) => setCurrentMediaItem({...currentMediaItem, url: e.target.value})}
                  margin="normal"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>File Type</InputLabel>
                  <Select
                    value={currentMediaItem.file_type || ''}
                    onChange={(e) => setCurrentMediaItem({...currentMediaItem, file_type: e.target.value})}
                    label="File Type"
                  >
                    <MenuItem value="PDF">PDF</MenuItem>
                    <MenuItem value="Word">Word Document</MenuItem>
                    <MenuItem value="Excel">Excel Spreadsheet</MenuItem>
                    <MenuItem value="PowerPoint">PowerPoint</MenuItem>
                    <MenuItem value="Image">Image</MenuItem>
                    <MenuItem value="Video">Video</MenuItem>
                    <MenuItem value="Audio">Audio</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Keywords (comma-separated)"
                  value={currentMediaItem.keywords}
                  onChange={(e) => setCurrentMediaItem({...currentMediaItem, keywords: e.target.value})}
                  margin="normal"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={currentMediaItem.exclusive}
                      onChange={(e) => setCurrentMediaItem({...currentMediaItem, exclusive: e.target.checked})}
                      name="exclusive"
                    />
                  }
                  label="Exclusive"
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1">Preview:</Typography>
                  <MediaPreview item={currentMediaItem} />
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleUpdateMedia} color="primary" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={() => setFilterAnchorEl(null)}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedFilters.exclusive}
                onChange={(e) => handleFilterChange('exclusive', e.target.checked)}
              />
            }
            label="Exclusive only"
          />
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <ListItemText primary="File Types" />
        </MenuItem>
        {fileTypes.map((type) => (
          <MenuItem key={type} dense>
            <Checkbox
              checked={selectedFilters.fileTypes.includes(type)}
              onChange={() => handleFilterChange('fileType', type)}
            />
            <ListItemText primary={type} />
          </MenuItem>
        ))}
      </Menu>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MediaManager;
import * as Icons from '@mui/icons-material';

// Helper function to safely get icons
const safeIcon = (iconName) => Icons[iconName] || null;

export const selectedIcons = {
  // General icons
  Link: safeIcon('Link'),
  Email: safeIcon('Email'),
  Phone: safeIcon('Phone'),
  Home: safeIcon('Home'),
  Info: safeIcon('Info'),
  Star: safeIcon('Star'),
  Chat: safeIcon('Chat'),
  Share: safeIcon('Share'),
  Download: safeIcon('Download'),
  Upload: safeIcon('Upload'),
  ContentCopy: safeIcon('ContentCopy'),
  Message: safeIcon('Message'),
  Favorite: safeIcon('Favorite'),
  Settings: safeIcon('Settings'),
  Help: safeIcon('Help'),
  ShoppingCart: safeIcon('ShoppingCart'),
  Search: safeIcon('Search'),
  Notifications: safeIcon('Notifications'),
  AccountCircle: safeIcon('AccountCircle'),
  CalendarToday: safeIcon('CalendarToday'),
  ThumbUp: safeIcon('ThumbUp'),
  ThumbDown: safeIcon('ThumbDown'),
  Directions: safeIcon('Directions'),
  Warning: safeIcon('Warning'),
  Error: safeIcon('Error'),
  CheckCircle: safeIcon('CheckCircle'),
  Save: safeIcon('Save'),
  Print: safeIcon('Print'),
  Add: safeIcon('Add'),
  Remove: safeIcon('Remove'),
  Edit: safeIcon('Edit'),
  Delete: safeIcon('Delete'),
  KeyboardVoice: safeIcon('KeyboardVoice'),
  Language: safeIcon('Language'),
  Translate: safeIcon('Translate'),
  ArrowBack: safeIcon('ArrowBack'),
  ArrowForward: safeIcon('ArrowForward'),
  Refresh: safeIcon('Refresh'),
  MoreVert: safeIcon('MoreVert'),
  Dashboard: safeIcon('Dashboard'),
  Fullscreen: safeIcon('Fullscreen'),
  FullscreenExit: safeIcon('FullscreenExit'),
  ZoomIn: safeIcon('ZoomIn'),
  ZoomOut: safeIcon('ZoomOut'),
  FilterList: safeIcon('FilterList'),
  Sort: safeIcon('Sort'),
  ViewList: safeIcon('ViewList'),
  ViewModule: safeIcon('ViewModule'),
  ViewQuilt: safeIcon('ViewQuilt'),
  ToggleOn: safeIcon('ToggleOn'),
  ToggleOff: safeIcon('ToggleOff'),
  Lock: safeIcon('Lock'),
  LockOpen: safeIcon('LockOpen'),
  Visibility: safeIcon('Visibility'),
  VisibilityOff: safeIcon('VisibilityOff'),
  

  // Hospitality and Tourism
  Restaurant: safeIcon('Restaurant'),
  LocalCafe: safeIcon('LocalCafe'),
  LocalBar: safeIcon('LocalBar'),
  Hotel: safeIcon('Hotel'),
  LocalAirport: safeIcon('LocalAirport'),
  Flight: safeIcon('Flight'),
  BeachAccess: safeIcon('BeachAccess'),
  Pool: safeIcon('Pool'),
  Spa: safeIcon('Spa'),
  Attractions: safeIcon('Attractions'),
  LocalActivity: safeIcon('LocalActivity'),
  Museum: safeIcon('Museum'),
  RoomService: safeIcon('RoomService'),
  Luggage: safeIcon('Luggage'),
  EmojiTransportation: safeIcon('EmojiTransportation'),
  Tour: safeIcon('Tour'),
  KingBed: safeIcon('KingBed'),
  NightShelter: safeIcon('NightShelter'),
  Surfing: safeIcon('Surfing'),
  Apartment: safeIcon('Apartment'),
  BungalowOutlined: safeIcon('BungalowOutlined'),
  CabinOutlined: safeIcon('CabinOutlined'),
  Casino: safeIcon('Casino'),
  HolidayVillage: safeIcon('HolidayVillage'),
  HotTub: safeIcon('HotTub'),
  Parasailing: safeIcon('Parasailing'),
  Sailing: safeIcon('Sailing'),
  SportsGolf: safeIcon('SportsGolf'),
  TravelExplore: safeIcon('TravelExplore'),

  // Business and Work
  BusinessCenter: safeIcon('BusinessCenter'),
  MeetingRoom: safeIcon('MeetingRoom'),
  Work: safeIcon('Work'),
  People: safeIcon('People'),
  Group: safeIcon('Group'),
  Event: safeIcon('Event'),
  Assignment: safeIcon('Assignment'),
  Description: safeIcon('Description'),
  Folder: safeIcon('Folder'),
  Schedule: safeIcon('Schedule'),
  PresentToAll: safeIcon('PresentToAll'),
  CorporateFare: safeIcon('CorporateFare'),
  Assessment: safeIcon('Assessment'),
  BarChart: safeIcon('BarChart'),
  Timeline: safeIcon('Timeline'),
  TrendingUp: safeIcon('TrendingUp'),
  TrendingDown: safeIcon('TrendingDown'),
  PieChart: safeIcon('PieChart'),
  Inventory: safeIcon('Inventory'),
  Insights: safeIcon('Insights'),
  Leaderboard: safeIcon('Leaderboard'),
  GroupWork: safeIcon('GroupWork'),
  Handshake: safeIcon('Handshake'),
  PersonAdd: safeIcon('PersonAdd'),
  ContentPaste: safeIcon('ContentPaste'),
  FileCopy: safeIcon('FileCopy'),
  Approval: safeIcon('Approval'),

  // Transportation
  LocationOn: safeIcon('LocationOn'),
  Map: safeIcon('Map'),
  Explore: safeIcon('Explore'),
  Place: safeIcon('Place'),
  Navigation: safeIcon('Navigation'),
  DirectionsCar: safeIcon('DirectionsCar'),
  LocalTaxi: safeIcon('LocalTaxi'),
  Train: safeIcon('Train'),
  DirectionsBus: safeIcon('DirectionsBus'),
  Subway: safeIcon('Subway'),
  Tram: safeIcon('Tram'),
  LocalShipping: safeIcon('LocalShipping'),
  ElectricCar: safeIcon('ElectricCar'),
  ElectricBike: safeIcon('ElectricBike'),
  ElectricScooter: safeIcon('ElectricScooter'),
  Motorcycle: safeIcon('Motorcycle'),
  Pedalbike: safeIcon('Pedalbike'),
  DirectionsBoat: safeIcon('DirectionsBoat'),
  AirplanemodeActive: safeIcon('AirplanemodeActive'),
  LocalParking: safeIcon('LocalParking'),
  TrafficOutlined: safeIcon('TrafficOutlined'),
  EvStation: safeIcon('EvStation'),
  LocalGasStation: safeIcon('LocalGasStation'),
  CarRental: safeIcon('CarRental'),
  CarRepair: safeIcon('CarRepair'),

  // Sports and Recreation
  SportsBasketball: safeIcon('SportsBasketball'),
  SportsSoccer: safeIcon('SportsSoccer'),
  SportsTennis: safeIcon('SportsTennis'),
  SportsFootball: safeIcon('SportsFootball'),
  SportsVolleyball: safeIcon('SportsVolleyball'),
  SportsRugby: safeIcon('SportsRugby'),
  SportsGolf: safeIcon('SportsGolf'),
  SportsHockey: safeIcon('SportsHockey'),
  SportsCricket: safeIcon('SportsCricket'),
  SportsEsports: safeIcon('SportsEsports'),
  FitnessCenter: safeIcon('FitnessCenter'),
  SportsKabaddi: safeIcon('SportsKabaddi'),
  SportsHandball: safeIcon('SportsHandball'),
  SportsMma: safeIcon('SportsMma'),
  SportsMotorsports: safeIcon('SportsMotorsports'),
  PoolOutlined: safeIcon('PoolOutlined'),
  Skateboarding: safeIcon('Skateboarding'),
  Snowboarding: safeIcon('Snowboarding'),
  Surfing: safeIcon('Surfing'),
  SportsMartialArts: safeIcon('SportsMartialArts'),

  // Food and Dining
  Fastfood: safeIcon('Fastfood'),
  LocalPizza: safeIcon('LocalPizza'),
  LocalDining: safeIcon('LocalDining'),
  FreeBreakfast: safeIcon('FreeBreakfast'),
  Bakery: safeIcon('Bakery'),
  IcecreamOutlined: safeIcon('IcecreamOutlined'),
  Coffee: safeIcon('Coffee'),
  LocalDrink: safeIcon('LocalDrink'),
  SetMeal: safeIcon('SetMeal'),
  Takeout: safeIcon('Takeout'),
  DinnerDining: safeIcon('DinnerDining'),
  LunchDining: safeIcon('LunchDining'),
  BrunchDining: safeIcon('BrunchDining'),
  LocalPizzaOutlined: safeIcon('LocalPizzaOutlined'),
  LocalCafeOutlined: safeIcon('LocalCafeOutlined'),
  RestaurantMenu: safeIcon('RestaurantMenu'),
  DeliveryDining: safeIcon('DeliveryDining'),
  TapasOutlined: safeIcon('TapasOutlined'),
  EmojiFoodBeverage: safeIcon('EmojiFoodBeverage'),
  Blender: safeIcon('Blender'),

  // Outdoor and Nature
  Landscape: safeIcon('Landscape'),
  Park: safeIcon('Park'),
  Forest: safeIcon('Forest'),
  Terrain: safeIcon('Terrain'),
  WbSunny: safeIcon('WbSunny'),
  Waves: safeIcon('Waves'),
  AcUnit: safeIcon('AcUnit'),
  Thunderstorm: safeIcon('Thunderstorm'),
  EmojiNature: safeIcon('EmojiNature'),
  Fireplace: safeIcon('Fireplace'),
  BeachAccess: safeIcon('BeachAccess'),
  CloudOutlined: safeIcon('CloudOutlined'),
  WbCloudy: safeIcon('WbCloudy'),
  Umbrella: safeIcon('Umbrella'),
  Eco: safeIcon('Eco'),
  Grass: safeIcon('Grass'),
  LocalFlorist: safeIcon('LocalFlorist'),
  Pets: safeIcon('Pets'),
  Water: safeIcon('Water'),
  WindPower: safeIcon('WindPower'),

  // Entertainment
  LocalMovies: safeIcon('LocalMovies'),
  TheaterComedy: safeIcon('TheaterComedy'),
  MusicNote: safeIcon('MusicNote'),
  Casino: safeIcon('Casino'),
  SportsBar: safeIcon('SportsBar'),
  Celebration: safeIcon('Celebration'),
  Nightlife: safeIcon('Nightlife'),
  Theaters: safeIcon('Theaters'),
  LiveTv: safeIcon('LiveTv'),
  Mic: safeIcon('Mic'),
  QueueMusic: safeIcon('QueueMusic'),
  SportsEsports: safeIcon('SportsEsports'),
  Videocam: safeIcon('Videocam'),
  MovieFilter: safeIcon('MovieFilter'),
  Games: safeIcon('Games'),
  Headphones: safeIcon('Headphones'),
  Karaoke: safeIcon('Karaoke'),
  Carousel: safeIcon('Carousel'),
  FestivalOutlined: safeIcon('FestivalOutlined'),
  PartyMode: safeIcon('PartyMode'),

  // Shopping
  LocalMall: safeIcon('LocalMall'),
  Store: safeIcon('Store'),
  ShoppingBasket: safeIcon('ShoppingBasket'),
  AddShoppingCart: safeIcon('AddShoppingCart'),
  Storefront: safeIcon('Storefront'),
  Receipt: safeIcon('Receipt'),
  LocalOffer: safeIcon('LocalOffer'),
  Redeem: safeIcon('Redeem'),
  ShoppingBag: safeIcon('ShoppingBag'),
  Loyalty: safeIcon('Loyalty'),
  LocalConvenienceStore: safeIcon('LocalConvenienceStore'),
  LocalGroceryStore: safeIcon('LocalGroceryStore'),
  Checkroom: safeIcon('Checkroom'),
  Inventory2: safeIcon('Inventory2'),
  SellOutlined: safeIcon('SellOutlined'),
  AddCard: safeIcon('AddCard'),
  Discount: safeIcon('Discount'),
  QrCodeScanner: safeIcon('QrCodeScanner'),
  Barcode: safeIcon('Barcode'),

  // Health and Wellness
  Fitness: safeIcon('Fitness'),
  LocalHospital: safeIcon('LocalHospital'),
  Healing: safeIcon('Healing'),
  MedicalServices: safeIcon('MedicalServices'),
  Medication: safeIcon('Medication'),
  Psychology: safeIcon('Psychology'),
  SelfImprovement: safeIcon('SelfImprovement'),
  HealthAndSafety: safeIcon('HealthAndSafety'),
  MonitorHeart: safeIcon('MonitorHeart'),
  Bloodtype: safeIcon('Bloodtype'),
  Vaccines: safeIcon('Vaccines'),
  Coronavirus: safeIcon('Coronavirus'),
  LocalPharmacy: safeIcon('LocalPharmacy'),
  SpaOutlined: safeIcon('SpaOutlined'),
  Wheelchair: safeIcon('Wheelchair'),
  ElderlyOutlined: safeIcon('ElderlyOutlined'),
  PregnantWoman: safeIcon('PregnantWoman'),
  ChildCare: safeIcon('ChildCare'),

  // Technology
  AppIcon: safeIcon('Apps'),
  Wifi: safeIcon('Wifi'),
  Computer: safeIcon('Computer'),
  Smartphone: safeIcon('Smartphone'),
  Tablet: safeIcon('Tablet'),
  Storage: safeIcon('Storage'),
  Cloud: safeIcon('Cloud'),
  Code: safeIcon('Code'),
  Security: safeIcon('Security'),
  VpnKey: safeIcon('VpnKey'),
  DataUsage: safeIcon('DataUsage'),
  Devices: safeIcon('Devices'),
  DeveloperMode: safeIcon('DeveloperMode'),
  Memory: safeIcon('Memory'),
  SdStorage: safeIcon('SdStorage'),
  Usb: safeIcon('Usb'),
  Bluetooth: safeIcon('Bluetooth'),
  Cast: safeIcon('Cast'),
  Router: safeIcon('Router'),
  PhoneIphone: safeIcon('PhoneIphone'),
  KeyboardAlt: safeIcon('KeyboardAlt'),

  // Payment and Finance
  CreditCard: safeIcon('CreditCard'),
  AttachMoney: safeIcon('AttachMoney'),
  AccountBalance: safeIcon('AccountBalance'),
  Payments: safeIcon('Payments'),
  Receipt: safeIcon('Receipt'),
  MonetizationOn: safeIcon('MonetizationOn'),
  AccountBalanceWallet: safeIcon('AccountBalanceWallet'),
  PriceCheck: safeIcon('PriceCheck'),
  Currency: safeIcon('CurrencyExchange'),
  Savings: safeIcon('Savings'),
  RequestQuote: safeIcon('RequestQuote'),
  ReceiptLong: safeIcon('ReceiptLong'),
  AccountBalanceOutlined: safeIcon('AccountBalanceOutlined'),
  CreditScore: safeIcon('CreditScore'),
  LocalAtm: safeIcon('LocalAtm'),
  PriceChange: safeIcon('PriceChange'),
  Paid: safeIcon('Paid'),
  PointOfSale: safeIcon('PointOfSale'),
  Exchange: safeIcon('SwapHoriz'),  // Horizontal swap icon, good for representing exchange
  Change: safeIcon('ChangeCircle'),  // Circle with an arrow, good for representing change
  CurrencyExchange: safeIcon('CurrencyExchange'),  // Specific icon for currency exchange
  Sync: safeIcon('Sync'),  // Circular arrows, another way to represent change or exchange
  Transform: safeIcon('Transform'),  // For more abstract transformations or changes


  // Education
  School: safeIcon('School'),
  Book: safeIcon('Book'),
  MenuBook: safeIcon('MenuBook'),
  LibraryBooks: safeIcon('LibraryBooks'),
  Quiz: safeIcon('Quiz'),
  CastForEducation: safeIcon('CastForEducation'),
  Science: safeIcon('Science'),
  Architecture: safeIcon('Architecture'),
  HistoryEdu: safeIcon('HistoryEdu'),
  Psychology: safeIcon('Psychology'),
  Calculate: safeIcon('Calculate'),
  FunctionsTwoTone: safeIcon('FunctionsTwoTone'),
  Biotech: safeIcon('Biotech'),
  AccountTree: safeIcon('AccountTree'),
  EmojiObjects: safeIcon('EmojiObjects'),
  School: safeIcon('School'),
  AutoStories: safeIcon('AutoStories'),
  Language: safeIcon('Language'),
  ScienceOutlined: safeIcon('ScienceOutlined'),
  DesignServices: safeIcon('DesignServices'),

  // Communication
  Forum: safeIcon('Forum'),
  QuestionAnswer: safeIcon('QuestionAnswer'),
  ChatBubble: safeIcon('ChatBubble'),
  ContactSupport: safeIcon('ContactSupport'),
  Feedback: safeIcon('Feedback'),
  RateReview: safeIcon('RateReview'),
  Campaign: safeIcon('Campaign'),
  Call: safeIcon('Call'),
  VideoCall: safeIcon('VideoCall'),
  VoiceChat: safeIcon('VoiceChat'),
  Chat: safeIcon('Chat'),
  Mail: safeIcon('Mail'),
  ContactMail: safeIcon('ContactMail'),
  AlternateEmail: safeIcon('AlternateEmail'),
  Textsms: safeIcon('Textsms'),
  RssFeed: safeIcon('RssFeed'),
  Send: safeIcon('Send'),
  Podcasts: safeIcon('Podcasts'),
  LiveHelp: safeIcon('LiveHelp'),

  // Social Media
  Facebook: safeIcon('Facebook'),
  Twitter: safeIcon('Twitter'),
  Instagram: safeIcon('Instagram'),
  LinkedIn: safeIcon('LinkedIn'),
  YouTube: safeIcon('YouTube'),
  Pinterest: safeIcon('Pinterest'),
  Reddit: safeIcon('Reddit'),
  WhatsApp: safeIcon('WhatsApp'),
  TripAdvisor: safeIcon('TravelExplore'),
  TikTok: safeIcon('MusicVideo'),
  Snapchat: safeIcon('PhotoCamera'),
  Telegram: safeIcon('Send'),
  Twitch: safeIcon('Visibility'),
  Discord: safeIcon('Headset'),
  Slack: safeIcon('Forum'),
  Medium: safeIcon('Description'),
  Tumblr: safeIcon('Web'),
  Vimeo: safeIcon('Videocam'),

  // Weather
  WbSunny: safeIcon('WbSunny'),
  Cloud: safeIcon('Cloud'),
  Thunderstorm: safeIcon('Thunderstorm'),
  AcUnit: safeIcon('AcUnit'),
  WaterDrop: safeIcon('WaterDrop'),
  Air: safeIcon('Air'),
  Thermostat: safeIcon('Thermostat'),
  Tornado: safeIcon('Tornado'),
  WbCloudy: safeIcon('WbCloudy'),
  WbTwilight: safeIcon('WbTwilight'),
  Foggy: safeIcon('Foggy'),
  Grain: safeIcon('Grain'),
  Waves: safeIcon('Waves'),
  Umbrella: safeIcon('Umbrella'),
  
  // New Animal icons
  Pets: safeIcon('Pets'),
  Cruelty: safeIcon('CrueltyFree'),
  BugReport: safeIcon('BugReport'),
  Butterfly: safeIcon('ButterflyOutlined'),
  Cat: safeIcon('Catamaran'),
  Dog: safeIcon('Dog'),
  Horse: safeIcon('Horse'),
  Elephant: safeIcon('ElectricalServicesOutlined'),
  Bird: safeIcon('BirdBath'),
  Fish: safeIcon('Fish'),
  Turtle: safeIcon('TurtleOutlined'),
  Rabbit: safeIcon('RabbitOutlined'),
  Paw: safeIcon('PawOutlined'),
  Deer: safeIcon('DeerOutlined'),
  Penguin: safeIcon('PenguinOutlined'),
  Bee: safeIcon('BeeOutlined'),

  // Enhanced Nature icons
  Landscape: safeIcon('Landscape'),
  Park: safeIcon('Park'),
  Forest: safeIcon('Forest'),
  Terrain: safeIcon('Terrain'),
  WbSunny: safeIcon('WbSunny'),
  Waves: safeIcon('Waves'),
  AcUnit: safeIcon('AcUnit'),
  Thunderstorm: safeIcon('Thunderstorm'),
  EmojiNature: safeIcon('EmojiNature'),
  Fireplace: safeIcon('Fireplace'),
  BeachAccess: safeIcon('BeachAccess'),
  CloudOutlined: safeIcon('CloudOutlined'),
  WbCloudy: safeIcon('WbCloudy'),
  Umbrella: safeIcon('Umbrella'),
  Eco: safeIcon('Eco'),
  Grass: safeIcon('Grass'),
  LocalFlorist: safeIcon('LocalFlorist'),
  Water: safeIcon('Water'),
  WindPower: safeIcon('WindPower'),
  Volcano: safeIcon('Volcano'),
  Mountain: safeIcon('Landscape'),
  Waterfall: safeIcon('WaterDamage'),
  Canyon: safeIcon('Terrain'),
  Cave: safeIcon('DarkMode'),
  Glacier: safeIcon('AcUnit'),
  Rainforest: safeIcon('Park'),
  Desert: safeIcon('Landscape'),
  Savanna: safeIcon('Grass'),
  Coral: safeIcon('Water'),
  Swamp: safeIcon('Forest'),
  Rainbow: safeIcon('ColorLens'),
  NorthernLights: safeIcon('Lightbulb'),
  SolarPower: safeIcon('WbSunny'),
  Recycle: safeIcon('Recycling'),
  PlantNursery: safeIcon('LocalFlorist'),

  // Time and Schedule
  AccessTime: safeIcon('AccessTime'),
  Schedule: safeIcon('Schedule'),
  Event: safeIcon('Event'),
  Today: safeIcon('Today'),
  DateRange: safeIcon('DateRange'),
  WatchLater: safeIcon('WatchLater'),
  Alarm: safeIcon('Alarm'),
  HourglassEmpty: safeIcon('HourglassEmpty'),
  Update: safeIcon('Update'),
  Timer: safeIcon('Timer'),
  EventAvailable: safeIcon('EventAvailable'),
  EventBusy: safeIcon('EventBusy'),
  Snooze: safeIcon('Snooze'),

  // Music and Audio
  MusicNote: safeIcon('MusicNote'),
  Queue: safeIcon('Queue'),
  PlayArrow: safeIcon('PlayArrow'),
  Pause: safeIcon('Pause'),
  SkipNext: safeIcon('SkipNext'),
  SkipPrevious: safeIcon('SkipPrevious'),
  Replay: safeIcon('Replay'),
  Shuffle: safeIcon('Shuffle'),
  VolumeUp: safeIcon('VolumeUp'),
  VolumeOff: safeIcon('VolumeOff'),
  Audiotrack: safeIcon('Audiotrack'),
  QueueMusic: safeIcon('QueueMusic'),
  Lyrics: safeIcon('Lyrics'),
  Album: safeIcon('Album'),
  PlaylistPlay: safeIcon('PlaylistPlay'),

  // File and Document
  Description: safeIcon('Description'),
  FileCopy: safeIcon('FileCopy'),
  Folder: safeIcon('Folder'),
  CloudUpload: safeIcon('CloudUpload'),
  CloudDownload: safeIcon('CloudDownload'),
  PictureAsPdf: safeIcon('PictureAsPdf'),
  InsertDriveFile: safeIcon('InsertDriveFile'),
  Attachment: safeIcon('Attachment'),
  Archive: safeIcon('Archive'),
  Unarchive: safeIcon('Unarchive'),
  Note: safeIcon('Note'),
  NoteAdd: safeIcon('NoteAdd'),
  Compress: safeIcon('Compress'),
  FolderShared: safeIcon('FolderShared'),
  FileProsent: safeIcon('FileProsent'),

  // Photography and Images
  PhotoCamera: safeIcon('PhotoCamera'),
  Image: safeIcon('Image'),
  Collections: safeIcon('Collections'),
  Palette: safeIcon('Palette'),
  Brush: safeIcon('Brush'),
  Filter: safeIcon('Filter'),
  CropRotate: safeIcon('CropRotate'),
  ImageSearch: safeIcon('ImageSearch'),
  Panorama: safeIcon('Panorama'),
  Camera: safeIcon('Camera'),
  PhotoLibrary: safeIcon('PhotoLibrary'),
  PhotoFilter: safeIcon('PhotoFilter'),
  BurstMode: safeIcon('BurstMode'),
  Landscape: safeIcon('Landscape'),
  Portrait: safeIcon('Portrait'),

  // Productivity and Work
  Work: safeIcon('Work'),
  Assignment: safeIcon('Assignment'),
  ContentPaste: safeIcon('ContentPaste'),
  Dashboard: safeIcon('Dashboard'),
  TrendingUp: safeIcon('TrendingUp'),
  Assessment: safeIcon('Assessment'),
  Timeline: safeIcon('Timeline'),
  InsertChart: safeIcon('InsertChart'),
  TableChart: safeIcon('TableChart'),
  EventNote: safeIcon('EventNote'),
  Checklist: safeIcon('Checklist'),
  ChecklistRtl: safeIcon('ChecklistRtl'),
  Grading: safeIcon('Grading'),
  Insights: safeIcon('Insights'),
  WorkOutline: safeIcon('WorkOutline'),

  // Security and Privacy
  Security: safeIcon('Security'),
  Lock: safeIcon('Lock'),
  LockOpen: safeIcon('LockOpen'),
  VerifiedUser: safeIcon('VerifiedUser'),
  Fingerprint: safeIcon('Fingerprint'),
  VpnKey: safeIcon('VpnKey'),
  PasswordOutlined: safeIcon('PasswordOutlined'),
  Https: safeIcon('Https'),
  PhonelinkLock: safeIcon('PhonelinkLock'),
  PrivacyTip: safeIcon('PrivacyTip'),
  Shield: safeIcon('Shield'),
  Gavel: safeIcon('Gavel'),
  Policy: safeIcon('Policy'),
  AdminPanelSettings: safeIcon('AdminPanelSettings'),

  // Misc
  Photo: safeIcon('Photo'),
  Accessibility: safeIcon('Accessibility'),
  Eco: safeIcon('Eco'),
  Pets: safeIcon('Pets'),
  EmojiEmotions: safeIcon('EmojiEmotions'),
  Lightbulb: safeIcon('Lightbulb'),
  Palette: safeIcon('Palette'),
  Build: safeIcon('Build'),
  Public: safeIcon('Public'),
  Explore: safeIcon('Explore'),
  Celebration: safeIcon('Celebration'),
  EmojiEvents: safeIcon('EmojiEvents'),
  Cake: safeIcon('Cake'),
  Diversity3: safeIcon('Diversity3'),
  Fingerprint: safeIcon('Fingerprint'),
  Layers: safeIcon('Layers'),
  Recycling: safeIcon('Recycling'),
  Rowing: safeIcon('Rowing'),
  Toys: safeIcon('Toys'),
  Translate: safeIcon('Translate')
};

export const iconCategories = [
  { name: 'General', icons: ['Link', 'Email', 'Phone', 'Home', 'Info', 'Star', 'Chat', 'Share', 'Download', 'Upload', 'ContentCopy', 'Message', 'Favorite', 'Settings', 'Help', 'ShoppingCart', 'Search', 'Notifications', 'AccountCircle', 'CalendarToday', 'ThumbUp', 'ThumbDown', 'Directions', 'Warning', 'Error', 'CheckCircle', 'Save', 'Print', 'Add', 'Remove', 'Edit', 'Delete', 'KeyboardVoice', 'Language', 'Translate', 'ArrowBack', 'ArrowForward', 'Refresh', 'MoreVert', 'Dashboard', 'Fullscreen', 'FullscreenExit', 'ZoomIn', 'ZoomOut', 'FilterList', 'Sort', 'ViewList', 'ViewModule', 'ViewQuilt', 'ToggleOn', 'ToggleOff', 'Lock', 'LockOpen', 'Visibility', 'VisibilityOff', 'Exchange', 'Change', 'Sync', 'Transform']},
  { name: 'Hospitality & Tourism', icons: ['Restaurant', 'LocalCafe', 'LocalBar', 'Hotel', 'LocalAirport', 'Flight', 'BeachAccess', 'Pool', 'Spa', 'Attractions', 'LocalActivity', 'Museum', 'RoomService', 'Luggage', 'EmojiTransportation', 'Tour', 'KingBed', 'NightShelter', 'Surfing', 'Apartment', 'BungalowOutlined', 'CabinOutlined', 'Casino', 'HolidayVillage', 'HotTub', 'Parasailing', 'Sailing', 'SportsGolf', 'TravelExplore'] },
  { name: 'Business & Work', icons: ['BusinessCenter', 'MeetingRoom', 'Work', 'People', 'Group', 'Event', 'Assignment', 'Description', 'Folder', 'Schedule', 'PresentToAll', 'CorporateFare', 'Assessment', 'BarChart', 'Timeline', 'TrendingUp', 'TrendingDown', 'PieChart', 'Inventory', 'Insights', 'Leaderboard', 'GroupWork', 'Handshake', 'PersonAdd', 'ContentPaste', 'FileCopy', 'Approval'] },
  { name: 'Transportation', icons: ['DirectionsCar', 'LocalTaxi', 'Train', 'DirectionsBus', 'Subway', 'Tram', 'LocalShipping', 'ElectricCar', 'ElectricBike', 'ElectricScooter', 'Motorcycle', 'Pedalbike', 'DirectionsBoat', 'AirplanemodeActive', 'LocalParking', 'TrafficOutlined', 'EvStation', 'LocalGasStation', 'CarRental', 'CarRepair', 'Location', 'Map', 'Explore', 'Place', 'navigation'] },
  { name: 'Sports & Recreation', icons: ['SportsBasketball', 'SportsSoccer', 'SportsTennis', 'SportsFootball', 'SportsVolleyball', 'SportsRugby', 'SportsGolf', 'SportsHockey', 'SportsCricket', 'SportsEsports', 'FitnessCenter', 'SportsKabaddi', 'SportsHandball', 'SportsMma', 'SportsMotorsports', 'PoolOutlined', 'Skateboarding', 'Snowboarding', 'Surfing', 'SportsMartialArts'] },
  { name: 'Food & Dining', icons: ['Fastfood', 'LocalPizza', 'LocalDining', 'FreeBreakfast', 'Bakery', 'IcecreamOutlined', 'Coffee', 'LocalDrink', 'SetMeal', 'Takeout', 'DinnerDining', 'LunchDining', 'BrunchDining', 'LocalPizzaOutlined', 'LocalCafeOutlined', 'RestaurantMenu', 'DeliveryDining', 'TapasOutlined', 'EmojiFoodBeverage', 'Blender'] },
  { name: 'Outdoor & Nature', icons: ['Landscape', 'Park', 'Forest', 'Terrain', 'WbSunny', 'Waves', 'AcUnit', 'Thunderstorm', 'EmojiNature', 'Fireplace', 'BeachAccess', 'CloudOutlined', 'WbCloudy', 'Umbrella', 'Eco', 'Grass', 'LocalFlorist', 'Pets', 'Water', 'WindPower'] },
  { name: 'Entertainment', icons: ['LocalMovies', 'TheaterComedy', 'MusicNote', 'Casino', 'SportsBar', 'Celebration', 'Nightlife', 'Theaters', 'LiveTv', 'Mic', 'QueueMusic', 'SportsEsports', 'Videocam', 'MovieFilter', 'Games', 'Headphones', 'Karaoke', 'Carousel', 'FestivalOutlined', 'PartyMode'] },
  { name: 'Shopping', icons: ['LocalMall', 'Store', 'ShoppingBasket', 'AddShoppingCart', 'Storefront', 'Receipt', 'LocalOffer', 'Redeem', 'ShoppingBag', 'Loyalty', 'LocalConvenienceStore', 'LocalGroceryStore', 'Checkroom', 'Inventory2', 'SellOutlined', 'AddCard', 'Discount', 'QrCodeScanner', 'Barcode'] },
  { name: 'Health & Wellness', icons: ['Fitness', 'LocalHospital', 'Healing', 'MedicalServices', 'Medication', 'Psychology', 'SelfImprovement', 'HealthAndSafety', 'MonitorHeart', 'Bloodtype', 'Vaccines', 'Coronavirus', 'LocalPharmacy', 'SpaOutlined', 'Wheelchair', 'ElderlyOutlined', 'PregnantWoman', 'ChildCare'] },
  { name: 'Technology', icons: ['AppIcon', 'Wifi', 'Computer', 'Smartphone', 'Tablet', 'Storage', 'Cloud', 'Code', 'Security', 'VpnKey', 'DataUsage', 'Devices', 'DeveloperMode', 'Memory', 'SdStorage', 'Usb', 'Bluetooth', 'Cast', 'Router', 'PhoneIphone', 'KeyboardAlt'] },
  { name: 'Payment & Finance', icons: ['CreditCard', 'AttachMoney', 'AccountBalance', 'Payments', 'Receipt', 'MonetizationOn', 'AccountBalanceWallet', 'PriceCheck', 'Currency', 'Savings', 'RequestQuote', 'ReceiptLong', 'AccountBalanceOutlined', 'CreditScore', 'LocalAtm', 'PriceChange', 'Paid', 'PointOfSale', 'CurrencyExchange'] },
  { name: 'Education', icons: ['School', 'Book', 'MenuBook', 'LibraryBooks', 'Quiz', 'CastForEducation', 'Science', 'Architecture', 'HistoryEdu', 'Psychology', 'Calculate', 'FunctionsTwoTone', 'Biotech', 'AccountTree', 'EmojiObjects', 'AutoStories', 'Language', 'ScienceOutlined', 'DesignServices'] },
  { name: 'Communication', icons: ['Forum', 'QuestionAnswer', 'ChatBubble', 'ContactSupport', 'Feedback', 'RateReview', 'Campaign', 'Call', 'VideoCall', 'VoiceChat', 'Chat', 'Mail', 'ContactMail', 'AlternateEmail', 'Textsms', 'RssFeed', 'Send', 'Podcasts', 'LiveHelp'] },
  { name: 'Social Media', icons: ['Facebook', 'Twitter', 'Instagram', 'LinkedIn', 'YouTube', 'Pinterest', 'Reddit', 'WhatsApp', 'TripAdvisor', 'TikTok', 'Snapchat', 'Telegram', 'Twitch', 'Discord', 'Slack', 'Medium', 'Tumblr', 'Vimeo'] },
  { name: 'Weather', icons: ['WbSunny', 'Cloud', 'Thunderstorm', 'AcUnit', 'WaterDrop', 'Air', 'Thermostat', 'Tornado', 'WbCloudy', 'WbTwilight', 'Foggy', 'Grain', 'Waves', 'Umbrella'] },
  { name: 'Animals', icons: ['Pets', 'Cruelty', 'BugReport', 'Butterfly', 'Cat', 'Dog', 'Horse', 'Elephant', 'Bird', 'Fish', 'Turtle', 'Rabbit', 'Paw', 'Deer', 'Penguin', 'Bee'] },
  { name: 'Outdoor & Nature', icons: ['Landscape', 'Park', 'Forest', 'Terrain', 'WbSunny', 'Waves', 'AcUnit', 'Thunderstorm', 'EmojiNature', 'Fireplace', 'BeachAccess', 'CloudOutlined', 'WbCloudy', 'Umbrella', 'Eco', 'Grass', 'LocalFlorist', 'Water', 'WindPower', 'Volcano', 'Mountain', 'Waterfall', 'Canyon', 'Cave', 'Glacier', 'Rainforest', 'Desert', 'Savanna', 'Coral', 'Swamp', 'Rainbow', 'NorthernLights', 'SolarPower', 'Recycle', 'PlantNursery'] },

  { name: 'Time & Schedule', icons: ['AccessTime', 'Schedule', 'Event', 'Today', 'DateRange', 'WatchLater', 'Alarm', 'HourglassEmpty', 'Update', 'Timer', 'EventAvailable', 'EventBusy', 'Snooze'] },
  { name: 'Music & Audio', icons: ['MusicNote', 'Queue', 'PlayArrow', 'Pause', 'SkipNext', 'SkipPrevious', 'Replay', 'Shuffle', 'VolumeUp', 'VolumeOff', 'Audiotrack', 'QueueMusic', 'Lyrics', 'Album', 'PlaylistPlay'] },
  { name: 'File & Document', icons: ['Description', 'FileCopy', 'Folder', 'CloudUpload', 'CloudDownload', 'PictureAsPdf', 'InsertDriveFile', 'Attachment', 'Archive', 'Unarchive', 'Note', 'NoteAdd', 'Compress', 'FolderShared', 'FileProsent'] },
  { name: 'Photography & Images', icons: ['PhotoCamera', 'Image', 'Collections', 'Palette', 'Brush', 'Filter', 'CropRotate', 'ImageSearch', 'Panorama', 'Camera', 'PhotoLibrary', 'PhotoFilter', 'BurstMode', 'Landscape', 'Portrait'] },
  { name: 'Productivity & Work', icons: ['Work', 'Assignment', 'ContentPaste', 'Dashboard', 'TrendingUp', 'Assessment', 'Timeline', 'InsertChart', 'TableChart', 'EventNote', 'Checklist', 'ChecklistRtl', 'Grading', 'Insights', 'WorkOutline'] },
  { name: 'Security & Privacy', icons: ['Security', 'Lock', 'LockOpen', 'VerifiedUser', 'Fingerprint', 'VpnKey', 'PasswordOutlined', 'Https', 'PhonelinkLock', 'PrivacyTip', 'Shield', 'Gavel', 'Policy', 'AdminPanelSettings'] },
  { name: 'Misc', icons: ['Photo', 'Accessibility', 'Eco', 'Pets', 'EmojiEmotions', 'Lightbulb', 'Palette', 'Build', 'Public', 'Explore', 'Celebration', 'EmojiEvents', 'Cake', 'Diversity3', 'Fingerprint', 'Layers', 'Recycling', 'Rowing', 'Toys', 'Translate'] },
  

];
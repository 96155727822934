import React from 'react';
import { Tooltip as MuiTooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InfoButton = ({ title }) => (
  <MuiTooltip title={title}>
    <IconButton size="small">
      <InfoIcon fontSize="small" />
    </IconButton>
  </MuiTooltip>
);

export default InfoButton;
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const SectionHeading = ({ children, icon: Icon }) => {
  const theme = useTheme();

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        mt: 4, 
        mb: 2 
      }}
    >
      <Icon 
        sx={{ 
          fontSize: 40, 
          mr: 2, 
          color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main 
        }} 
      />
      <Typography 
        variant="h5" 
        sx={{ 
          color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.primary,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default SectionHeading;

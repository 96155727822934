import React from 'react';
import { 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  
} from '@mui/material';
import { 
  ResponsiveContainer, 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  Legend, 
  Cell 
} from 'recharts';
import { Tooltip as MuiTooltip } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReplayIcon from '@mui/icons-material/Replay';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import InfoButton from '../InfoButton';

const UserEngagementFunnel = ({ allQAData, isLoading }) => {
  

  const totalInteractions = allQAData.length;
  const followUpQuestions = allQAData.filter(qa => qa.is_follow_up === true).length;
  const interactionsWithFeedback = allQAData.filter(qa => qa.feedback && qa.feedback !== 'no_feedback').length;
  const interactionsWithPositiveFeedback = allQAData.filter(qa => ['heart', 'thumbs_up'].includes(qa.feedback)).length;

  const funnelData = [
    { stage: 'Asked Question', value: totalInteractions, icon: QuestionAnswerIcon, color: '#8884d8' },
    { stage: 'Asked Follow-up Question', value: followUpQuestions, icon: ReplayIcon, color: '#82ca9d' },
    { stage: 'Gave Feedback', value: interactionsWithFeedback, icon: FeedbackIcon, color: '#ffc658' },
    { stage: 'Gave Positive Feedback', value: interactionsWithPositiveFeedback, icon: ThumbUpAltIcon, color: '#ff7300' },
  ];

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom color="text.primary">
            User Engagement Funnel
          </Typography>
          <InfoButton title="Visualizes the user journey from total questions to positive feedback, showing engagement at each step" />

          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={funnelData}
              layout="vertical"
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="stage" type="category" />
              <RechartsTooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    const data = payload[0].payload;
                    const percentage = ((data.value / totalInteractions) * 100).toFixed(2);
                    return (
                      <Box
                        sx={{
                          backgroundColor: 'background.paper',
                          border: '1px solid',
                          borderColor: 'divider',
                          p: 2,
                          borderRadius: 6,
                        }}
                      >
                        <Typography variant="body2" color="text.primary" fontWeight="bold">
                          {data.stage}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {`Count: ${data.value}`}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {`${percentage}% of Total Interactions`}
                        </Typography>
                      </Box>
                    );
                  }
                  return null;
                }}
              />
              <Legend />
              <Bar dataKey="value" name="Number of Interactions">
                {funnelData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} fillOpacity={0.7}/>
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <Box display="flex" justifyContent="space-around" mt={2}>
            {funnelData.map((item, index) => (
              <MuiTooltip key={index} title={item.stage}>
                <Box display="flex" alignItems="center" flexDirection="column">
                  <item.icon style={{ color: item.color, fontSize: 40 }} />
                  <Typography variant="caption">{item.value}</Typography>
                </Box>
              </MuiTooltip>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UserEngagementFunnel;

import React, { useMemo } from 'react';
import { Grid, Card, CardContent, Typography, useTheme, Tooltip as MuiTooltip, IconButton, Box } from '@mui/material';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend } from 'recharts';
import InfoIcon from '@mui/icons-material/Info';

const QuestionComplexityAnalysis = ({ allQAData, isLoading }) => {
  const theme = useTheme();

  const complexityData = useMemo(() => {
    const complexityBuckets = {
      '1-5 words': { count: 0, totalResponseTime: 0 },
      '6-10 words': { count: 0, totalResponseTime: 0 },
      '11-20 words': { count: 0, totalResponseTime: 0 },
      '21-30 words': { count: 0, totalResponseTime: 0 },
      '31+ words': { count: 0, totalResponseTime: 0 },
    };

    allQAData.forEach(qa => {
      const wordCount = qa.question.split(/\s+/).length;
      let bucket;
      if (wordCount <= 5) bucket = '1-5 words';
      else if (wordCount <= 10) bucket = '6-10 words';
      else if (wordCount <= 20) bucket = '11-20 words';
      else if (wordCount <= 30) bucket = '21-30 words';
      else bucket = '31+ words';

      complexityBuckets[bucket].count++;
      complexityBuckets[bucket].totalResponseTime += qa.responseTime || 0;
    });

    return Object.entries(complexityBuckets).map(([complexity, data]) => ({
      complexity,
      count: data.count,
      avgResponseTime: data.count > 0 ? data.totalResponseTime / data.count : 0,
    }));
  }, [allQAData]);

  const InfoButton = ({ title }) => (
    <MuiTooltip title={title}>
      <IconButton size="small">
        <InfoIcon fontSize="small" />
      </IconButton>
    </MuiTooltip>
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '10px',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
          }}
        >
          <Typography variant="body2" color="text.primary">
            <strong>{label}</strong>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Count: {payload[0].value}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Avg Response Time: {payload[1].value.toFixed(2)}s
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Grid item xs={12} md={16}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" color="text.primary">
              Question Complexity Analysis
            </Typography>
            <InfoButton title="Distribution of question complexity and its impact on response time" />
          </Box>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={complexityData}
              margin={{ top: 20, right: 30, left: 20, bottom: 0 }}
               fillOpacity={0.8}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="complexity" angle={0} textAnchor="end" height={70} />
              <YAxis yAxisId="left" orientation="left" stroke={theme.palette.primary.main} />
              <YAxis yAxisId="right" orientation="right" stroke={theme.palette.secondary.main} />
              <RechartsTooltip content={<CustomTooltip />} />
              <Legend />
              <Bar yAxisId="left" dataKey="count" name="Question Count" fill={theme.palette.primary.main} />
              <Bar yAxisId="right" dataKey="avgResponseTime" name="Avg Response Time (s)" fill={theme.palette.secondary.main} />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default QuestionComplexityAnalysis;

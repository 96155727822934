import React, { useMemo } from 'react';
import { Card, CardContent, Typography, Box, useTheme, CircularProgress, alpha, IconButton, Tooltip } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, Area } from 'recharts';
import InfoIcon from '@mui/icons-material/Info';

const ResponseTimeByHourChart = ({ allQAData, isLoading }) => {
  const theme = useTheme();

  const hourlyData = useMemo(() => {
    console.log('Processing allQAData:', allQAData);
    if (!allQAData || allQAData.length === 0) {
      console.log('allQAData is empty or undefined');
      return [];
    }

    console.log('Sample qa object:', allQAData[0]);

    const hourlyAverages = Array(24).fill().map(() => ({ total: 0, count: 0 }));

    allQAData.forEach((qa, index) => {
      if (qa && qa.timestamp && qa.responseTime !== undefined && qa.responseTime !== null) {
        // Parse the custom date format
        const [timePart] = qa.timestamp.split(', ');
        const [hourStr] = timePart.split(':');
        const hour = parseInt(hourStr, 10);

        const responseTime = Number(qa.responseTime);

        if (!isNaN(hour) && !isNaN(responseTime)) {
          hourlyAverages[hour].total += responseTime;
          hourlyAverages[hour].count += 1;
        } else {
          console.log(`Invalid data at index ${index}:`, { hour, responseTime, originalQA: qa });
        }
      } else {
        console.log(`Missing required fields at index ${index}:`, qa);
      }
    });

    const result = hourlyAverages.map((data, hour) => ({
      hour,
      averageResponseTime: data.count > 0 ? data.total / data.count : 0
    }));

    console.log('Processed hourly data:', result);
    return result;
  }, [allQAData]);

  if (isLoading) {
    return (
      <Card sx={{ height: '100%', boxShadow: theme.shadows[3], borderRadius: 2 }}>
        <CardContent sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  if (hourlyData.length === 0 || hourlyData.every(data => data.averageResponseTime === 0)) {
    return (
      <Card sx={{ height: '100%', boxShadow: theme.shadows[3], borderRadius: 2 }}>
        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Response Time Distribution by Time of Day
          </Typography>
          <Typography variant="body2" color="text.secondary">
            No data available for this chart.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ height: '100%', boxShadow: theme.shadows[3], borderRadius: 2 }}>
      <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 3 }}>
        <Box mb={2} display="flex" alignItems="center">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            Response Time Distribution by Time of Day
          </Typography>
          <Tooltip title="Shows how average response time varies throughout the day">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box flexGrow={1} minHeight={300}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={hourlyData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke={alpha(theme.palette.text.primary, 0.1)} />
              <XAxis 
                dataKey="hour" 
                tickFormatter={(hour) => `${hour.toString().padStart(2, '0')}:00`}
                stroke={theme.palette.text.secondary}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <YAxis 
                stroke={theme.palette.text.secondary}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
                label={{ 
                  value: 'Avg Response Time (s)', 
                  angle: -90, 
                  position: 'insideLeft',
                  style: { textAnchor: 'middle', fill: theme.palette.text.primary, fontSize: 14 }
                }}
              />
              <RechartsTooltip 
                formatter={(value) => [`${value.toFixed(2)} s`, 'Avg Response Time']}
                labelFormatter={(hour) => `${hour.toString().padStart(2, '0')}:00 - ${((hour + 1) % 24).toString().padStart(2, '0')}:00`}
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 8,
                  boxShadow: theme.shadows[3],
                  color: theme.palette.text.primary,
                }}
              />
              <Legend iconType="circle" />
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.8}/>
                  <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0}/>
                </linearGradient>
              </defs>
              <Area 
                type="monotone" 
                dataKey="averageResponseTime" 
                stroke={theme.palette.primary.main}
                fillOpacity={1}
                fill="url(#colorUv)"
              />
              <Line 
                type="monotone" 
                dataKey="averageResponseTime" 
                name="Avg Response Time" 
                stroke={theme.palette.primary.main}
                strokeWidth={3}
                dot={{ r: 4, fill: theme.palette.background.paper, stroke: theme.palette.primary.main, strokeWidth: 2 }}
                activeDot={{ r: 8, fill: theme.palette.primary.main, stroke: theme.palette.background.paper, strokeWidth: 2 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ResponseTimeByHourChart;
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import CountUp from 'react-countup';

const MetricCard = ({ title, value, gradient, prefix = '', isInteger = false }) => {
  return (
    <Card sx={{ 
      background: gradient, 
      color: 'white',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4">
          {prefix}
          <CountUp 
            end={value} 
            duration={2.5} 
            separator="," 
            decimals={isInteger ? 0 : 2}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MetricCard;
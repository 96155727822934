import React from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import chatConfig from './chatConfig';



const pulse = keyframes`
  0% { transform: scale(1); }
  10% { transform: scale(1.1); }
  20% { transform: scale(1); }
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-20px); }
  60% { transform: translateY(-10px); }
`;

const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(5px); }
  50% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;



const jello = keyframes`
  11.1% { transform: none }
  22.2% { transform: skewX(-12.5deg) skewY(-12.5deg) }
  33.3% { transform: skewX(6.25deg) skewY(6.25deg) }
  44.4% { transform: skewX(-3.125deg) skewY(-3.125deg) }
  55.5% { transform: skewX(1.5625deg) skewY(1.5625deg) }
  66.6% { transform: skewX(-0.78125deg) skewY(-0.78125deg) }
  77.7% { transform: skewX(0.390625deg) skewY(0.390625deg) }
  88.8% { transform: skewX(-0.1953125deg) skewY(-0.1953125deg) }
  100% { transform: none }
`;



const tada = keyframes`
  0% { transform: scale(1); }
  10%, 20% { transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% { transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% { transform: scale(1.1) rotate(-3deg); }
  100% { transform: scale(1) rotate(0); }
`;



const getAnimation = (type) => {
  switch (type) {
    case 'pulse': return pulse;
    case 'spin': return spin;
    case 'bounce': return bounce;
    case 'shake': return shake;
    case 'jello': return jello;
    case 'tada': return tada;
    default: return 'none';
  }
};

// Fallback values
const fallbackConfig = {
  size: 40,
  margin: { left: 15, right: 20 },
  animation: { type: 'pulse', duration: '3s', timingFunction: 'ease-in-out', iterationCount: 'infinite' },
  isCircular: true
};

const AnimatedIconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'animation' && prop !== 'isCircular'
})(({ animation }) => {
  const size = chatConfig?.botIcon?.size || fallbackConfig.size;
  const marginLeft = chatConfig?.botIcon?.margin?.left || fallbackConfig.margin.left;
  const marginRight = chatConfig?.botIcon?.margin?.right || fallbackConfig.margin.right;
  const animationType = chatConfig?.botIcon?.animation?.type || fallbackConfig.animation.type;
  const animationDuration = chatConfig?.botIcon?.animation?.duration || fallbackConfig.animation.duration;
  const animationTimingFunction = chatConfig?.botIcon?.animation?.timingFunction || fallbackConfig.animation.timingFunction;
  const animationIterationCount = chatConfig?.botIcon?.animation?.iterationCount || fallbackConfig.animation.iterationCount;

  return {
    width: size,
    height: size,
    marginLeft: marginLeft,
    marginRight: marginRight,
    position: 'relative',
    animation: animationType !== 'none'
      ? `${getAnimation(animationType)} ${animationDuration} ${animationTimingFunction} ${animationIterationCount}`
      : 'none',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '100%', // This creates a 1:1 aspect ratio
    },
  };
});

const AnimatedIconContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCircular'
})(({ isCircular }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: isCircular ? '50%' : '0',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const AnimatedBotIcon = () => {
  const iconSrc = chatConfig?.botIcon?.src || "/images/bot-icon.png";
  const isCircular = chatConfig?.botIcon?.isCircular ?? fallbackConfig.isCircular;

  return (
    <AnimatedIconWrapper
      animation={chatConfig?.botIcon?.animation || fallbackConfig.animation}
    >
      <AnimatedIconContent isCircular={isCircular}>
        <img src={iconSrc} alt="Bot" />
      </AnimatedIconContent>
    </AnimatedIconWrapper>
  );
};

export default AnimatedBotIcon;
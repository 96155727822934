import React, { useMemo } from 'react';
import { Card, CardContent, Typography, useTheme, CircularProgress } from '@mui/material';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import InfoButton from '../InfoButton';

const QuestionComplexityDistributionChart = ({ allQAData, isLoading }) => {
  const theme = useTheme();

  const questionComplexityDistribution = useMemo(() => {
    const distribution = {
      '1-5': 0,
      '6-10': 0,
      '11-15': 0,
      '16-20': 0,
      '21+': 0
    };
    
    allQAData.forEach(qa => {
      const wordCount = qa.question.split(/\s+/).length;
      if (wordCount <= 5) distribution['1-5']++;
      else if (wordCount <= 10) distribution['6-10']++;
      else if (wordCount <= 15) distribution['11-15']++;
      else if (wordCount <= 20) distribution['16-20']++;
      else distribution['21+']++;
    });

    return Object.entries(distribution).map(([range, count]) => ({ range, count }));
  }, [allQAData]);

  if (isLoading) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom color="text.primary">
            Question Complexity Distribution
          </Typography>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  if (questionComplexityDistribution.length === 0) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom color="text.primary">
            Question Complexity Distribution
          </Typography>
          <Typography>No data available</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="text.primary">
          Question Complexity Distribution
        </Typography>
        <InfoButton title="Displays the distribution of questions based on their complexity (word count), helping understand typical user query lengths" />
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={questionComplexityDistribution}>
            <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
            <XAxis 
              dataKey="range" 
              tick={{ fill: theme.palette.text.primary }}
              label={{ value: 'Word Count Range', position: 'bottom', fill: theme.palette.text.primary }}
            />
            <YAxis 
              tick={{ fill: theme.palette.text.primary }}
              label={{ value: 'Number of Questions', angle: -90, position: 'insideLeft', fill: theme.palette.text.primary }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 10,
                color: theme.palette.text.primary,
              }}
            />
            <Bar dataKey="count" fill={theme.palette.primary.main} fillOpacity={0.7} />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default QuestionComplexityDistributionChart;
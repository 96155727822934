import React, { useState, useEffect } from 'react';
import { 
  Grid, Typography, List, ListItem, ListItemText, 
  CircularProgress, Box, useTheme, IconButton, Tooltip, ButtonGroup, Button,
  LinearProgress, Paper, Fade
} from '@mui/material';
import { 
  BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, 
  Legend, ResponsiveContainer
} from 'recharts';
import { getAdminOverview } from '../services/api';
import RefreshIcon from '@mui/icons-material/Refresh';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';

const AdminOverview = () => {
  const [overviewData, setOverviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState('14');
  const theme = useTheme();

  const fetchOverviewData = async () => {
    try {
      setLoading(true);
      const data = await getAdminOverview();
      setOverviewData(data);
      setError(null);
    } catch (error) {
      console.error('Failed to fetch overview data:', error);
      setError('Failed to load overview data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOverviewData();
    const intervalId = setInterval(fetchOverviewData, 300000); // Refresh every 5 minutes
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress />
    </Box>
  );
  if (error) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography color="error">{error}</Typography>
    </Box>
  );
  if (!overviewData) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography>No overview data available.</Typography>
    </Box>
  );

  const {
    total_users = 0,
    active_sessions = 0,
    total_questions = 0,
    avg_response_time = 0,
    queries_per_day = [],
    avg_response_time_history = [],
    positive_feedback = 0,
    negative_feedback = 0,
    user_satisfaction = 0,
    recent_activities = []
  } = overviewData;

  const StatCard = ({ title, value, subValue, color, icon }) => (
    <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
      <Box sx={{ backgroundColor: color, color: 'white', p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{title}</Typography>
          {icon}
        </Box>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {typeof value === 'number' ? value.toLocaleString() : value}
        </Typography>
      </Box>
      {subValue && (
        <Box sx={{ p: 1, backgroundColor: 'background.paper' }}>
          <Typography variant="body2">{subValue}</Typography>
        </Box>
      )}
    </Paper>
  );

  return (
    <Fade in={true}>
      <Grid container spacing={3}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          
          <Box>
            <ButtonGroup size="small" sx={{ mr: 2 }}>
              <Button 
                variant={dateRange === '7' ? 'contained' : 'outlined'} 
                onClick={() => setDateRange('7')}
              >
                7 Days
              </Button>
              <Button 
                variant={dateRange === '14' ? 'contained' : 'outlined'} 
                onClick={() => setDateRange('14')}
              >
                14 Days
              </Button>
              <Button 
                variant={dateRange === '30' ? 'contained' : 'outlined'} 
                onClick={() => setDateRange('30')}
              >
                30 Days
              </Button>
            </ButtonGroup>
            <Tooltip title="Refresh Data">
              <IconButton onClick={fetchOverviewData}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <StatCard 
            title="Total Users" 
            value={total_users} 
            color={theme.palette.primary.main} 
            icon={<PersonIcon fontSize="large" />} 
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard 
            title="Active Sessions" 
            value={active_sessions} 
            color={theme.palette.secondary.main} 
            icon={<GroupIcon fontSize="large" />} 
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard 
            title="Total Questions" 
            value={total_questions} 
            color={theme.palette.success.main} 
            icon={<QuestionAnswerIcon fontSize="large" />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard 
            title="Avg. Response Time" 
            value={`${avg_response_time.toFixed(2)}s`} 
            color={theme.palette.warning.main}
            icon={<AccessTimeIcon fontSize="large" />}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>Queries per Day</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={queries_per_day.slice(-parseInt(dateRange))}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="queries" fill={theme.palette.primary.main} />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>Average Response Time</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={avg_response_time_history.slice(-parseInt(dateRange))}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                <Line type="monotone" dataKey="avg_response_time" stroke={theme.palette.secondary.main} />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>User Feedback</Typography>
            <Box display="flex" justifyContent="space-around" alignItems="center" mt={2}>
              <Box textAlign="center">
                <ThumbUpIcon color="success" fontSize="large" />
                <Typography variant="h4">{positive_feedback.toFixed(1)}%</Typography>
                <Typography variant="body2">Positive</Typography>
              </Box>
              <Box textAlign="center">
                <ThumbDownIcon color="error" fontSize="large" />
                <Typography variant="h4">{negative_feedback.toFixed(1)}%</Typography>
                <Typography variant="body2">Negative</Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="body2" gutterBottom>Overall Satisfaction</Typography>
              <LinearProgress 
                variant="determinate" 
                value={user_satisfaction} 
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: theme.palette.grey[300],
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 5,
                    backgroundColor: theme.palette.success.main,
                  },
                }}
              />
              <Typography variant="body2" align="right">{`${user_satisfaction.toFixed(1)}%`}</Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>Recent Activity</Typography>
            <List sx={{ maxHeight: 400, overflow: 'auto' }}>
              {recent_activities.map((activity, index) => (
                <ListItem key={index} divider={index !== recent_activities.length - 1}>
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        <strong>{activity.user}:</strong> {activity.question}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography variant="body2" color="textSecondary">
                          Answer: {activity.answer.length > 100 ? `${activity.answer.substring(0, 100)}...` : activity.answer}
                        </Typography>
                        <Box display="flex" alignItems="center" mt={1} justifyContent="space-between">
                          <Box display="flex" alignItems="center">
                            <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                            <Typography variant="caption" color="textSecondary">
                              {new Date(activity.timestamp).toLocaleString()}
                            </Typography>
                          </Box>
                          {activity.feedback !== 'no_feedback' && (
                            <Tooltip title={`Feedback: ${activity.feedback}`}>
                              <IconButton size="small">
                                {activity.feedback === 'thumbs_up' ? <ThumbUpIcon color="success" /> : 
                                 activity.feedback === 'thumbs_down' ? <ThumbDownIcon color="error" /> : 
                                 null}
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default AdminOverview;
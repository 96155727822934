import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, Typography, Button, TextField, Select, MenuItem, FormControl, 
  InputLabel, Slider, Grid, CircularProgress, Radio, RadioGroup,
  FormControlLabel, Alert, List, ListItem, ListItemText, Tabs, Tab,
  Switch, Card, CardContent, Divider
} from '@mui/material';
import { getDatabases, processDocuments, scrapeAndCreateDb } from '../services/api';
import KnowledgeBaseOverview from './KnowledgeBaseOverview';
import DocumentUpload from './DocumentUpload';
import ProcessingDialog from './ProcessingDialog';

const KnowledgeBase = () => {
  const [files, setFiles] = useState([]);
  const [splitterType, setSplitterType] = useState('recursive');
  const [chunkSize, setChunkSize] = useState(1000);
  const [chunkOverlap, setChunkOverlap] = useState(200);
  const [embeddingModel, setEmbeddingModel] = useState('text-embedding-3-small');
  const [isProcessing, setIsProcessing] = useState(false);
  const [databaseAction, setDatabaseAction] = useState('new');
  const [newDatabaseName, setNewDatabaseName] = useState('');
  const [existingDatabases, setExistingDatabases] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [databasesInfo, setDatabasesInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [processingDialogOpen, setProcessingDialogOpen] = useState(false);
  const [scrapingUrl, setScrapingUrl] = useState('');
  const [scrapingDbName, setScrapingDbName] = useState('');
  const [maxPages, setMaxPages] = useState(50);
  const [maxTime, setMaxTime] = useState(3600);
  const [tabValue, setTabValue] = useState(0);
  const [extractMedia, setExtractMedia] = useState(true);
  const [activeDatabases, setActiveDatabases] = useState([]);

  const fetchDatabases = useCallback(async () => {
  try {
    setIsLoading(true);
    const data = await getDatabases();
    setDatabasesInfo(data.databases);
    setExistingDatabases(data.databases.map(db => db.name));
    setActiveDatabases(data.active_databases || []);
    setError(null);
  } catch (error) {
    console.error('Failed to fetch databases:', error);
    setError(`Failed to load databases: ${error.response?.data?.detail || error.message}`);
  } finally {
    setIsLoading(false);
  }
}, []);

  useEffect(() => {
    fetchDatabases();
  }, [fetchDatabases]);
  
  
  
  
  
  

  const handleFileUpload = (event) => {
    setFiles([...files, ...Array.from(event.target.files)]);
  };

  const handleFileRemove = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleProcess = useCallback(() => {
    if (isProcessing) return;
    setProcessingDialogOpen(true);
    setIsProcessing(true);
  }, [isProcessing]);

  const handleScrapeAndCreateDb = useCallback(async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      await scrapeAndCreateDb({
        url: scrapingUrl,
        database_name: scrapingDbName,
        max_pages: maxPages,
        max_time: maxTime,
        chunk_size: chunkSize,
        chunk_overlap: chunkOverlap,
        splitter_type: splitterType,
        embedding_model: embeddingModel,
        extract_media: extractMedia
      });
      setProcessingDialogOpen(true);
    } catch (error) {
      console.error('Error starting scraping process:', error);
      setError(error.message || 'Failed to start scraping process. Please try again.');
      setIsProcessing(false);
    }
  }, [scrapingUrl, scrapingDbName, maxPages, maxTime, chunkSize, chunkOverlap, splitterType, embeddingModel, extractMedia, isProcessing]);

  const handleProcessingComplete = useCallback(() => {
    setProcessingDialogOpen(false);
    setIsProcessing(false);
    setSuccessMessage("Process completed successfully!");
    fetchDatabases();
    setFiles([]);
    setNewDatabaseName('');
    setSelectedDatabase(null);
    setScrapingUrl('');
    setScrapingDbName('');
  }, [fetchDatabases]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'normal', mb: 3 }}>Knowledge Base Manager</Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      )}
      
      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>
      )}
      
      {!error && databasesInfo.length === 0 && (
        <Alert severity="info" sx={{ mb: 2 }}>No databases found. Create a new one to get started.</Alert>
      )}
      
      <KnowledgeBaseOverview 
      databases={databasesInfo} 
      activeDatabases={activeDatabases}
      onActiveDatabasesChange={setActiveDatabases}
    />
      
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="Upload Documents" />
            <Tab label="Scrape Website" />
          </Tabs>

          {tabValue === 0 && (
            <>
              <DocumentUpload 
                files={files} 
                onFileUpload={handleFileUpload} 
                onFileRemove={handleFileRemove}
              />
              
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>Database Options</Typography>
                <RadioGroup
                  value={databaseAction}
                  onChange={(e) => setDatabaseAction(e.target.value)}
                >
                  <FormControlLabel value="new" control={<Radio />} label="Create a new database" />
                  <FormControlLabel value="existing" control={<Radio />} label="Add to an existing database" />
                </RadioGroup>
                {databaseAction === 'new' ? (
                  <TextField
                    fullWidth
                    label="New Database Name"
                    value={newDatabaseName}
                    onChange={(e) => setNewDatabaseName(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                ) : (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Select an existing database:
                    </Typography>
                    {existingDatabases.length > 0 ? (
                      <List>
                        {existingDatabases.map((db) => (
                          <ListItem
                            key={db}
                            button
                            selected={selectedDatabase === db}
                            onClick={() => setSelectedDatabase(db)}
                          >
                            <ListItemText primary={db} />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography>No existing databases found.</Typography>
                    )}
                  </Box>
                )}
              </Box>

              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleProcess}
                disabled={isProcessing || files.length === 0 || (databaseAction === 'new' && !newDatabaseName) || (databaseAction === 'existing' && !selectedDatabase)}
                sx={{ mt: 3 }}
              >
                Process Documents
              </Button>
            </>
          )}

          {tabValue === 1 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" gutterBottom>Scrape Website</Typography>
              <TextField
                fullWidth
                label="Website URL"
                value={scrapingUrl}
                onChange={(e) => setScrapingUrl(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Database Name"
                value={scrapingDbName}
                onChange={(e) => setScrapingDbName(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                type="number"
                label="Max Pages"
                value={maxPages}
                onChange={(e) => setMaxPages(Number(e.target.value))}
                margin="normal"
              />
              <TextField
                fullWidth
                type="number"
                label="Max Time (seconds)"
                value={maxTime}
                onChange={(e) => setMaxTime(Number(e.target.value))}
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleScrapeAndCreateDb}
                disabled={isProcessing || !scrapingUrl || !scrapingDbName}
                sx={{ mt: 2 }}
              >
                Scrape and Create Database
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Text Splitting Settings</Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Splitter Type</InputLabel>
                <Select
                  value={splitterType}
                  onChange={(e) => setSplitterType(e.target.value)}
                  label="Splitter Type"
                >
                  <MenuItem value="recursive">Recursive Character</MenuItem>
                  <MenuItem value="character">Character</MenuItem>
                </Select>
              </FormControl>
              <Typography gutterBottom>Chunk Size: {chunkSize}</Typography>
              <Slider
                value={chunkSize}
                onChange={(e, newValue) => setChunkSize(newValue)}
                min={100}
                max={2000}
                step={100}
              />
              <Typography gutterBottom>Chunk Overlap: {chunkOverlap}</Typography>
              <Slider
                value={chunkOverlap}
                onChange={(e, newValue) => setChunkOverlap(newValue)}
                min={0}
                max={500}
                step={50}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Embedding Settings</Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
  <InputLabel>Embedding Model</InputLabel>
  <Select
    value={embeddingModel}
    onChange={(e) => setEmbeddingModel(e.target.value)}
    label="Embedding Model"
  >
    <MenuItem value="text-embedding-3-small">text-embedding-3-small (OpenAI)</MenuItem>
    <MenuItem value="text-embedding-3-large">text-embedding-3-large (OpenAI)</MenuItem>
    <MenuItem value="text-embedding-ada-002">text-embedding-ada-002 (OpenAI Legacy)</MenuItem>
    <MenuItem value="huggingface">HuggingFace</MenuItem>
  </Select>
</FormControl>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>Media Extraction Settings</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={extractMedia}
                    onChange={(e) => setExtractMedia(e.target.checked)}
                    name="extractMedia"
                    color="primary"
                  />
                }
                label="Extract media from documents"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ProcessingDialog
        open={processingDialogOpen}
        onClose={() => {
          if (!isProcessing) {
            setProcessingDialogOpen(false);
          }
        }}
        processDocuments={processDocuments}
        scrapeAndCreateDb={scrapeAndCreateDb}
        files={files}
        settings={{
          chunk_size: chunkSize,
          chunk_overlap: chunkOverlap,
          embedding_model: embeddingModel,
          splitter_type: splitterType,
          extract_media: extractMedia
        }}
        databaseAction={databaseAction}
        databaseName={databaseAction === 'new' ? newDatabaseName : selectedDatabase}
        scrapingUrl={scrapingUrl}
        scrapingDbName={scrapingDbName}
        maxPages={maxPages}
        maxTime={maxTime}
        onProcessingComplete={handleProcessingComplete}
        processingType={tabValue === 0 ? 'documents' : 'website'}
      />
    </Box>
  );
};

export default KnowledgeBase;
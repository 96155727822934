
import { Grid, Typography } from '@mui/material';



import MediaTypeDistributionChart from './charts/MediaTypeDistributionChart';
import TopMediaList from './charts/TopMediaList';

const MediaAnalyticsSection = ({ mediaData }) => {
  

  if (!mediaData || !Array.isArray(mediaData.media_analytics) || mediaData.media_analytics.length === 0) {
    return (
      <Grid item xs={12}>
        
        <Typography>No media analytics data available for the selected date range.</Typography>
      </Grid>
    );
  }

  return (
    
      <Grid container spacing={3}>
        
        
        <Grid item xs={12}>
         
          <MediaTypeDistributionChart 
            mediaData={mediaData.media_analytics} 
          />
        </Grid>

        <Grid item xs={12}>
          <TopMediaList mediaData={mediaData.media_analytics} />
        </Grid>
      </Grid>
   
  );
};

export default MediaAnalyticsSection;
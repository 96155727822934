import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Box, useTheme, TextField, Paper } from '@mui/material';
import chatConfig from './chatConfig';







// Header component for the chat interface ***
export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: chatConfig.header.padding,
  backgroundColor: theme.palette.mode === 'dark' 
    ? chatConfig.header.backgroundColor.dark 
    : chatConfig.header.backgroundColor.light,
  backdropFilter: chatConfig.header.backdropFilter,
  borderBottom: `${chatConfig.header.borderBottomWidth} solid ${theme.palette.divider}`,
}));



// Fallback values
const fallbackConfig = {
  backgroundGradient: {
    light: 'linear-gradient(to bottom right, #f5f5f5, #e0e0e0)',
    dark: 'linear-gradient(to bottom right, #1a1a1a, #2c2c2c)'
  },
  inputField: {
    backgroundColor: {
      light: '#ffffff',
      dark: '#333333'
    },
    borderRadius: '20px',
    padding: '8px 16px'
  }
};


// Background gradient component
export const BackgroundGradient = ({ children }) => {
  const theme = useTheme();
  const bgConfig = chatConfig.backgroundStyle;
  
  const parallaxRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (bgConfig.parallax.enable && parallaxRef.current) {
        const scrollPosition = window.pageYOffset;
        const yPos = -(scrollPosition * bgConfig.parallax.speed);
        parallaxRef.current.style.transform = `translate3d(0, ${yPos}px, 0)`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [bgConfig.parallax.enable, bgConfig.parallax.speed]);

  const currentImage = theme.palette.mode === 'dark' ? bgConfig.image.dark : bgConfig.image.light;

  return (
    <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <Box
        ref={parallaxRef}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -2,
          backgroundImage: `url(${currentImage.url})`,
          backgroundSize: currentImage.backgroundSize,
          backgroundPosition: currentImage.backgroundPosition,
          transform: 'translate3d(0, 0, 0)',
          willChange: 'transform',
        }}
      />
      {bgConfig.overlay.enable && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.palette.mode === 'dark' 
              ? bgConfig.overlay.color.dark
              : bgConfig.overlay.color.light,
            mixBlendMode: bgConfig.overlay.blendMode,
            zIndex: -1,
          }}
        />
      )}
      {bgConfig.blur.enable && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: `blur(${bgConfig.blur.amount})`,
            zIndex: -1,
          }}
        />
      )}
      {children}
    </Box>
  );
};




// Container for chat messages
export const ChatContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1, 
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  paddingBottom: 0, // Remove bottom padding
  position: 'relative',
  backgroundColor: 'transparent',
}));


// Message bubble component
export const MessageBubble = styled(Box)(({ theme, sender }) => {
  const bubbleConfig = chatConfig.messageBubble[sender];
  const bgColor = theme.palette.mode === 'dark' ? bubbleConfig.dark : bubbleConfig.light;
  const gradientBg = theme.palette.mode === 'dark' ? bubbleConfig.darkGradient : bubbleConfig.lightGradient;

  // Use either solid color or gradient based on configuration
  const backgroundStyle = bubbleConfig.useGradient ? { background: gradientBg } : { backgroundColor: bgColor };

  return {
    maxWidth: bubbleConfig.maxWidth,
    padding: bubbleConfig.padding,
    borderRadius: bubbleConfig.borderRadius,
    ...backgroundStyle,
    color: theme.palette.getContrastText(bgColor),
    alignSelf: sender === 'user' ? 'flex-end' : 'flex-start',
    marginBottom: theme.spacing(1),
    boxShadow: bubbleConfig.boxShadow,
  };
});






// Styled text field for message input
export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? chatConfig?.inputField?.backgroundColor?.dark || fallbackConfig.inputField.backgroundColor.dark
      : chatConfig?.inputField?.backgroundColor?.light || fallbackConfig.inputField.backgroundColor.light,
    borderRadius: chatConfig?.inputField?.borderRadius || fallbackConfig.inputField.borderRadius,
    padding: chatConfig?.inputField?.padding || fallbackConfig.inputField.padding,
    borderColor: theme.palette.mode === 'dark'
      ? chatConfig?.inputField?.borderColor?.dark || 'inherit'
      : chatConfig?.inputField?.borderColor?.light || 'inherit',
    color: theme.palette.mode === 'dark'
      ? chatConfig?.inputField?.textColor?.dark || 'inherit'
      : chatConfig?.inputField?.textColor?.light || 'inherit',
    '&:hover': {
      borderColor: theme.palette.mode === 'dark'
        ? chatConfig?.inputField?.focusBorderColor?.dark || 'inherit'
        : chatConfig?.inputField?.focusBorderColor?.light || 'inherit',
    },
    '&.Mui-focused': {
      borderColor: theme.palette.mode === 'dark'
        ? chatConfig?.inputField?.focusBorderColor?.dark || 'inherit'
        : chatConfig?.inputField?.focusBorderColor?.light || 'inherit',
    },
  },
  '& .MuiInputBase-input': {
    fontSize: chatConfig?.inputField?.fontSize || 'inherit',
    fontFamily: chatConfig?.inputField?.fontFamily || 'inherit',
  },
}));






// Footer and Input area component
export const InputArea = styled(Paper)(({ theme }) => ({
  display: 'flex', 
  alignItems: 'center',
  padding: chatConfig.inputArea.padding,
  borderRadius: 0, // Remove border radius
  boxShadow: 'none', // Remove box shadow
  backgroundColor: theme.palette.mode === 'dark'
    ? chatConfig.inputArea.backgroundColor.dark
    : chatConfig.inputArea.backgroundColor.light,
}));




export const Footer = styled(Box)(({ theme }) => ({
  padding: chatConfig.footer.padding,
  backgroundColor: theme.palette.mode === 'dark'
    ? chatConfig.footer.backgroundColor.dark
    : chatConfig.footer.backgroundColor.light,
  borderTop: `0px solid ${theme.palette.divider}`,
  width: '100%',
}));

// Add more styled components as needed
import React, { useState } from 'react';
import { 
  Box, Typography, Grid, Chip, Button, Alert, Tooltip,
  Card, CardContent, CardActions, useTheme, useMediaQuery,
  Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText,
  Paper
} from '@mui/material';
import { 
  PictureAsPdf, Storage, Description, DataUsage, Timer, Info, Code, Image
} from '@mui/icons-material';
import { Bar, Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement } from 'chart.js';
import { changeActiveDatabase } from '../services/api';

ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement);

const KnowledgeBaseOverview = ({ databases, activeDatabases, onActiveDatabasesChange }) => {
  const [error, setError] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedDB, setSelectedDB] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  const handleChangeActiveDatabase = async (dbName) => {
    try {
      let newActiveDatabases;
      if (activeDatabases.includes(dbName)) {
        newActiveDatabases = activeDatabases.filter(db => db !== dbName);
      } else {
        newActiveDatabases = [...activeDatabases, dbName];
      }
      newActiveDatabases = newActiveDatabases.filter(db => db && db.trim());
      
      if (newActiveDatabases.length === 0) {
        setError('At least one database must be active.');
        return;
      }
      await changeActiveDatabase(newActiveDatabases);
      onActiveDatabasesChange(newActiveDatabases);
      setError(null);
    } catch (error) {
      console.error('Error changing active database:', error);
      setError('Failed to change active database. Please try again.');
    }
  };

  const handleOpenDetails = (db) => {
    setSelectedDB(db);
    setDetailsOpen(true);
  };

  const renderDatabaseCard = (db) => {
    const isActive = activeDatabases.includes(db.name);
    const metadata = db.metadata || {};

    const totalFiles = metadata.total_files || metadata.file_count || 0;
    const totalChunks = metadata.total_chunks || metadata.chunk_count || 0;
    const chunkSize = metadata.chunk_size || (metadata.settings && metadata.settings.chunk_size) || 'N/A';
    const chunkOverlap = metadata.chunk_overlap || (metadata.settings && metadata.settings.chunk_overlap) || 'N/A';
    const extractedMediaCount = metadata.extracted_media_count || 0;
    const pdfCount = metadata.pdf_count || (metadata.file_names ? metadata.file_names.filter(name => name.toLowerCase().endsWith('.pdf')).length : 0);

    const barData = {
      labels: ['Files', 'Chunks', 'Media', 'PDFs'],
      datasets: [{
        label: 'Count',
        data: [totalFiles, totalChunks, extractedMediaCount, pdfCount],
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.warning.main,
          theme.palette.error.main,
        ],
      }]
    };

    return (
      <Card 
        sx={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          transition: 'all 0.3s ease-in-out',
          position: 'relative',
          overflow: 'visible',
          border: isActive ? `2px solid ${theme.palette.primary.main}` : 'none',
          boxShadow: isActive ? `0 0 10px ${theme.palette.primary.main}` : theme.shadows[1],
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: isActive ? `0 5px 15px ${theme.palette.primary.main}` : theme.shadows[10],
          },
        }}
      >
        {isActive && (
          <Tooltip title="Active Database" placement="top">
            <Box
              sx={{
                position: 'absolute',
                top: -15,
                right: -15,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderRadius: '50%',
                width: 30,
                height: 30,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: theme.shadows[3],
              }}
            >
              <Storage fontSize="small" />
            </Box>
          </Tooltip>
        )}
        <CardContent sx={{ flexGrow: 1, pb: 1 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography 
              variant={isMobile ? "subtitle1" : "h6"}
              component="div"
              sx={{ 
                fontWeight: isActive ? 'bold' : 'normal',
                color: isActive ? theme.palette.primary.main : 'inherit'
              }}
            >
              {db.name}
            </Typography>
          </Box>
          <Box height={isMobile ? 120 : 180} mb={2}>
            <Bar 
              data={barData} 
              options={{ 
                maintainAspectRatio: false, 
                scales: { y: { beginAtZero: true } },
                plugins: { legend: { display: false } }
              }} 
            />
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4}>
              <Tooltip title={`Total Files: ${totalFiles}`}>
                <Chip
                  icon={<Description fontSize="small" />}
                  label={totalFiles}
                  size="small"
                  sx={{ width: '100%' }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Tooltip title={`Total Chunks: ${totalChunks}`}>
                <Chip
                  icon={<DataUsage fontSize="small" />}
                  label={totalChunks}
                  size="small"
                  sx={{ width: '100%' }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Tooltip title={`Chunk Size: ${chunkSize}`}>
                <Chip
                  icon={<Timer fontSize="small" />}
                  label={`${chunkSize} Size`}
                  size="small"
                  sx={{ width: '100%' }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Tooltip title={`Chunk Overlap: ${chunkOverlap}`}>
                <Chip
                  icon={<Code fontSize="small" />}
                  label={`${chunkOverlap} Overlap`}
                  size="small"
                  sx={{ width: '100%' }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Tooltip title={`Extracted Media: ${extractedMediaCount}`}>
                <Chip
                  icon={<Image fontSize="small" />}
                  label={extractedMediaCount}
                  size="small"
                  sx={{ width: '100%' }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Tooltip title={`PDF Count: ${pdfCount}`}>
                <Chip
                  icon={<PictureAsPdf fontSize="small" />}
                  label={pdfCount}
                  size="small"
                  sx={{ width: '100%' }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ 
          flexDirection: 'column',
          alignItems: 'stretch',
          p: 2,
          pt: 1,
        }}>
          <Button 
            size="small" 
            color="primary" 
            onClick={() => handleChangeActiveDatabase(db.name)}
            sx={{ 
              mb: 1,
              whiteSpace: 'nowrap'
            }}
          >
            {isActive ? 'Remove Active' : 'Set Active'}
          </Button>
          <Button 
            size="small" 
            color="secondary" 
            onClick={() => handleOpenDetails(db)}
            startIcon={<Info />}
            sx={{ 
              whiteSpace: 'nowrap'
            }}
          >
            Details
          </Button>
        </CardActions>
      </Card>
    );
  };

  const renderDetailsDialog = () => {
    if (!selectedDB) return null;

    const metadata = selectedDB.metadata || {};
    const chunkSizeDistribution = metadata.chunk_size_distribution || {};

    const totalFiles = metadata.total_files || metadata.file_count || 'N/A';
    const totalChunks = metadata.total_chunks || metadata.chunk_count || 'N/A';
    const avgVectorSize = metadata.avg_vector_size || (metadata.average_vector_size && metadata.average_vector_size.toFixed(2)) || 'N/A';
    const totalVectors = metadata.total_vectors || 'N/A';
    const chunkSize = metadata.chunk_size || (metadata.settings && metadata.settings.chunk_size) || 'N/A';
    const chunkOverlap = metadata.chunk_overlap || (metadata.settings && metadata.settings.chunk_overlap) || 'N/A';
    const embeddingModel = metadata.embedding_model || 'N/A';
    const splitterType = metadata.splitter_type || 'N/A';
    const createdAt = metadata.created_at ? new Date(metadata.created_at).toLocaleString() : 'N/A';
    const pdfCount = metadata.pdf_count || (metadata.file_names ? metadata.file_names.filter(name => name.toLowerCase().endsWith('.pdf')).length : 'N/A');
    const pagesScraped = metadata.pages_scraped || totalFiles;
    const extractedMediaCount = metadata.extracted_media_count || 0;
    const extractedMedia = metadata.extracted_media || [];

    const chunkSizeData = {
      datasets: [{
        label: 'Chunk Size Distribution',
        data: Object.entries(chunkSizeDistribution).map(([size, count]) => ({ x: parseInt(size), y: count })),
        backgroundColor: theme.palette.primary.main,
      }]
    };

    const InfoItem = ({ label, value }) => (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="body2" color="text.secondary">{label}:</Typography>
        <Typography variant="body2" fontWeight="medium">{value}</Typography>
      </Box>
    );

    return (
      <Dialog open={detailsOpen} onClose={() => setDetailsOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Typography variant={isMobile ? "h6" : "h5"} component="span">
            {selectedDB.name} Details
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>Database Information</Typography>
              <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default' }}>
                <InfoItem label="Total Files" value={totalFiles} />
                <InfoItem label="Total Chunks" value={totalChunks} />
                <InfoItem label="Total Vectors" value={totalVectors} />
                <InfoItem label="Average Vector Size" value={avgVectorSize} />
                <InfoItem label="Pages Scraped" value={pagesScraped} />
                <InfoItem label="PDF Count" value={pdfCount} />
                <InfoItem label="Created At" value={createdAt} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>Processing Settings</Typography>
              <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default' }}>
                <InfoItem label="Chunk Size" value={chunkSize} />
                <InfoItem label="Chunk Overlap" value={chunkOverlap} />
                <InfoItem label="Embedding Model" value={embeddingModel} />
                <InfoItem label="Splitter Type" value={splitterType} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>Chunk Size Distribution</Typography>
              <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default', height: isMobile ? 200 : 300 }}>
                {Object.keys(chunkSizeDistribution).length > 0 ? (
                  <Scatter 
                    data={chunkSizeData} 
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: { 
                          title: { display: !isMobile, text: 'Chunk Size (tokens)' },
                          ticks: { maxTicksLimit: isMobile ? 5 : 10 }
                        },
                        y: { 
                          title: { display: !isMobile, text: 'Frequency' },
                          ticks: { maxTicksLimit: isMobile ? 3 : 5 }
                        }
                      },
                      plugins: { legend: { display: false } }
                    }} 
                  />
                ) : (
                  <Typography align="center" sx={{ py: 5 }}>No chunk size distribution data available</Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>File Names</Typography>
              <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default', maxHeight: isMobile ? 150 : 200, overflow: 'auto' }}>
                {metadata.file_names && metadata.file_names.length > 0 ? (
                  metadata.file_names.map((name, index) => (
                    <Typography key={index} variant="body2" sx={{ mb: 0.5 }}>{name}</Typography>
                  ))
                ) : (
                  <Typography>No file names available</Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>Extracted Media</Typography>
              <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default', maxHeight: isMobile ? 150 : 200, overflow: 'auto' }}>
                <Typography variant="body2" sx={{ mb: 1 }}>Total Extracted Media: {extractedMediaCount}</Typography>
                {extractedMedia.length > 0 ? (
                  <List dense disablePadding>
                    {extractedMedia.slice(0, isMobile ? 5 : 10).map((media, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemText
                          primary={`${media.type}: ${media.filename}`}
                          secondary={`Page: ${media.page}, Source: ${media.source}`}
                          primaryTypographyProps={{ variant: 'body2' }}
                          secondaryTypographyProps={{ variant: 'caption' }}
                        />
                      </ListItem>
                    ))}
                    {extractedMedia.length > (isMobile ? 5 : 10) && (
                      <ListItem disableGutters>
                        <ListItemText primary={`... and ${extractedMedia.length - (isMobile ? 5 : 10)} more`} primaryTypographyProps={{ variant: 'body2' }} />
                      </ListItem>
                    )}
                  </List>
                ) : (
                  <Typography variant="body2">No extracted media available</Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: 'divider' }}>
          <Button onClick={() => setDetailsOpen(false)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={{ mb: 4 }}>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Grid container spacing={2}>
        {databases.map((db) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={db.name}>
            {renderDatabaseCard(db)}
          </Grid>
        ))}
      </Grid>
      {renderDetailsDialog()}
    </Box>
  );
};

export default KnowledgeBaseOverview;